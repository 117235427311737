<template>
    <div class="home">
      <el-menu :default-active="key" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1">订单/理赔</el-menu-item>
        <el-menu-item index="2">处理记录</el-menu-item>
        <el-menu-item index="3">操作记录</el-menu-item>
        <el-menu-item index="5">订单进度</el-menu-item>
        <el-menu-item index="6">消息推送</el-menu-item>
        <el-menu-item index="8">补充资料</el-menu-item>
        <el-menu-item :class="hasDamage?'reds':''" v-if="topState == '待付款'" index="11">违约金</el-menu-item>
        <el-menu-item index="9">投诉函</el-menu-item>
        <el-menu-item index="10">行为记录</el-menu-item>
        <div style='line-height: 60px;'>
            <a v-if='certificate_pic' :href="certificate_pic" target='_blank' >
            <el-button style='margin-right: 20px;' type="success" size="mini">
            授权书
            </el-button>
            </a>
          订单号：{{topOrder_no}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{topState}}
        </div>
      </el-menu> 
      <div v-if='tableData != "" && key == "1"'>
        <div class="pt20"></div>
        <div style="display:flex;">
          <el-button type="primary" @click="details = 1">订单详情</el-button>
          <el-button type="primary" @click="details = 2">理赔详情</el-button>
        </div>
        <div v-if="details==1">
          <div class="pt20"></div>
          <div class="Associated">
            <div class="AOrder">关联订单</div>
            <el-button class="ABtn" @click="getOrderRelation" type="primary">关联订单</el-button>
          </div>
          <div class="Asoon" v-if="tableData.mainOrderRelation">关联主订单:{{tableData.mainOrderRelation}}</div>
          <div class="Asoon" v-if="!tableData.mainOrderRelation">关联子订单:{{tableData.subOrderRelation}}</div>
          <div class="pt20"></div>
            <el-descriptions class="margin-top" title="订单信息" :column="3" :size="size" border> 
              <el-descriptions-item>
                  <template slot="label">
                    购票渠道
                  </template>
                  {{tableData.order.otaTitle}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-user"></i>
                    创建时间
                  </template>
                  {{tableData.order.cdate}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-mobile-phone"></i>
                    订单类型
                  </template>
                  <el-select  v-model="tableData.order.order_group" class="select1" placeholder="请选择">
                    <el-option
                      v-for="(ot,i) in orderType"
                      :key="'a'+i"
                      :label="ot.name"
                      :value="ot.value">
                    </el-option>
                  </el-select>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-location-outline"></i>
                    系统来源
                  </template>
                  {{tableData.order.platform}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-tickets"></i>
                    是否与文章联动
                  </template>
                  <el-tag size="small">{{tableData.order.article == null?'否':'是'}}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-office-building"></i>
                    文章编号
                  </template>
                  {{tableData.order.article?tableData.order.article.id:''}}
                </el-descriptions-item>
            </el-descriptions>
          <div class="pt20"></div>
            <el-descriptions class="margin-top" title="客户信息" :column="4" :size="size" border> 
              <el-descriptions-item>
                <template slot="label">
                  姓名
                </template>
                <font v-if='tableData.orderEdit.name == 1' style="color:red;">{{tableData.user.name}}</font>
                {{tableData.orderEdit.name == 1 ? '' : tableData.user.name}}
              </el-descriptions-item><el-descriptions-item>
                <template slot="label">
                  注册人姓名
                </template>
                {{tableData.customer_data.name}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  性别
                </template>
                {{tableData.user.sex == 0?'未选择':''}}
                {{tableData.user.sex == 1?'男':''}}
                {{tableData.user.sex == 2?'女':''}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  会员等级
                </template>
                {{tableData.user.level}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  注册手机
                </template>
                <el-tag size="small">{{tableData.user.area_code}}</el-tag>
                {{tableData.user.phone}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  联系邮箱
                </template>
                {{tableData.user.email}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  证件类型
                </template>
                {{tableData.user.oidtype | oidTypefil}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  证件号码
                </template>
                <font v-if='tableData.orderEdit.oidnum == 1' style="color:red;">{{tableData.user.oidnum}}</font>
                {{tableData.orderEdit.oidnum == 1 ? '' : tableData.user.oidnum}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  有无同行人
                </template>
                {{tableData.customer_data.is_complex == 1?'有':'无'}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  已领取补偿
                </template>
                {{tableData.order.is_compensation_received==1?'是':'否'}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  自行投诉过
                </template>
                {{tableData.order.is_self_claimed==1?'是':'否'}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  投诉类型
                </template>
                {{tableData.order.cptype_text}}
              </el-descriptions-item>
              <el-descriptions-item v-if="tableData.order.cptype_text == '航班-延误'">
                <template slot="label">
                  延误原因
                </template>
                {{tableData.order.delay_reason}}
              </el-descriptions-item>
              <el-descriptions-item v-if="tableData.order.cptype_text == '航班-延误'">
                <template slot="label">
                  延误时间
                </template>
                {{tableData.order.delay_hour}}
              </el-descriptions-item>
              <el-descriptions-item v-if="tableData.order.cptype_text == '航班-延误'">
                <template slot="label">
                  实际损失
                </template>
                {{tableData.order.actual_loss}}
              </el-descriptions-item>
            </el-descriptions>
              <div class="content_title">投诉内容:</div>
              <div class="contents">{{tableData.order.descr}}</div>
              <div class="ph">
                <div class="ph_title">照片信息:</div>
                <div  class="ph_btn" @click="getpic()">浏览照片</div>
              </div>
            <div v-for="(airinfo,i) in tableData.airinfo" :key="'b'+i">
          <div class="pt20"></div>
              <el-descriptions class="margin-top" :title="'航班信息'+(++i)" :column="3" :size="size" border> 
                  <el-descriptions-item>
                    <template slot="label">
                      投诉对象
                    </template>
                    {{airinfo.fcom}}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      出发机场
                    </template>
                    {{airinfo.dcitycode}}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      到达机场
                    </template>
                    {{airinfo.acitycode}}
                  </el-descriptions-item>   
                  <el-descriptions-item>
                    <template slot="label">
                      出发日期
                    </template>
                <font v-if='tableData.orderEdit.flight_data == 1' style="color:red;">{{airinfo.fdate}}</font>
                {{tableData.orderEdit.flight_data == 1 ? '' : airinfo.fdate}}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      航班号码
                    </template>
                <font v-if='tableData.orderEdit.flight_data == 1' style="color:red;">{{airinfo.fnum}}</font>
                {{tableData.orderEdit.flight_data == 1 ? '' : airinfo.fnum}}
                  </el-descriptions-item>  
              </el-descriptions>
            </div>
          <div class="pt20"></div>
            <el-descriptions class="margin-top" title="客服处理信息" :column="3" :size="size" border> 
              <el-descriptions-item>
                <template slot="label">
                  证件名
                </template>
                <el-input style='width:200px;margin-left: 5px;' v-model="tableData.customer_data.name" placeholder="请输入证件名"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  性别
                </template>
                <el-radio-group v-model="tableData.customer_data.sex">
                  <el-radio v-model="tableData.customer_data.sex" :label="1">男</el-radio>
                  <el-radio v-model="tableData.customer_data.sex" :label="2">女</el-radio>
                </el-radio-group>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  出生年月
                </template>
                <el-col :span="20">
                  <el-date-picker type="date" placeholder="选择日期" v-model="tableData.customer_data.birth" style="width: 100%;"></el-date-picker>
                </el-col>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  渠道
                </template>
                  <el-select  v-model="tableData.customer_data.channel" class="select1" placeholder="请选择">
                    <el-option
                      label="请选择渠道"
                      :value="0">
                    </el-option>
                    <el-option
                      v-for="(tchan,i) in tchannel"
                      :key="'c'+i"
                      :label="tchan.name"
                      :value="tchan.value">
                    </el-option>
                  </el-select>
                  <el-input style='width:200px;margin-left: 5px;' v-if="tableData.customer_data.channel == 4" v-model="tableData.customer_data.remark" placeholder="请输入渠道备注"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  联系邮箱
                </template>
                <el-input v-model="tableData.customer_data.email" placeholder="请输入联系邮箱"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  联系电话
                </template>
                <div style="display: flex;">
                  <el-input style="width: 80px;margin-right:5px;" v-model="tableData.customer_data.area_code"></el-input>
                  <el-input v-model="tableData.customer_data.phone" placeholder="请输入联系电话"></el-input>
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  证件类型
                </template>
                  <el-select  v-model="tableData.customer_data.oidtype" class="select1" placeholder="请选择">
                    <el-option
                      v-for="(oid,i) in oidType"
                      :key="'d'+i"
                      :label="oid.name"
                      :value="oid.value">
                    </el-option>
                  </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  证件号码
                </template>
                <el-input v-model="tableData.customer_data.oidnum" placeholder="请输入证件号码"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  地区
                </template>
                <el-input v-model="tableData.customer_data.region" placeholder="请输入地区"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  有无同行人
                </template>
                <el-radio-group v-model="tableData.customer_data.is_complex">
                  <el-radio v-model="tableData.customer_data.is_complex" :label="1">有</el-radio>
                  <el-radio v-model="tableData.customer_data.is_complex" :label="2">没有</el-radio>
                </el-radio-group>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  ETKT号码
                </template>
                <el-input v-model="tableData.customer_data.etkt_num" placeholder="请输入ETKT号码"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  事故单编号
                </template>
                <el-input v-model="tableData.customer_data.accident_number" placeholder="请输入事故单编号" ></el-input>
              </el-descriptions-item>
            </el-descriptions>
          <div :key="index" v-for="(customerData,index) in tableData.complaint_information">
            <div style="font-size: 16px;font-weight: bold;height: 50px;line-height: 50px;text-align: left;position: relative;">
              投诉信息{{index+1}}&nbsp;({{customerData.complaint_information_no}})
               <el-button v-if="(index+1) == tableData.complaint_information.length" @click="AddComplaintInformatin" icon="el-icon-plus" style="position: absolute;right: 100px;top:5px;"  type="primary"  circle></el-button>
               <el-button v-if="index > 0" @click="DelComplaintInformatin(index)" icon="el-icon-minus" style="position: absolute;right: 50px;top:5px;"  type="primary"  circle></el-button>
            </div>
            <el-descriptions class="margin-top"   :column="3" :size="size" border> 
             <div> <el-button icon="el-icon-search" circle></el-button></div>
              <el-descriptions-item>
                <template slot="label">
                  投诉对象
                </template>
                <el-radio-group v-model="customerData.is_main">
                  <el-radio v-model="customerData.is_main" :label="1">是</el-radio>
                  <el-radio v-model="customerData.is_main" :label="0">否</el-radio>
                </el-radio-group>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  航班号码
                </template>
                <el-input @blur='getAircompanyInfo(index)' v-model="customerData.flight_number" placeholder="请输入航班号码"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  航司代码
                </template>
                {{customerData.flight_code}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  航空公司
                </template>
                {{customerData.airline_company}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  航司归属地
                </template>
                {{customerData.ascription}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  出发日期
                </template>
                <el-col :span="20">
                  <el-date-picker type="date" placeholder="选择日期" v-model="customerData.departure_date" style="width: 100%;"></el-date-picker>
                </el-col>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  出发机场
                </template>
                <el-input v-model="customerData.departure_airport" placeholder="请输入出发机场"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  到达机场
                </template>
                <el-input v-model="customerData.airport_destination" placeholder="请输入到达机场"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  航线信息
                </template>
                  <el-select  v-model="customerData.flight_information" class="select1" placeholder="请选择">
                    <el-option
                      label="请选择"
                      :value="-1">
                    </el-option>
                  <el-option
                      label="国内航班"
                      :value="0">
                    </el-option>
                    <el-option
                      label="国际航班"
                      :value="1">
                    </el-option>
                  </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  投诉类型
                </template>
                  <el-select @change="ScxType(customerData.complaint_type,index,true)" v-model="customerData.complaint_type" class="select1" placeholder="请选择">
                    <el-option
                      label="请选择"
                      :value="-1">
                    </el-option>
                    <el-option
                      v-for="(cxt,i) in cxType"
                      :key="'e'+i"
                      :label="cxt.name"
                      :value="cxt.value">
                    </el-option>
                  </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  投诉1级类型
                </template> 
                  <el-select :disabled='cxTypeOne[index] ? cxTypeOne[index].length == 0 ? true : false : true'  @change="ScxTypeO(customerData.complaint_type_level_one,index,true)"  v-model="customerData.complaint_type_level_one" class="select1" placeholder="请选择">
                    <el-option
                      label="请选择"
                      :value="-1">
                    </el-option>
                    <el-option
                      v-for="(cxt,i) in cxTypeOne[index]"
                      :key="'f'+i"
                      :label="cxt.name"
                      :value="cxt.value">
                    </el-option>
                  </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  投诉2级类型
                </template>
                  <el-select v-if="cxTypeTwo[index]?cxTypeTwo[index].length != 0 : false" @change="ScxTypeT(customerData.complaint_type_level_two,index,true)"  v-model="customerData.complaint_type_level_two" class="select1" placeholder="请选择">
                    <el-option
                      label="请选择"
                      :value="-1">
                    </el-option>
                    <el-option
                      v-for="(cxt,i) in cxTypeTwo[index]"
                      :key="'s'+i"
                      :label="cxt.name"
                      :value="cxt.value">
                    </el-option>
                  </el-select>
                  <el-input v-else :disabled='customerData.complaint_type_level_one === "" || customerData.complaint_type_level_one === -1 ? true : false' v-model="customerData.complaint_type_level_two_custom" placeholder="请输入投诉2级类型"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  投诉3级类型
                </template>
                  <el-select v-if="cxTypeThree[index]?cxTypeThree[index].length != 0 : false" v-model="customerData.complaint_type_level_three" class="select1" placeholder="请选择">
                    <el-option
                      label="请选择"
                      :value="-1">
                    </el-option>
                    <el-option
                      v-for="(cxt,i) in cxTypeThree[index]"
                      :key="'t'+i"
                      :label="cxt.name"
                      :value="cxt.value">
                    </el-option>
                  </el-select>
                  <el-input v-else :disabled='customerData.complaint_type_level_two == -1' v-model="customerData.complaint_type_level_three_custom" placeholder="请输入投诉3级类型"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  延误时间
                </template>
                <el-input v-model="customerData.delay_time" placeholder="请输入延误时间"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  投诉方式
                </template>
                  <el-select  v-model="customerData.complaint_mode" class="select1" placeholder="请选择">
                    <el-option
                      label="请选择"
                      :value="-1">
                    </el-option>
                    <el-option
                      v-for="(tmt,i) in tmethod"
                      :key="'g'+i"
                      :label="tmt.name"
                      :value="tmt.value">
                    </el-option>
                  </el-select>
              </el-descriptions-item>
              <el-descriptions-item v-if="customerData.complaint_mode == 2 || customerData.complaint_mode == 3 || customerData.complaint_mode == 4">
                <template slot="label"><!--升级投诉,律师介入,信函投诉 2,3,4 -->
                  民航局投诉情况
                </template>
                  <el-select v-model="customerData.civil_aviation" class="select1" placeholder="请选择">
                    <el-option
                      label="请选择"
                      :value="-1">
                    </el-option>
                    <el-option
                      v-for="(aa,i) in AviationAdministrationofChina"
                      :key="'aa'+i"
                      :label="aa.name"
                      :value="aa.value">
                    </el-option>
                  </el-select>
              </el-descriptions-item>
              <el-descriptions-item v-if="customerData.complaint_mode == 1 || customerData.complaint_mode == 2 || customerData.complaint_mode == 5">
                <template slot="label"><!--一般投诉 升级投诉 举报投诉 1,2,5 -->
                  投诉对象
                </template>
                  <el-select v-model="customerData.complaint_target" class="select1" placeholder="请选择">
                    <el-option
                      label="请选择"
                      :value="-1">
                    </el-option>
                    <el-option
                      v-for="(coc,i) in ComplaintObjectCollection"
                      :key="'coc'+i"
                      :label="coc.name"
                      :value="coc.value">
                    </el-option>
                  </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  索赔情况
                </template>
                  <el-select @change="getcauseanalysis(customerData.claims,index)" v-model="customerData.claims" class="select1" placeholder="请选择">
                    <el-option
                      label="请选择"
                      :value="-1">
                    </el-option>
                    <el-option
                      v-for="(cla,i) in claimsdata"
                      :key="'cla'+i"
                      :label="cla.name"
                      :value="cla.value">
                    </el-option>
                  </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  原因分析
                </template>
                  <el-select :disabled='causeanalysis[index]?causeanalysis[index].length == 0 ? true : false : true'  v-model="customerData.cause_analysis" class="select1" placeholder="请选择">
                    <el-option
                      label="请选择"
                      :value="-1">
                    </el-option>
                    <el-option
                      v-for="(cau,i) in causeanalysis[index]"
                      :key="'cau'+i"
                      :label="cau.name"
                      :value="cau.value">
                    </el-option>
                  </el-select>
              </el-descriptions-item>
              <el-descriptions-item v-if="customerData.claims == 0">
                <template slot="label">
                  赔偿方案
                </template>
                  <el-select v-model="customerData.claims_detail" class="select1" placeholder="请选择">
                    <el-option
                      label="请选择"
                      :value="-1">
                    </el-option>
                    <el-option
                      v-for="(cdl,i) in claims_detail_list"
                      :key="'cdl'+i"
                      :label="cdl.name"
                      :value="cdl.value">
                    </el-option>
                  </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  首次联系
                </template>
                <el-col :span="20">
                  <el-date-picker type="datetime"  placeholder="选择日期" v-model="customerData.first_contact_time"
                :format="valueformat1"
                :editable='true'
                @focus="timef(1)"
                @blur="timeb(1)" style="width: 100%;"></el-date-picker>
                </el-col>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  资料收齐
                </template>
                <el-col :span="20">
                  <el-date-picker type="datetime"  placeholder="选择日期" v-model="customerData.all_data_collected_time"
                :format="valueformat2"
                :editable='true'
                @focus="timef(2)"
                @blur="timeb(2)" style="width: 100%;"></el-date-picker>
                </el-col>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  发起投诉
                </template>
                <el-col :span="20">
                  <el-date-picker type="datetime"  placeholder="选择日期" v-model="customerData.time_of_complaint_initiation"
                :format="valueformat3"
                :editable='true'
                @focus="timef(3)"
                @blur="timeb(3)" style="width: 100%;"></el-date-picker>
                </el-col>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  首次回复
                </template>
                <el-col :span="20">
                  <el-date-picker type="datetime"  placeholder="选择日期" v-model="customerData.first_response_to_complaint_time"
                :format="valueformat4"
                :editable='true'
                @focus="timef(4)"
                @blur="timeb(4)" style="width: 100%;"></el-date-picker>
                </el-col>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  确认赔偿
                </template>
                <el-col :span="20">
                  <el-date-picker type="datetime"  placeholder="选择日期" v-model="customerData.confirmation_compensation"
                :format="valueformat5"
                :editable='true'
                @focus="timef(5)"
                @blur="timeb(5)" style="width: 100%;"></el-date-picker>
                </el-col>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  打款时间
                </template>
                <el-col :span="20">
                  <el-date-picker type="datetime"  placeholder="选择日期" v-model="customerData.payment_time_airlines"
                :format="valueformat6"
                :editable='true'
                @focus="timef(6)"
                @blur="timeb(6)" style="width: 100%;"></el-date-picker>
                </el-col>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  自定义1
                </template>
                <el-input v-model="customerData.custom_1" placeholder="请输入"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  自定义2
                </template>
                <el-input v-model="customerData.custom_2" placeholder="请输入"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  自定义3
                </template>
                <el-input v-model="customerData.custom_3" placeholder="请输入"></el-input>
              </el-descriptions-item>
              
              <el-descriptions-item v-if="(customerData.complaint_type == 73 && customerData.complaint_type_level_one == 75 && customerData.claims === 0)"  > 
                <template slot="label">
                  最终赔付企业
                </template>
                <el-select v-model="customerData.final_compensation" multiple placeholder="请选择">
                    <el-option label="ota平台" value="ota平台"></el-option>
                    <el-option label="航司" value="航司"></el-option>
                    <el-option label="票代" value="票代"></el-option>
                  </el-select>
              </el-descriptions-item>
            </el-descriptions>
          </div>

          <div class="handle_title">处理内容:</div>
          <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6}" v-model="handle" ></el-input>
          <div class="pt20"></div>
          <el-button type="primary" @click='addhandle'>提交记录</el-button> 
          <div class="pt20"></div>
          <div class="botoom">
            <div>
              下次处理记录：
              <el-select style="width:200px;"  v-model="next_processing_status" placeholder="请选择">
                <el-option  
                      label="请选择"
                      :value="-1">
                    </el-option>
                    <el-option
                      v-for="ntr in nextreason"
                      :key="ntr.value"
                      :label="ntr.name"
                      :value="ntr.value">
                    </el-option>
              </el-select>
            </div>
              <div class="nextprocessingtime">
                  下次处理时间：
                <el-date-picker type="date" placeholder="选择时间" v-model="next_processing_time" style="width: 250px;"></el-date-picker>
            </div> 
            <div class="orderstatus"><!-- 订单状态state 0：待审核 1：处理中 2：待付款 3：投诉完成 4：索赔成功 -->
              订单删除状态：<!--  订单删除状态status：1正常 0禁用 -1：删除 -2:未激活 --> 
                <div>{{tableData.order.status == -1 ? '删除':'未删除'}}</div>
            </div>
          </div>
          <div class="pt20"></div>
          <div style="text-align: center;" v-if="list_type == 2">
              <el-button @click="GetModifyInformation" type="info">修改信息</el-button>
              <el-button @click="postorder(3)" class="magrin20" type="primary">保存</el-button>
              <el-button @click="openmsgbox(2)"  type="danger">提交</el-button>
              <el-button v-if="topState != '待审核'" @click="openmsgbox(1)" class="magrin100" type="success">结单</el-button>
              <el-button v-if="topState != '待审核'"  @click="openmsgbox(4)" class="magrin100 xs315" type="success">315限时</el-button>
          </div>  
          <div class="pt20"></div>
          <div class="pt20"></div>
          <div class="pt20"></div>
        </div>
        <div v-if="details==2">
          <div class="pt20"></div>
            <el-descriptions class="margin-top" title="理赔信息" :column="4" :size="size" border> 
                <el-descriptions-item>
                  <template slot="label">
                    申请金额
                  </template>
                  <el-input v-model="InformationData.order.application_amount" placeholder="请输入申请金额"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    获赔金额
                  </template>
                  <el-input @blur="calculation" v-model="InformationData.order.amount_paid" placeholder="请输入获赔金额"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    不计赔款金额
                  </template>
                  <el-input @blur="calculation" v-model="InformationData.order.ex_price" placeholder="请输入不计赔款金额"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    服务费比例（%）
                  </template>
                  <el-input @blur="calculation" v-model="InformationData.order.service_charges" placeholder="请输入服务费比例"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    原服务费金额
                  </template>
                  <el-input v-model="InformationData.order.payable" placeholder="请输入服务费金额"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    支付渠道
                  </template>
                  <el-select  v-model="InformationData.order.payment_channel" class="select1" placeholder="请选择支付渠道">
                    <el-option label="请选择" :value="-1"></el-option>
                    <el-option
                      v-for="(pay,i) in pay_channel"
                      :key="'a'+i"
                      :label="pay.name"
                      :value="pay.value">
                    </el-option>
                  </el-select>
                </el-descriptions-item>
                <el-descriptions-item v-if="InformationData.order.payment_channel == 6">
                  <template slot="label">
                    需打款金额
                  </template>
                  {{Amount_to_be_paid}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    优惠劵信息
                  </template>
                  {{canableEditCoupon ? '' : InformationData.order.coupon_name}}
                  <el-select v-if="canableEditCoupon" v-model="InformationData.order.cus_coupon_id" @change="setdiscount" class="select1" placeholder="请选择优惠卷">
                    <el-option
                      v-for="(cou,i) in coupons"
                      :key="'a'+i"
                      :label="cou.coupon_name"
                      :value="cou.id">
                    </el-option>
                  </el-select>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    折扣比例（%）
                  </template>
                  {{InformationData.order.discount}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    减免优惠
                  </template>
                  {{InformationData.deduction_roll.price == 0 ? '' :'吐槽红包'}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    优惠金额
                  </template>
                  {{InformationData.deduction_roll.price}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    应支付服务费
                  </template>
                  <el-input v-model="InformationData.order.payment" placeholder="请输入应支付服务费"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    锁定服务费
                  </template>
                  <el-button @click="feelock()" type="success" size='mini' style="margin-right: 10px;">锁定</el-button>
                  <el-tag size="small">公账入款结单前必须锁定</el-tag>
                </el-descriptions-item>
            </el-descriptions>
          <div :key="index" v-for="(Information,index) in InformationData.claim_information">
            <div class="details_title" style="position: relative;">第{{index+1}}段理赔信息(拆分)
              <el-button v-if="index == 0" @click="synchronization" type="primary" size='small' style="margin-left: 10px;">同步</el-button>
              <el-button v-if="(index+1) == InformationData.claim_information.length" @click="AddInformation" icon="el-icon-plus" style="position: absolute;right: 100px;top:-5px;"  type="primary"  circle></el-button>
              <el-button v-if="index > 0" @click="DelInformation(index)" icon="el-icon-minus" style="position: absolute;right: 50px;top:-5px;"  type="primary"  circle></el-button>
            </div>
            <el-descriptions class="margin-top" :column="4" :size="size" border>  
                <el-descriptions-item>
                  <template slot="label">
                    投诉编号
                  </template>
                  <el-input v-model="Information.complaint_information_no" placeholder="请输入投诉编号"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    申请金额
                  </template>
                  <el-input v-model="Information.application_amount" placeholder="请输入申请金额"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    获赔金额
                  </template>
                  <el-input @blur="InformationDataCalculation(index)" v-model="Information.amount_paid" placeholder="请输入获赔金额"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    不计赔款金额
                  </template>
                  <el-input @blur="InformationDataCalculation(index)" v-model="Information.ex_price" placeholder="请输入不计赔款金额"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    服务费比例（%）
                  </template>
                  <el-input @blur="InformationDataCalculation(index)" v-model="Information.service_charges" placeholder="请输入服务费比例"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    折扣比例（%）
                  </template>
                  <el-input disabled v-model="Information.discount" placeholder="请输入折扣比例"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    减免优惠
                  </template>
                 {{Information.claim_deduction_roll == 0?'':'吐槽红包'}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    优惠金额
                  </template>
                  <el-input disabled v-model="Information.claim_deduction_roll " placeholder="请输入优惠金额"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    服务费金额
                  </template>
                  <el-input v-model="Information.payable" placeholder="请输入服务费金额"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    服务费支付渠道
                  </template>
                  <el-select  v-model="Information.payment_channel" class="select1" placeholder="请选择支付渠道">
                    <el-option label="请选择" :value="-1"></el-option>
                    <el-option
                      v-for="(pay,i) in pay_channel"
                      :key="'a'+i"
                      :label="pay.name"
                      :value="pay.value">
                    </el-option>
                  </el-select>
                </el-descriptions-item>
                <el-descriptions-item v-if="Information.payment_channel == 6">
                  <template slot="label">
                    需打款金额
                  </template>
                  {{Information.Amount_to_be_paid}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    优惠劵信息
                  </template>
                  {{Information.coupon_name}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    应支付服务费
                  </template>
                  <el-input v-model="Information.payment" placeholder="请输入优惠劵信息"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    预计打款时间
                  </template>
                  <el-date-picker type="datetime"  placeholder="选择日期" v-model="Information.estimated_payment_time" style="width: 100%;"></el-date-picker>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    索赔成功人数
                  </template>
                  <el-input v-model="Information.number_of_claims" placeholder="请输入索赔成功人数"></el-input>
                </el-descriptions-item>
            </el-descriptions>
          </div>
          
          <div class="pt20"></div>
            <el-descriptions class="margin-top" title="银行卡信息" :column="4" :size="size" border> 
                <el-descriptions-item>
                  <template slot="label">
                    开户人
                  </template>
                  <el-input v-model="InformationData.order.account_holder" placeholder="请输入开户人"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    银行名称
                  </template>
                  <el-input v-model="InformationData.order.bank" placeholder="请输入银行名称"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    银行账户
                  </template>
                  <el-input v-model="InformationData.order.bank_account" placeholder="请输入银行账户"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    开户行
                  </template>
                  <el-input v-model="InformationData.order.opening_bank" placeholder="请输入开户行"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    银行地址
                  </template>
                  <el-input v-model="InformationData.order.bank_address" placeholder="请输入银行地址"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    CNAPS联行号
                  </template>
                  <el-input v-model="InformationData.order.cnaps" placeholder="请输入CNAPS联行号"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    Swift Code
                  </template>
                  <el-input v-model="InformationData.order.swift_code" placeholder="请输入Swift Code"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    打款时间
                  </template>
                  <el-date-picker type="datetime"  placeholder="选择日期" v-model="InformationData.order.estimated_payment_time" style="width: 100%;"></el-date-picker>
                </el-descriptions-item>
            </el-descriptions>
          <div class="pt20"></div>
            <el-descriptions class="margin-top" title="其他信息" :column="4" :size="size" border> 
                <el-descriptions-item>
                  <template slot="label">
                    打赏金额
                  </template>
                  <el-input v-model="InformationData.order.reward_amount" placeholder="请输入打赏金额"></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    送达地址
                  </template>
                  <el-input v-model="InformationData.order.service_address" placeholder="请输入送达地址"></el-input>
                </el-descriptions-item>
            </el-descriptions>
            <div class="pt20"></div>
            <div style="text-align: center;"  v-if="list_type == 2">
                <el-button @click="postorder(3)" class="magrin20" type="primary">保存</el-button>
                <el-button @click="openmsgbox(2)" type="danger">提交</el-button>
                <el-button @click="openmsgbox(1)" class="magrin100" type="success">结单</el-button>
              <el-button v-if="topState != '待审核'"  @click="openmsgbox(4)" class="magrin100 xs315" type="success">315限时</el-button>
            </div>  
            <div class="pt20"></div>
            <div class="pt20"></div>
            <div class="pt20"></div>
        </div>
      </div>
      <div v-if='key == "2"'>
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{background:'#F5F7FA'}"
          >
          <el-table-column
            prop="create_time"
            label="处理时间">
          </el-table-column>
          <el-table-column
            prop="type_text"
            label="类型">
          </el-table-column>
          <el-table-column
            prop="handle_contents"
            label="处理内容">
          <template  slot-scope="scope">
            <div v-html="scope.row.handle_contents"></div>
          </template> 
          </el-table-column>
          <el-table-column
            label="操作人员">
          <template  slot-scope="scope">
            {{scope.row.admin?scope.row.admin.account:''}}
          </template> 
          </el-table-column>
        </el-table>
      </div>
      <div v-if='key == "3"'>
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{background:'#F5F7FA'}"
          >
          
          <el-table-column
            prop="create_time"
            label="操作时间">
             <template  slot-scope="scope">
                  {{scope.row.create_time | formatDate}}
             </template>
          </el-table-column>
          <el-table-column
            prop="content"
            label="操作内容">
          </el-table-column>
          <el-table-column
            label="操作账号">
          <template  slot-scope="scope">
            {{scope.row.admin?scope.row.admin.account:''}}
          </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="div5" v-if='key == "5"'>
          <div class="left">
            <div class="top">
              <el-form :model="orderSchedule" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
                <el-form-item label="推送内容：" placeholder="请输入推送内容" prop="content">
                  <el-input type="textarea" v-model="orderSchedule.content"></el-input>
                </el-form-item>
                <el-form-item label="推送内容(英文)：" placeholder="请输入推送内容" prop="en_content">
                  <el-input type="textarea" v-model="orderSchedule.en_content"></el-input>
                </el-form-item>
                <el-form-item label="二级状态：" placeholder="请输入二级状态名称" prop="secondStatus">
                  <el-input v-model="orderSchedule.secondStatus"></el-input>
                </el-form-item>
                <el-form-item label="二级状态(英文)：" placeholder="请输入二级状态名称" prop="en_secondStatus">
                  <el-input v-model="orderSchedule.en_secondStatus"></el-input>
                </el-form-item>
                <el-form-item label="存储到：" prop="type">
                  <el-select v-model="orderSchedule.type" placeholder="请选择">
                    <el-option label="处理中" value="1"></el-option>
                    <el-option label="待付款" value="2"></el-option>
                    <el-option label="客服消息" value="3"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitForm(orderSchedule)">新增</el-button>
                  <el-button :disabled='!orderSchedule.id || order_schedule_model_loading' type="primary" @click="postForm(orderSchedule)">编辑</el-button>
                  <el-button @click="sendSchedule(orderSchedule)">发送</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="bottom">
              <div class='cltitle'>处理进度记录</div>
              <el-table
                v-loading="loading"
                :data="tableData"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                >
                <el-table-column
                  prop="created_at"
                  label="发送时间">
                </el-table-column>
                <el-table-column
                  prop="toplevel"
                  label="投诉状态">
                </el-table-column>
                <el-table-column
                  prop="secondlevel"
                  label="二级状态">
                </el-table-column>
                <el-table-column
                  prop="content"
                  label="发送内容">
                </el-table-column>
                <el-table-column
                  prop="operator"
                  label="操作人">
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="right">
            <el-tabs @tab-click="order_schedule_model()" v-model="activeName" type="border-card">
              <el-tab-pane label="处理中">
                <div ref="asrbxc">
                  <div ref='asrbxcfgoRef'  :id='ord.model_id' class="div2" v-for="(ord,i) in orderScheduleModel" :key="'h'+i">
                    <div @click="addorder(ord)" class="title">
                      {{ord.secondStatus}}
                      <el-button type="danger" @click.stop="deleteordermodel(ord.model_id,activeName)" class="lese" icon="el-icon-delete" circle></el-button>
                    </div>
                    <div class="content">
                      {{ord.content}}
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="待付款">
                <div ref="asrbxc2">
                  <div ref='asrbxcfgoRef2' :id='ord.model_id' class="div2" v-for="(ord,i) in orderScheduleModel" :key="'m'+i">
                    <div @click="addorder(ord)" class="title">
                      {{ord.secondStatus}}
                      <el-button type="danger" @click.stop="deleteordermodel(ord.model_id,activeName)" class="lese" icon="el-icon-delete" circle></el-button>
                    </div>
                    <div class="content">
                      {{ord.content}}
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="客服消息">
                <div ref="asrbxc3">
                  <div ref='asrbxcfgoRef3' :id='ord.model_id' class="div2" v-for="(ord,i) in orderScheduleModel" :key="'n'+i">
                    <div @click="addorder(ord)" class="title">
                      {{ord.secondStatus}}
                      <el-button type="danger" @click.stop="deleteordermodel(ord.model_id,activeName)" class="lese" icon="el-icon-delete" circle></el-button>
                    </div>
                    <div class="content">
                      {{ord.content}}
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
      </div>
      <div class="div6" v-if='key == "6"'>
          <div class="left">
            <div class="top">
              <el-form :model="modeSchedule" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
                <el-form-item label="推送内容：" placeholder="请输入推送内容" prop="content">
                  <el-input type="textarea" v-model="modeSchedule.content"></el-input>
                </el-form-item>
                <el-form-item label="推送内容(英文)：" placeholder="请输入推送内容(英文)" prop="en_content">
                  <el-input type="textarea" v-model="modeSchedule.en_content"></el-input>
                </el-form-item>
                <el-form-item label="模板名称：" placeholder="请输入模板名称" prop="title">
                  <el-input v-model="modeSchedule.title"></el-input>
                </el-form-item>
                <el-form-item label="模板名称(英文)：" placeholder="请输入模板名称(英文)" prop="en_title">
                  <el-input v-model="modeSchedule.en_title"></el-input>
                </el-form-item>
                <el-form-item label="存储到：" prop="type">
                  <el-select v-model="modeSchedule.type" placeholder="请选择">
                    <el-option label="处理中" value="1"></el-option>
                    <el-option label="待付款" value="2"></el-option>
                    <el-option label="已完成" value="3"></el-option>
                    <el-option label="客服消息" value="4"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitmodeForm(modeSchedule)">新增</el-button>
                  <el-button :disabled='!modeSchedule.id' type="primary" @click="postmodeForm(modeSchedule)">编辑</el-button>
                  <el-button @click="sendSmodechedule(modeSchedule)">发送</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="bottom">
              <div class='cltitle'>处理进度记录</div>
              <el-table
                v-loading="loading"
                :data="tableData"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                >
                <el-table-column
                  prop="created_at"
                  label="发送时间">
                </el-table-column>
                <el-table-column
                  prop="content"
                  label="发送内容">
                </el-table-column>
                <el-table-column
                  prop="operator"
                  label="操作人">
                </el-table-column>
              </el-table>
            </div>
          </div> 
          <div class="right">
            <el-tabs @tab-click="model_message()" v-model="modeActive" type="border-card">
              <el-tab-pane label="处理中">
                <div ref='mkaod'>
                  <div ref='dbdfbw' :id='ord.model_id' class="div2" v-for="(ord,i) in modelmessage" :key="'o'+i">
                    <div @click="addmodel(ord)" class="title">
                      {{ord.title}}
                      <el-button type="danger" @click.stop="deleteModeModel(ord.model_id,modeActive)" class="lese" icon="el-icon-delete" circle></el-button>
                    </div>
                    <div class="content">
                      {{ord.content}}
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="待付款">
                <div ref='mkaod1'>
                  <div ref='dbdfbw1' :id='ord.model_id' class="div2" v-for="(ord,i) in modelmessage" :key="'p'+i">
                    <div @click="addmodel(ord)" class="title">
                      {{ord.title}}
                      <el-button type="danger" @click.stop="deleteModeModel(ord.model_id,modeActive)" class="lese" icon="el-icon-delete" circle></el-button>
                    </div>
                    <div class="content">
                      {{ord.content}}
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="已完成">
                <div ref='mkaod2'>
                  <div ref='dbdfbw2' :id='ord.model_id' class="div2" v-for="(ord,i) in modelmessage" :key="'q'+i">
                    <div @click="addmodel(ord)" class="title">
                      {{ord.title}}
                      <el-button type="danger" @click.stop="deleteModeModel(ord.model_id,modeActive)" class="lese" icon="el-icon-delete" circle></el-button>
                    </div>
                    <div class="content">
                      {{ord.content}}
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="客服消息">
                <div ref='mkaod3'>
                  <div ref='dbdfbw3' :id='ord.model_id' class="div2" v-for="(ord,i) in modelmessage" :key="'r'+i">
                    <div @click="addmodel(ord)" class="title">
                      {{ord.title}}
                      <el-button type="danger" @click.stop="deleteModeModel(ord.model_id,modeActive)" class="lese" icon="el-icon-delete" circle></el-button>
                    </div>
                    <div class="content">
                      {{ord.content}} 
                    </div>
                  </div>
                </div>
              </el-tab-pane> 
            </el-tabs>
          </div>
      </div> 
      <div class="div8" v-if="key == '8'">
        <div>
          <div class='exchangeByOrder'>补充投诉资料</div>
          <div style="text-align: left;margin-bottom: 10px;">
                <el-button v-if='bcData.length == 0' @click="addbcdata()" type="primary" style="font-size: 16px;font-weight: bold;" plain>新创建</el-button>
                <el-button v-if='bcData.length == 0' @click="Createusingtemplates()" type="primary" style="font-size: 16px;font-weight: bold;" plain>使用模板创建</el-button> 
              </div>
              <el-table
                v-loading="loading"
                :data="bcData"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                >
                <el-table-column
                  prop="child_status"
                  label="处理状态">
                <template  slot-scope="scope" >
                  {{scope.row.child_status == 0 ? '待发送' : ''}}
                  {{scope.row.child_status == 1 ? '等待用户回复' : ''}}
                  <font v-if="scope.row.child_status == 2" style="color:red;">待处理</font>
                  {{scope.row.child_status == 3 ? '已完成' : ''}}
                </template> 
                </el-table-column>
                <el-table-column
                  prop="create_time"
                  label="发送时间">
                </el-table-column>
                <el-table-column
                  prop="operator_name"
                  label="操作人">
                </el-table-column>
                <el-table-column
                  label="操作">
                <template  slot-scope="scope">
                  <el-button @click='bcDataE(scope.row.id)' size="mini" type="info" plain>{{scope.row.child_status != 1 ? '编辑' : '查看'}}</el-button>
                  <el-button v-if='scope.row.child_status == 1' @click='bcDataA(scope.row.id)' size="mini" type="info" plain>追加</el-button>
                  <el-button @click='bcHistory(scope.row.id)' size="mini" type="info" plain>历史记录</el-button>
                </template>
                </el-table-column>
              </el-table>

              <div class='exchangeByOrder'>补充收款信息</div>
              <div style="text-align: left;margin-bottom: 10px;">
                <el-button v-if="banklistData.length == 0"  @click="xinchuangjian()" type="primary" style="font-size: 16px;font-weight: bold;" plain>新创建</el-button>
              </div>
              <el-table
                v-loading="loading"
                :data="banklistData"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                >
                <el-table-column
                  prop="child_status"
                  label="处理状态">
                <template  slot-scope="scope" >
                  {{scope.row.child_status == 0 ? '待发送' : ''}}
                  {{scope.row.child_status == 1 ? '等待用户回复' : ''}}
                  <font v-if="scope.row.child_status == 2" style="color:red;">待处理</font>
                  {{scope.row.child_status == 3 ? '已完成' : ''}}
                </template>
                </el-table-column>
                <el-table-column
                  prop="is_complete"
                  label="全部收集完成">
                <template  slot-scope="scope">
                  {{scope.row.is_complete==1?'是':'否'}}
                </template>
                </el-table-column>
                <el-table-column
                  prop="operator_name"
                  label="操作人">
                </el-table-column>
                <el-table-column
                  label="操作">
                <template  slot-scope="scope">
                  <el-button @click='BankDataE(scope.row.id)' size="mini" type="info" plain>编辑</el-button>
                  <el-button @click='BankHistory()' size="mini" type="info" plain>历史记录</el-button>
                </template>
                </el-table-column>
              </el-table>
              
              <div class='exchangeByOrder'>用户主动联系客服</div>
              <el-table
                v-loading="loading"
                :data="exchangeByOrderData"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                >
                <el-table-column
                  prop="child_status"
                  label="状态">
                <template  slot-scope="scope">
                  {{scope.row.child_status==1?'已处理':''}}
                  <font v-if="scope.row.child_status!=1" style="color:red;">未处理</font>
                </template>
                </el-table-column>
                <el-table-column
                  prop="created_at"
                  label="发送时间">
                </el-table-column>
                <el-table-column
                  prop="is_call"
                  label="要求客服联系">
                <template  slot-scope="scope">
                  {{scope.row.is_call==1?'打通':scope.row.is_call==-1?'--':'未打通'}}
                </template>
                </el-table-column>
                <el-table-column
                  prop="operator_name"
                  label="操作人">
                </el-table-column>
                <el-table-column
                  label="操作">
                <template  slot-scope="scope">
                  <el-button @click='ByOrderDataE(scope.row.id)' size="mini" type="info" plain>编辑</el-button>
                </template>
                </el-table-column>
              </el-table>
        </div>
      </div>
      <div class="div9" v-if="key == '9'">
        <dir style='text-align: left;padding-left: 0;'>
          <el-select style='margin-right: 10px;' filterable  v-model="tsItemDate.value" placeholder="请选择">
            <el-option
              v-for="(item,i) in tsItem"
              :key="i"
              :label="item.nick"
              :value="item.nick">
            </el-option>
          </el-select>
          <el-button type="primary" @click="insert">插入项目</el-button>
          <el-button type="primary" @click="preview">预览</el-button>
          <el-button type="primary" @click="makeUseOf">使用模板</el-button>
          <el-button type="primary" @click="increase">添加记录</el-button>
        </dir> 
        <div class="div9content">
            <el-input type="textarea" :autosize="{ minRows: 20, maxRows: 30}" v-model="tsItemDate.content"></el-input>
        </div>
      </div>
      <div class="div10" v-if="key == '10'">
        <div>
          <div class='exchangeByOrder'>限时行为记录</div>
              <div style="text-align: left;margin-bottom: 10px;">
                <el-button  @click="action_list_add()" type="primary" style="font-size: 16px;font-weight: bold;" plain>添加</el-button>
              </div>
              <el-table
                v-loading="loading"
                :data="actionlist"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                >
                <el-table-column
                  prop="create_time"
                  label="记录日期">
                </el-table-column>
                <el-table-column
                  prop="order_no"
                  width='250'
                  label="订单编号">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="姓名">
                </el-table-column>
                <el-table-column
                  prop="phone"
                  label="注册手机">
                </el-table-column>
                <el-table-column
                  prop="actions.name"
                  label="行为记录">
                </el-table-column>
                <el-table-column
                  prop="specific_situations.name"
                  label="具体情况">
                </el-table-column>
                <el-table-column
                  prop="specific_doubt.name"
                  label="具体存疑点">
                </el-table-column>
                <el-table-column
                  prop="specific_materials_text"
                  label="具体材料">
                </el-table-column>
                <el-table-column
                  prop="remark"
                  label="备注">
                </el-table-column>
                <el-table-column
                  label="操作">
                <template  slot-scope="scope">
                  <el-button @click='editaclog(scope.row)' size="mini" type="info" plain>编辑</el-button>
                  <el-button @click='delaclog(scope.row.id)' size="mini" type="info" plain>删除</el-button>
                </template>
                </el-table-column>
              </el-table>

        </div>
      </div>
      <div class='div11' v-if="key == '11'">
        <div class="pt20"></div>
        <el-descriptions title="违约金总计">
          <el-descriptions-item label="金额">{{damagedata.total}}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="逾期支付服务费">
          <el-descriptions-item label="计算方式">
            <el-select v-model="damagedata.op_amount_flg" class="select1" placeholder="请选择">
              <el-option label="系统" :value="0"></el-option>
              <el-option label="自定义" :value="1"></el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item v-if="damagedata.op_amount_flg === 1 ? false : true"  label="起计日期">
            <el-date-picker
                @change="setopamountdate"
                v-model="damagedata.op_amount_date"
                type="datetime"
                format='yyyy-MM-dd'
                placeholder="选择日期">
            </el-date-picker>
          </el-descriptions-item>
          <el-descriptions-item label="金额">
            <el-input v-if='damagedata.op_amount_flg === 1' :disabled='false' v-model="damagedata.custom_op_amount" placeholder=""></el-input>
            <div v-if='damagedata.op_amount_flg === 0'>{{damagedata.op_amount}}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="延迟告知退赔">
          <el-descriptions-item label="计算方式">
            <el-select v-model="damagedata.dn_amount_flg" class="select1" placeholder="请选择">
              <el-option label="系统" :value="0"></el-option>
              <el-option label="自定义" :value="1"></el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item v-if="damagedata.dn_amount_flg === 1 ? false : true"  label="起计日期">
            <el-date-picker
                @change="setdnamountsdate"
                v-model="damagedata.dn_amount_sdate"
                type="datetime"
                format='yyyy-MM-dd'
                placeholder="选择日期">
            </el-date-picker>
          </el-descriptions-item>
          <el-descriptions-item v-if="damagedata.dn_amount_flg === 1 ? false : true"  label="中止日期">
            <el-date-picker
                @change="setdnamountsdate"
                v-model="damagedata.dn_amount_edate"
                type="datetime"
                format='yyyy-MM-dd'
                placeholder="选择日期">
            </el-date-picker>
          </el-descriptions-item>
          <el-descriptions-item label="金额">
            <el-input v-if='damagedata.dn_amount_flg === 1' :disabled='false' v-model="damagedata.custom_dn_amount" placeholder=""></el-input>
            <div v-if='damagedata.dn_amount_flg === 0'>{{damagedata.dn_amount}}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="拒绝告知退赔">
          <el-descriptions-item label="计算方式">
            <el-select v-model="damagedata.rti_amount_flg" class="select1" placeholder="请选择">
              <el-option label="系统" :value="0"></el-option>
              <el-option label="自定义" :value="1"></el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item v-if="damagedata.rti_amount_flg === 1 ? false : true" label="赔偿依据">
            <el-select @change="setrtiamountflg" v-model="damagedata.rti_amount_rely" class="select1" placeholder="请选择">
              <el-option label="---无---" :value="0"></el-option>
              <el-option label="获赔金额" :value="1"></el-option>
              <el-option label="申请金额" :value="2"></el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="金额">
            <el-input v-if='damagedata.rti_amount_flg === 1' :disabled='false' v-model="damagedata.custom_rti_amount" placeholder=""></el-input>
            <div v-if='damagedata.rti_amount_flg === 0'>{{damagedata.rti_amount}}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="拒绝协助">
          <el-descriptions-item label="计算方式">
            <el-select v-model="damagedata.roa_amount_flg" class="select1" placeholder="请选择">
              <el-option label="系统" :value="0"></el-option>
              <el-option label="自定义" :value="1"></el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item v-if="damagedata.roa_amount_flg === 1 ? false : true" label="赔偿依据">
            <el-select @change="setroaamountflg" v-model="damagedata.roa_amount_rely" class="select1" placeholder="请选择">
              <el-option label="---无---" :value="0"></el-option>
              <el-option label="获赔金额" :value="1"></el-option>
              <el-option label="申请金额" :value="2"></el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="金额">
            <el-input v-if='damagedata.roa_amount_flg === 1' :disabled='false' v-model="damagedata.custom_roa_amount" placeholder=""></el-input>
            <div v-if='damagedata.roa_amount_flg === 0'>{{damagedata.roa_amount}}</div>
          </el-descriptions-item>
        </el-descriptions>
        <div class="pt20"></div>
        <el-button @click="editdamage()" type="primary">提交</el-button>
      </div>
      <el-dialog
        title="预览/添加图片"
        :visible.sync="centerDialogVisible"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <el-button @click="picdownload()" type="primary"><i class="el-icon-download"></i>下载</el-button>
          <div class="pt20"></div>
          <div class="dis_flx">
            <div class="block" v-for="(pic,index) in picdata" :key="index">
              <span class="demonstration">{{ pic.name }}</span>
              <a :href="pic.url" target="_blank" class="thumbnail">
              <el-image 
                style="width: 200px; height: 200px"
                :src="pic.url"
                fit="contain"></el-image>
              </a>
            </div>
          </div>
      </el-dialog>
      <el-dialog
        title="关联订单"
        :visible.sync="Associatediv"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div style="font-size: 20px;">主订单：{{topOrder_no}}</div>
        <div style="line-height: 36px;font-size: 18px;">子订单：
          <div class="inpdiv" v-for="(orderelation,index) in orderelations" :key="index">
            <input placeholder="请输入订单号" class="inps" v-model="orderelation.sub_order_no" type="text">
            <input placeholder="请输入手机号" class="inps" v-model="orderelation.phone" type="text">
            <i @click="AssociateDF(index)" class="el-icon-delete inpis"></i>
          </div>
        </div>
        <el-button style="margin-left: 10px;" @click="AssociateAdd()" type="primary"><i class="el-icon-plus"></i>追加</el-button>
        <div class="pt20"></div>
        <el-button style="margin-left: 50%;transform: translate(-50%, 0%);" @click="define()" type="primary">确定</el-button>
        <div class="pt20"></div>
      </el-dialog>
      <el-dialog
        title="添加"
        :visible.sync="InvitationShow"
        :fullscreen="false"
        :modal="false"
        width="75%"
        hight="80%"
        center>
        <div>
          <div class="pt20"></div>
          <div>修改项目:</div>
          <div class="pt10"></div>
          <el-checkbox v-model="sendInvitationPost.fdata">航班信息</el-checkbox>
          <el-checkbox v-model="sendInvitationPost.name">委托人姓名</el-checkbox>
          <el-checkbox v-model="sendInvitationPost.oidnum">证件号码</el-checkbox>
          <el-checkbox v-model="sendInvitationPost.ota">被投诉人</el-checkbox>
          <el-checkbox v-model="sendInvitationPost.sign">签名</el-checkbox><div class="pt20"></div>
          <div>被投诉人:</div>
          <el-select  v-el-select-loadmore="loadMore"  :disabled='!sendInvitationPost.ota' filterable v-model="sendInvitationPost.ota_item" multiple  placeholder="请选择">
            <el-option 
              v-for="item in otadata"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
          <el-button type="primary" style='margin-left:10px;' @click="deleteota_item()">删除全部</el-button>
          <div class="pt20"></div>
          <div>航班页说明:</div>
          <div class="pt10"></div>
          <el-input v-model="sendInvitationPost.fdata_remark"  placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
          <div class="pt20"></div>
          <div>信息页说明:</div>
          <div class="pt10"></div>
          <el-input v-model="sendInvitationPost.user_remark"  placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
          <div class="pt20"></div>
          <div>签名页说明:</div>
          <div class="pt10"></div>
          <el-input v-model="sendInvitationPost.sign_remark"  placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
          <div class="pt20"></div>
          <div style="text-align: center;"><el-button type="primary" @click="AddsendInvitationPost()">发送</el-button></div>
          <div class="pt20"></div>
        </div>
      </el-dialog>
      <el-dialog
        title="修改信息"
        :visible.sync="ModifyInformation"
        :fullscreen="false"
        :modal="false"
        width="85%"
        hight="80%"
        center>
        <div>
          <el-button @click="AddInvitation()" type="primary"><i class="el-icon-plus"></i>添加</el-button>
          <div class="pt20"></div>
          <el-table
                :data="InvitationData"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                >
                <el-table-column
                  prop="create_time"
                  label="发送时间">
                  <template  slot-scope="scope">
                    {{scope.row.create_time | formatDate}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="operator_name"
                  label="操作人员">
                </el-table-column>
                <el-table-column
                  label="操作">
                <template slot-scope="scope">
                   <el-button type="primary" @click="sendInvitationInfo(scope.row.id)">查看</el-button>
                </template>
                </el-table-column>
          </el-table>
        </div>
      </el-dialog>
        <el-dialog
        title="编辑"
        :visible.sync="ByOrderDataInfoShow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
          <div>上传文件:
            <div v-for="(img,index) in ByOrderDataInfo.imgs" :key="index">
              <el-input type="text" v-model="names[index]" placeholder="请输入名称"></el-input>
              <el-image style="width: 100px; height: 100px" :src="img" :preview-src-list="ByOrderDataInfo.imgs"></el-image>
          </div>
        </div>
          <div class="pt20"></div>
          <div>备注信息:</div>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10}"
            disabled
            placeholder="请输入内容"
            v-model="ByOrderDataInfo.remark">
          </el-input>
          <div class="pt20"></div>
          <div>不通过原因:</div>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10}"
            placeholder="请输入内容"
            v-model="ByOrderDataInfo.message">
          </el-input>
          <div class="pt20"></div>
          <div>要求客服联系:</div>
          <el-radio v-model="ByOrderDataInfo.is_call" :label="1">打通</el-radio>
          <el-radio v-model="ByOrderDataInfo.is_call" :label="0">未打通</el-radio>
          <div class="pt20" style='text-align: center;'>
            <el-button @click="InfoExchange()" type="primary">保存</el-button>
          </div>
      </el-dialog>
      <el-dialog
        title="预览"
        :visible.sync="previewShow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
          <div id='previewDate' style="white-space: pre-wrap;">{{previewDate}}</div>
          <div class="pt20" style='text-align: center;'>
            <el-button @click="previewDateCopy()" type="primary">复制</el-button>
          </div>
      </el-dialog>
      <el-dialog
        title="模板"
        :visible.sync="makeUseOfShow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form :inline="true" :model="tsmodel" style="position: relative;" class="demo-form-inline">
            <el-form-item style="font-weight: bold;" label="订单类型">
              <el-select  v-model="tsmodel.type" class="select1" placeholder="请选择">
                    <el-option label="请选择"  :value="0"></el-option>
                    <el-option label="航班"  :value="1"></el-option>
                    <el-option label="行李"  :value="2"></el-option>
                    <el-option label="机票"  :value="3"></el-option>
                  </el-select>
            </el-form-item>
            <el-form-item style="font-weight: bold;" label="关键字">
             <el-input v-model="tsmodel.search_data" style="width:400px" placeholder="请输入"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="makeUseOf('CHA')">查询</el-button>
            <el-button  type="primary"  style="margin-left:20px" @click="addmodelist()">添加</el-button>
            </el-form-item>
      </el-form> 
            <el-table
          v-loading="makeloading"
          :data="makeUseOfDate"
          style="width: 100%"
          :header-cell-style="{background:'#F5F7FA'}"
          >
          <el-table-column
            prop="title"
            label="标题">
          </el-table-column>
          <el-table-column
            prop="is_default"
            label="是否默认">
          <template  slot-scope="scope">
            {{scope.row.is_default == 1 ? '是' : '否'}}
          </template>
          </el-table-column>
          <el-table-column
            prop="createtime"
            label="创建时间">
          </el-table-column>
          <el-table-column
              prop="id"
              label="操作">
                <template slot-scope="scope">
                  <el-button type="primary" @click="modolauc(scope.row.content)">使用</el-button>
                  <el-button type="primary" @click="modoledit(scope.row)">编辑</el-button>
                  <el-button type="primary" @click="modoldel(scope.row.id)">删除</el-button>
                </template> 
          </el-table-column>
        </el-table>
        </div>
        <el-pagination
          style="text-align: center;margin-top: 20px;"
          @current-change="modelChange"
          :current-page.sync="model_page"
          :page-size="modeler_page"
          layout="total,prev, pager, next, jumper"
          :total="modeltotal">
        </el-pagination>
      </el-dialog>
      <el-dialog
        title="添加"
        :visible.sync="addmodeshow"
        :fullscreen="false"
        :modal="false"
        width="70%"
        hight="70%"
        center>
        <div>
          <el-form ref="form" :model="addmodedata" label-width="120px">
            <el-form-item label="标题:" prop="title">
              <el-input v-model.trim="addmodedata.title" placeholder="请输入标题" maxlength="50" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="分类" prop="type">
              <el-select  v-model="addmodedata.type" class="select1" placeholder="请选择">
                    <el-option label="请选择"  :value="0"></el-option>
                    <el-option label="航班"  :value="1"></el-option>
                    <el-option label="行李"  :value="2"></el-option>
                    <el-option label="机票"  :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="内容:" prop="content">
             <dir style='text-align: left;padding-left: 0;'>
              <el-select style='margin-right: 10px;' filterable  v-model="tsItemDate.value" placeholder="请选择">
                <el-option
                  v-for="(item,i) in tsItem"
                  :key="i"
                  :label="item.nick"
                  :value="item.nick">
                </el-option>
              </el-select>
              <el-button type="primary" @click="inmodel">插入项目</el-button>
              <div class="div9content">
                  <el-input type="textarea" :autosize="{ minRows: 20, maxRows: 30}" v-model="addmodedata.content"></el-input>
              </div>
            </dir> 
          </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="putaddmode">添加</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
      <el-dialog
        title="编辑"
        :visible.sync="editmodeshow"
        :fullscreen="false"
        :modal="false"
        width="70%"
        hight="70%"
        center>
        <div>
          <el-form ref="form" :model="editmodedata" label-width="120px">
            <el-form-item label="标题:" prop="title">
              <el-input v-model.trim="editmodedata.title" placeholder="请输入标题" maxlength="50" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="分类" prop="type">
              <el-select  v-model="editmodedata.type" class="select1" placeholder="请选择">
                    <el-option label="请选择"  :value="0"></el-option>
                    <el-option label="航班"  :value="1"></el-option>
                    <el-option label="行李"  :value="2"></el-option>
                    <el-option label="机票"  :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否默认" prop="is_default">
              <el-select  v-model="editmodedata.is_default" class="select1" placeholder="请选择">
                    <el-option label="否"  :value="0"></el-option>
                    <el-option label="是"  :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="内容:" prop="content">
             <dir style='text-align: left;padding-left: 0;'>
              <el-select style='margin-right: 10px;' filterable  v-model="tsItemDate.value" placeholder="请选择">
                <el-option
                  v-for="(item,i) in tsItem"
                  :key="i"
                  :label="item.nick"
                  :value="item.nick">
                </el-option>
              </el-select>
              <el-button type="primary" @click="inemodel">插入项目</el-button>
              <div class="div9content">
                  <el-input type="textarea" :autosize="{ minRows: 20, maxRows: 30}" v-model="editmodedata.content"></el-input>
              </div>
            </dir> 
          </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="postaddmode">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
      <el-dialog
        title="新创建"
        :visible.sync="addbankshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="汇款地区类型:" prop="type">
              <el-radio-group @change='getitems' v-model="form.type" required>
                <el-radio label="1">境内</el-radio>
                <el-radio label="2">境外</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="前端展示列" prop="item">
              <el-checkbox-group  v-model="form.item">
                <el-checkbox v-for="(it,index) in bankitemlist" :key="index" :label="it.id" required>{{ it.name }}</el-checkbox>
              </el-checkbox-group>
              <el-input v-if="form.type == 2" v-model.trim="form.explain" placeholder="可接收外币种类" maxlength="50" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="客服留言:" prop="remark">
              <el-input v-model.trim="form.remark" placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="submitFormBC">提交</el-button>
              <el-button @click="resetForm">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
      <el-dialog
        title="编辑"
        :visible.sync="editactionlistshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form ref="logform" :model="logform" label-width="120px">
            <el-form-item label="行为记录:" prop="remark">
              <el-select v-model="logform.actionlogtype1" @change="actionlogtypeson(logform.actionlogtype1,1)" placeholder="请选择">
                <el-option
                  v-for="item in actionlogtypelist"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="具体情况:" prop="remark">
              <el-select v-model="logform.actionlogtype2" @change="actionlogtypeson(logform.actionlogtype2,2)" placeholder="请选择">
                <el-option
                  v-for="item in actionlogtypelist2"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="具体存疑点:" prop="remark">
              <el-select v-model="logform.actionlogtype3" @change="actionlogtypeson(logform.actionlogtype3,3)" placeholder="请选择">
                <el-option
                  v-for="item in actionlogtypelist3"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="具体材料:" prop="remark">
              <el-select style="width:500px;"
              multiple
              v-model="logform.actionlogtype4" placeholder="请选择">
                <el-option
                  v-for="item in actionlogtypelist4"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:" prop="remark">
              <el-input v-model.trim="logform.remark" placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="editsubmitactionlog">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
      <el-dialog
        title="添加"
        :visible.sync="addactionlistshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form ref="logform" :model="logform" label-width="120px">
            <el-form-item label="行为记录:" prop="remark">
              <el-select v-model="logform.actionlogtype1" @change="actionlogtypeson(logform.actionlogtype1,1)" placeholder="请选择">
                <el-option
                  v-for="item in actionlogtypelist"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="具体情况:" prop="remark">
              <el-select v-model="logform.actionlogtype2" @change="actionlogtypeson(logform.actionlogtype2,2)" placeholder="请选择">
                <el-option
                  v-for="item in actionlogtypelist2"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="具体存疑点:" prop="remark">
              <el-select v-model="logform.actionlogtype3" @change="actionlogtypeson(logform.actionlogtype3,3)" placeholder="请选择">
                <el-option
                  v-for="item in actionlogtypelist3"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="具体材料:" prop="remark">
              <el-select style="width:500px;"
              multiple
              v-model="logform.actionlogtype4" placeholder="请选择">
                <el-option
                  v-for="item in actionlogtypelist4"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:" prop="remark">
              <el-input v-model.trim="logform.remark" placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="submitactionlog">保存</el-button>
              <!-- <el-button @click="resetlogform">重置</el-button> -->
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
      <el-dialog
        title="追加"
        :visible.sync="bcAshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div class="conentbox">
            <div class="remarkds contflex">
              <div class="bcedatatitle">问题类型:</div>
              <div> 
                <el-button class="btnblack" type="info" @click="addfjappend(1)">下拉框</el-button>
                <el-button class="btnblack" type="info" @click="addfjappend(2)">输入框</el-button>
                <el-button class="btnblack" type="info" @click="addfjappend(3)">上传图片</el-button>
                <el-button class="btnblack" type="info" @click="addfjappend(4)">损失清单</el-button>
                <el-button class="btnblack" type="info" @click="addfjappend(5)">自行投诉记录</el-button>
              </div>
            </div>
            <div class="pt20" style='padding-top: 80px;'></div>
            <div ref="bcontainer">
               <div  :id='index' ref='bdivRef' v-for="(dom,index) in fjappenddata" :key="index" >
                <div>
                    类型：{{dom.item_type | types}} 
                    <el-checkbox class="domcheck" v-model="dom.is_must">必填项</el-checkbox>
                    <el-button class="btnblack" type="info" @click="delfjappend(index)">删除</el-button>
                    <el-button class="btnblack" type="info" @click="domodel(1,index)">使用模板</el-button>
                </div>
                <div class="pt20"></div>
                <div class="remarkds">
                  <div class='bcedatatitle' style="line-height: 40px;">问题名称:</div>
                  <div style="margin-top: 2px;">
                    <el-input v-model="dom.name" placeholder="请输入"></el-input>
                  </div>
                </div>
                <div class="pt20" v-if="dom.item_type == 1"></div>
                <div class="remarkds" v-if="dom.item_type == 1">
                  <div class='bcedatatitle' style="line-height: 40px;">选项:</div>
                  <div style="margin-top: 2px;">
                    <el-input style="min-width: 400px;" v-model="dom.content" placeholder="多项目换行表示" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
                  </div>
                </div>
                <div class="pt20" v-if="dom.item_type == 3"></div>
                <div class="remarkds" v-if="dom.item_type == 3">
                  <div class='bcedatatitle' style="line-height: 40px;">图片类型:</div>
                  <div style="margin-top: 2px;">
                    <el-select v-model="dom.image_type" placeholder="请选择">
                      <el-option
                        v-for="item in selectimagetype"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                      </el-option>
                      <el-option
                        label="请选择"
                        :value="0">
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="pt20"></div>
              </div>
            </div>
            <div class="pt20"></div>
            <div style="text-align: center;">
            <el-button type="primary" @click="putfjappend()">追加</el-button>
            </div>
          </div>
      </el-dialog>
      <el-dialog
        title="新创建"
        :visible.sync="bcaddshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="90%"
        center>
        <div class="conentbox">
          <div style='box-shadow: 5px 5px 5px #888888;position: fixed;
            width: 75%;
            background-color: white;
            z-index: 999;'> 
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle'>案件类型:</div>
              <div style="margin-top: 2px;">
                <el-radio v-model="bcedata.type" :label="1">行李</el-radio>
                <el-radio v-model="bcedata.type" :label="2">非行李</el-radio>
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle'>截止时间:</div>
              <el-date-picker
                v-model="bcedata.date"
                type="datetime"
                :format="valueformat"
                :editable='true'
                @focus="timef"
                @blur="timeb"
                placeholder="选择截止时间">
              </el-date-picker>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class="bcedatatitle">说明:</div>
              <el-input v-model="bcedata.remark" placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </div>
            <div  class="pt20"></div>
            <div  class="remarkds">
              <div class="bcedatatitle">问题类型:</div>
              <div> 
                <el-button class="btnblack" type="info" @click="adddom(1)">下拉框</el-button>
                <el-button class="btnblack" type="info" @click="adddom(2)">输入框</el-button>
                <el-button class="btnblack" type="info" @click="adddom(3)">上传图片</el-button>
                <el-button class="btnblack" type="info" @click="adddom(4)">损失清单</el-button>
                <el-button class="btnblack" type="info" @click="adddom(5)">自行投诉记录</el-button>
              </div>
            </div>
            <div class="pt20"></div>
          </div>
          <div style='height:270px;'></div>
            <!-- <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle'>收集完成:</div>
              <div style="margin-top: 2px;">
                <el-radio v-model="bcedata.is_complete" :label="1">是</el-radio>
                <el-radio v-model="bcedata.is_complete" :label="0">否</el-radio>
              </div>
            </div> -->
            <!-- <div>
              <div class="remarkds">
                <div class='bcedatatitle'>问题名称:</div>
                <div style="margin-top: 2px;">
                  损失测试
                </div>
              </div>
              <div class="pt20"></div>
              <div class="remarkds">
                <div class='bcedatatitle' style="line-height: 40px;">回复:</div>
                <div style="margin-top: 2px;line-height: 40px;">
                  有损失
                  <el-button class="btnblack liulan" type="info" @click="liulandom(bcedata.id)">浏览</el-button>
                  <el-radio v-model="bcedata.sj" :label="1">是</el-radio>
                  <el-radio v-model="bcedata.sj" :label="0">否</el-radio>
                </div>
              </div>
              <div class="pt20"></div>
              <div class="remarkds">
                <div class='bcedatatitle'>错误提示:</div>
                <el-input v-model="bcedata.remark" placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
              </div>
            </div> -->
            <div v-for="(dom,index) in bcedata.question" :key="index" >
              <div>
                  类型：{{dom.item_type | types}} 
                  <el-checkbox class="domcheck" v-model="dom.is_must" :true-label="1" :false-label="0" >必填项</el-checkbox>
                  <el-button  class="btnblack" type="info" @click="deldom(index)">删除</el-button>
                  <el-button  class="btnblack" type="info" @click="domodel(2,index)">使用模板</el-button>
              </div>
              <div class="pt20"></div>
              <div class="remarkds">
                <div class='bcedatatitle' style="line-height: 40px;">问题名称:</div>
                <div style="margin-top: 2px;">
                  <el-input v-model="dom.name" placeholder="请输入"></el-input>
                </div>
              </div>
              <div class="pt20" v-if="dom.item_type == 1"></div>
              <div class="remarkds" v-if="dom.item_type == 1">
                <div class='bcedatatitle' style="line-height: 40px;">选项:</div>
                <div style="margin-top: 2px;">
                  <el-input style="min-width: 400px;" v-model="dom.content" placeholder="多项目换行表示" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
                </div>
              </div>
              <div class="pt20" v-if="dom.item_type == 3"></div>
              <div class="remarkds" v-if="dom.item_type == 3">
                <div class='bcedatatitle' style="line-height: 40px;">图片类型:</div>
                <div style="margin-top: 2px;">
                  <el-select v-model="dom.image_type" placeholder="请选择">
                    <el-option
                      v-for="item in selectimagetype"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                    </el-option>
                      <el-option
                        label="请选择"
                        :value="0">
                      </el-option>
                  </el-select>
                </div>
              </div>
              <div class="pt20"></div>
            </div>
            <div class="pt20"></div>
            <div  style="text-align: center;">
            <!-- <el-button type="primary" @click="bcDetailinfo()">预览</el-button> -->
            <el-button @click="addDetailEdit()">发送</el-button>
            </div>
          </div>
      </el-dialog>
      <el-dialog
        title="预览"
        :visible.sync="tailinfoshow"
        :fullscreen="false"
        :modal="false"
        width="70%"
        hight="70%"
        center>
        <div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle'>案件类型:</div>
              <div style="margin-top: 2px;">{{bcedata.type == 1 ? '行李' : '非行李'}}
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle'>截止时间:{{ bcedata.date }}</div>
              
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class="bcedatatitle">说明:</div>
              {{bcedata.remark}}
            </div>
            <div class="pt20"></div>
            <div  v-for="(dom,index) in infoshowdata" :key="index">
              <div  class="bcedatatitle">问题{{index+1}}:{{dom.name}}</div>
            </div>
            <div class="pt20"></div>
          </div>
      </el-dialog>
      <el-dialog
        :title="bcedata.child_status != 1 ? '编辑' : '查看'"
        :visible.sync="bcshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div class="conentbox">
          <div class="editflex">
            <div class="remarkds">
              <div class='bcedatatitle'>案件类型:</div>
              <div style="margin-top: 2px;">
                <el-radio v-model="bcedata.type" :label="1">行李</el-radio>
                <el-radio v-model="bcedata.type" :label="2">非行李</el-radio>
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle'>截止时间:</div>
              <el-date-picker
                v-model="bcedata.date"
                type="datetime"
                :format="valueformat"
                :editable='true'
                @focus="timef"
                @blur="timeb"
                placeholder="选择截止时间">
              </el-date-picker>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class="bcedatatitle">说明:</div>
              <el-input v-model="bcedata.remark" placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </div>
            <div v-if='bcedata.child_status != 1' class="pt20"></div>
            <div v-if='bcedata.child_status != 1' class="remarkds">
              <div class="bcedatatitle">问题类型:</div>
              <div> 
                <el-button class="btnblack" type="info" @click="bjadd(1)">下拉框</el-button>
                <el-button class="btnblack" type="info" @click="bjadd(2)">输入框</el-button>
                <el-button class="btnblack" type="info" @click="bjadd(3)">上传图片</el-button>
                <el-button class="btnblack" type="info" @click="bjadd(4)">损失清单</el-button>
                <el-button class="btnblack" type="info" @click="bjadd(5)">自行投诉记录</el-button>
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle'>收集完成:</div>
              <div style="margin-top: 2px;">
                <el-radio v-model="bcedata.is_complete" :label="1">是</el-radio>
                <el-radio v-model="bcedata.is_complete" :label="0">否</el-radio>
              </div>
            </div>
          </div>
           <div style='padding-top:285px'></div>
            <!-- 不等于1就是编辑-->
            <div>
              <div class="pt20"></div>
              <div class="container" ref="container">
                <div :id='index' ref='divRef' :style="dom.is_allow == 2 ? 'background-color: whitesmoke;cursor: move;margin-bottom: 2px;':'cursor: move;'" 
                v-for="(dom,index) in bcedata.question" :key="index">
                  <div class="remarkds">
                    <div class='bcedatatitle'>问题名称:</div>
                    <div style="margin-top: 2px;">
                      {{dom.name}}
                    </div>
                  </div>
                  <div  v-for="(reply,index2) in dom.reply" :key="index2">
                    <div v-if='reply.content !== ""'>
                      <div class="pt20"></div>
                      <div class="remarkds">
                        <div class='bcedatatitle' style="line-height: 40px;">{{index2 == 0 ? '回复':'回复备注'}}:</div>
                        <div style="margin-top: 2px;line-height: 40px;">
                          <div v-if='reply.content == "否" && reply.name == "ss_has_loss"'>无损失</div>
                          <div v-if='reply.content == "否" && reply.name == "ssqd"'>无自行投诉记录</div>
                          <div v-if='!(reply.content == "否" && reply.name == "ss_has_loss") && !(reply.content == "否" && reply.name == "ssqd")'>
                            {{reply.name != 'images_url' ? reply.content : ''}}
                          </div>
                          <div style="display: flex;" v-if="reply.name == 'images_url'">
                            <div  v-for="(urlobj,urldex) in reply.urls" :key="urldex">
                              <el-image 
                              style="width: 200px; height: 200px"
                              :src="urlobj.url"
                              fit="contain"></el-image>
                              <div style="margin-top: 2px;line-height: 40px;">
                                <input type="radio"   v-model="urlobj.is_allow" :name="urlobj.url"  value="1"> 通过 
                                <input type="radio"   v-model="urlobj.is_allow" :name="urlobj.url" value="0"> 不通过
                              </div>
                            </div>
                          </div> 
                          <el-button v-if='reply.content == "是" && (dom.item_type == 4 || dom.item_type == 5) && bcedata.child_status != 1' class="btnblack liulan" type="info" @click="liulandom(reply.no,dom.item_type,reply.id)">浏览</el-button>
                          <div v-if="reply.content != '否' && reply.name != 'images_url'">
                            <el-radio :disabled='reply.content == "是" && (dom.item_type == 4 || dom.item_type == 5)' v-model="reply.is_allow" label="1">通过</el-radio>
                            <el-radio :disabled='reply.content == "是" && (dom.item_type == 4 || dom.item_type == 5)' v-model="reply.is_allow" label="0">不通过</el-radio>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pt20"></div>
                  <div class="remarkds">
                    <div class='bcedatatitle'>错误提示:</div>
                    <el-input v-model="dom.remark" placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
                  </div>
                  <div class="pt20"></div>
                </div>
                <div v-for="(dom,index) in bcitem" :key="index + 'zz'" >
                <div>
                    类型：{{dom.item_type | types}} 
                    <el-checkbox class="domcheck" v-model="dom.is_must">必填项</el-checkbox>
                    <el-button class="btnblack" type="info" @click="delbcitem(index)">删除</el-button>
                    <el-button class="btnblack" type="info" @click="domodel(3,index)">使用模板</el-button>
                </div>
                <div class="pt20"></div>
                <div class="remarkds">
                  <div class='bcedatatitle' style="line-height: 40px;">问题名称:</div>
                  <div style="margin-top: 2px;">
                    <el-input v-model="dom.name" placeholder="请输入"></el-input>
                  </div>
                </div>
                <div class="pt20" v-if="dom.item_type == 1"></div>
                <div class="remarkds" v-if="dom.item_type == 1">
                  <div class='bcedatatitle' style="line-height: 40px;">选项:</div>
                  <div style="margin-top: 2px;">
                    <el-input style="min-width: 400px;" v-model="dom.content" placeholder="多项目换行表示" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
                  </div>
                </div>
                <div class="pt20" v-if="dom.item_type == 3"></div>
                <div class="remarkds" v-if="dom.item_type == 3">
                  <div class='bcedatatitle' style="line-height: 40px;">图片类型:</div>
                  <div style="margin-top: 2px;">
                    <el-select v-model="dom.image_type" placeholder="请选择">
                      <el-option
                        v-for="item in selectimagetype"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                      </el-option>
                      <el-option
                        label="请选择"
                        :value="0">
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="pt20"></div>
              </div>
              </div>
            </div>
            <div class="pt20"></div>
            <div v-if="bcedata.child_status != 1" style="text-align: center;">
             <el-button type="primary" @click="bcDetailinfo()">预览</el-button> 
            <el-button type="primary" @click="bcDetailEdit(1)">再次发送</el-button>
            <el-button @click="bcDetailEdit(2)">保存</el-button>
            </div>
          </div>
      </el-dialog>
      <el-dialog
        :title="liulantitle"
        :visible.sync="liulanshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-table
                v-if="liulantitle == '损失清单'"
                v-loading="loading"
                :data="ssqdata"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                >
                <el-table-column
                  type="index"
                  prop="id"
                  width="100"
                  label="序号">
                </el-table-column>
                <el-table-column
                  prop="time"
                  label="损失时间">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="损失名称">
                </el-table-column>
                <el-table-column
                  prop="price"
                  label="单价">
                </el-table-column>
                <el-table-column
                  prop="unit"
                  label="货币">
                </el-table-column>
                <el-table-column
                  prop="num"
                  label="数量">
                </el-table-column>
                <el-table-column
                  prop="has_voucher"
                  label="是否提供凭证">
                </el-table-column>
                <el-table-column
                  prop="is_allow"
                  label="通过情况">
                  <template slot-scope="scope">
                    {{scope.row.is_allow == 1 ? '通过' : scope.row.is_allow == 2 ? '未审核' : '不通过'}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="id"
                  label="操作">
                  <template slot-scope="scope">
                    <el-button type="primary" @click="liulaned(scope.row.id)">编辑</el-button>
                  </template>
                </el-table-column>
          </el-table>
          <el-table
                v-if="liulantitle == '自行投诉记录'"
                v-loading="loading"
                :data="zxtsdata"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                >
                <el-table-column
                type="index"
                  prop="id"
                  label="序号">
                </el-table-column>
                <el-table-column
                  prop="ts_date"
                  label="投诉日期">
                </el-table-column>
                <el-table-column
                  prop="ts_way"
                  label="投诉方式">
                </el-table-column>
                <el-table-column
                  prop="ts_channel"
                  label="投诉渠道">
                </el-table-column>
                <el-table-column
                  prop="ts_result"
                  label="简述投诉回复结果">
                </el-table-column>
                <el-table-column
                  prop="ts_has_voucher"
                  label="是否提供凭证">
                </el-table-column>
                <el-table-column
                  prop="is_allow"
                  label="通过情况">
                  <template slot-scope="scope">
                    {{scope.row.is_allow == 1 ? '通过' : scope.row.is_allow == 2 ? '未审核' : '不通过'}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="id"
                  label="操作">
                  <template slot-scope="scope">
                    <el-button type="primary" @click="liulaned2(scope.row.id)">编辑</el-button>
                  </template>
                </el-table-column>
          </el-table>
          </div>
      </el-dialog>
      <el-dialog
        title="模板列表"
        :visible.sync="usingtemplateshow"
        :fullscreen="false"
        :modal="false"
        width="70%"
        hight="80%"
        center>
        <div> 
          <div class="remarkds">
            <div class='bcedatatitle' style="line-height: 40px;">关键词:</div>
            <div style="margin-top: 2px;">
                <el-input v-model="enclosure.search_data" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="pt20"></div>
          <div class="remarkds">
            <div class='bcedatatitle' style="line-height: 40px;">分类:</div>
            <div style="margin-top: 2px;">
              <el-select v-model="enclosure.f_type" @change="setstatus3" placeholder="请选择">
                   <el-option
                      v-for="(ot,i) in options3"
                      :key="'a'+i"
                      :label="ot.name"
                      :value="ot.value">
                    </el-option>
                    <el-option
                      label="请选择"
                      :value="0">
                    </el-option>
              </el-select>
              <el-select v-model="enclosure.c_type" placeholder="请选择">
                    <el-option
                      v-for="(ot,i) in options4"
                      :key="'a'+i"
                      :label="ot.name"
                      :value="ot.value">
                    </el-option>
                    <el-option
                      label="请选择"
                      :value="0">
                    </el-option>
              </el-select>
            </div>
          </div>
          <div class="pt20"></div>
            <div style="text-align: left;margin-left: 80px;">
              <el-button type="primary" @click="getustemp('cha')">查询</el-button>
            </div>
          <div class="pt20"></div>
            <el-table
                v-loading="enclosureloading"
                :data="enclosureData"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                @selection-change="handleSelectionChange"
                ><el-table-column type="selection" width="55" align="center"/>
                <el-table-column
                  prop="id"
                  label="序号">
                </el-table-column>
                <el-table-column
                  prop="enclosure_name"
                  label="模板名称">
                </el-table-column>
                <el-table-column
                  prop="create_time"
                  label="创建时间">
                <template  slot-scope="scope">
                  {{scope.row.create_time | formatDate}}
                </template>
                </el-table-column>
                <el-table-column
                  prop="operator_name"
                  label="操作人员">
                </el-table-column>
                <el-table-column
                  label="操作">
                  <template slot-scope="scope">
                    <el-button type="primary" @click="Viewenclosure(scope.row.id)">查看</el-button>
                    <el-button type="primary" @click="Usingenclosure(scope.row.id)">使用</el-button>
                  </template>
                </el-table-column>
            </el-table>
            <div class="pt20"></div>
            <el-pagination
            style='text-align: center;'
              @current-change="handleCurrentChange2"
              :current-page.sync="current_page"
              :page-size="per_page"
              layout="total,prev, pager, next, jumper"
              :total="total">
            </el-pagination>
            
          </div>
      </el-dialog>
      <el-dialog
        title="问题模板"
        :visible.sync="modelshow"
        :fullscreen="false"
        :modal="false"
        width="70%"
        hight="80%"
        center>
        <div> 
          <div class="remarkds">
            <div class='bcedatatitle' style="line-height: 40px;">关键词:</div>
            <div style="margin-top: 2px;">
                <el-input v-model="modelshowdata.search_data" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="pt20"></div>
          <div class="remarkds">
            <div class='bcedatatitle' style="line-height: 40px;">分类:</div>
            <div style="margin-top: 2px;">
              <el-select v-model="modelshowdata.f_type" @change="setstatus2" placeholder="请选择">
                   <el-option
                      v-for="(ot,i) in options"
                      :key="'a'+i"
                      :label="ot.name"
                      :value="ot.value">
                    </el-option>
                    <el-option
                      label="请选择"
                      :value="0">
                    </el-option>
              </el-select>
              <el-select v-model="modelshowdata.c_type" placeholder="请选择">
                    <el-option
                      v-for="(ot,i) in options2"
                      :key="'a'+i"
                      :label="ot.name"
                      :value="ot.value">
                    </el-option>
                    <el-option
                      label="请选择"
                      :value="0">
                    </el-option>
              </el-select>
            </div>
          </div>
          <div class="pt20"></div>
            <div style="text-align: left;margin-left: 80px;">
              <el-button type="primary" :disabled = 'selectedNum == 0' @click="Batchuse()">批量使用</el-button>
              <el-button type="primary" @click="chaxunzi('cha')">查询</el-button>
            </div>
          <div class="pt20"></div>
            <el-table
                v-loading="modeloading"
                :data="modelshowlist"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                @selection-change="handleSelectionChange"
                ><el-table-column type="selection" width="55" align="center"/>
                <el-table-column
                  prop="id"
                  label="序号">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="模板名称">
                </el-table-column>
                <el-table-column
                  prop="create_time"
                  label="创建时间">
                <template  slot-scope="scope">
                  {{scope.row.create_time | formatDate}}
                </template>
                </el-table-column>
                <el-table-column
                  prop="operator_name"
                  label="操作人员">
                </el-table-column>
                <el-table-column
                  label="操作">
                  <template slot-scope="scope">
                    <el-button type="primary" @click="ViewTemplate(scope.row.id)">查看</el-button>
                    <el-button type="primary" @click="Usingtemplates(scope.row.id)">使用</el-button>
                  </template>
                </el-table-column>
            </el-table>
            <div class="pt20"></div>
            <el-pagination
            style='text-align: center;'
              @current-change="handleCurrentChange"
              :current-page.sync="current_page"
              :page-size="per_page"
              layout="total,prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
      </el-dialog>
      <el-dialog
        title="审核"
        :visible.sync="shenheshow"
        :fullscreen="false"
        :modal="false"
        width="60%"
        hight="80%"
        center>
        <div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">全部通过:</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-checkbox @change="handleCheckboxChange" v-model="shenheshowdata.checked">全部通过</el-checkbox>
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">损失时间:{{shenheshowdata.time}}</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-radio v-model="shenheshowdata.time_is_allow" :label="1">通过</el-radio>
                <el-radio v-model="shenheshowdata.time_is_allow" :label="0">不通过</el-radio>
              </div>
            </div>
            <div class="pt20"></div><div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">损失名称:{{shenheshowdata.name}}</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-radio v-model="shenheshowdata.name_is_allow" :label="1">通过</el-radio>
                <el-radio v-model="shenheshowdata.name_is_allow" :label="0">不通过</el-radio>
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">单价:{{shenheshowdata.price}}</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-radio v-model="shenheshowdata.price_is_allow" :label="1">通过</el-radio>
                <el-radio v-model="shenheshowdata.price_is_allow" :label="0">不通过</el-radio>
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">货币:{{shenheshowdata.unit}}</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-radio v-model="shenheshowdata.unit_is_allow" :label="1">通过</el-radio>
                <el-radio v-model="shenheshowdata.unit_is_allow" :label="0">不通过</el-radio>
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">数量:{{shenheshowdata.num}}</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-radio v-model="shenheshowdata.num_is_allow" :label="1">通过</el-radio>
                <el-radio v-model="shenheshowdata.num_is_allow" :label="0">不通过</el-radio>
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">是否提供凭证:{{shenheshowdata.has_voucher}}</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-radio v-model="shenheshowdata.has_voucher_is_allow" :label="1">通过</el-radio>
                <el-radio v-model="shenheshowdata.has_voucher_is_allow" :label="0">不通过</el-radio>
              </div>
            </div>
            <div class="remarkds" v-if="shenheshowdata.has_voucher == '是'">
              <div class="pt20"></div>
              <div  :key="i+'img'" v-for="(img,i) in shenheshowdata.arrimages">
                <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">图片{{i+1}}:
                  <el-image 
                  style="width: 200px; height: 200px"
                  :src="img"
                  fit="contain"></el-image></div>
                <div style="margin-top: 2px;line-height: 40px;">
                  <input type="radio"  @click='setarrimagesallow(i,1)' v-model="shenheshowdata.arrimagesallow[i].images_is_allow" :name="i"  value="1"> 通过 
                  <input type="radio"  @click='setarrimagesallow(i,0)' v-model="shenheshowdata.arrimagesallow[i].images_is_allow" :name="i" value="0"> 不通过
                </div>
              </div>
            </div>
            <div class="pt20"></div><div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">错误提示:</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-input v-model="shenheshowdata.remark" placeholder="请输入内容"></el-input>
              </div>
            </div>
            <div class="pt20"></div>
            <div style="text-align: center;">
              <el-button @click="shenheshowdatabaocun()">保存</el-button>
            </div>
          </div>
      </el-dialog>
      <el-dialog
        title="审核"
        :visible.sync="shenhe2show"
        :fullscreen="false"
        :modal="false"
        width="60%"
        hight="80%"
        center>
        <div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">全部通过:</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-checkbox @change="handleCheckboxChange2" v-model="shen2heshowdata.checked">全部通过</el-checkbox>
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">投诉日期:{{shen2heshowdata.ts_date}}</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-radio v-model="shen2heshowdata.ts_date_is_allow" :label="1">通过</el-radio>
                <el-radio v-model="shen2heshowdata.ts_date_is_allow" :label="0">不通过</el-radio>
              </div>
            </div>
            <div class="pt20"></div><div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">投诉方式:{{shen2heshowdata.ts_way}}</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-radio v-model="shen2heshowdata.ts_way_is_allow" :label="1">通过</el-radio>
                <el-radio v-model="shen2heshowdata.ts_way_is_allow" :label="0">不通过</el-radio>
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">投诉渠道:{{shen2heshowdata.ts_channel}}</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-radio v-model="shen2heshowdata.ts_channel_is_allow" :label="1">通过</el-radio>
                <el-radio v-model="shen2heshowdata.ts_channel_is_allow" :label="0">不通过</el-radio>
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">简述回复结果:{{shen2heshowdata.ts_result}}</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-radio v-model="shen2heshowdata.ts_result_is_allow" :label="1">通过</el-radio>
                <el-radio v-model="shen2heshowdata.ts_result_is_allow" :label="0">不通过</el-radio>
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">是否有投诉凭证:{{shen2heshowdata.ts_has_voucher}}</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-radio v-model="shen2heshowdata.ts_has_voucher_is_allow" :label="1">通过</el-radio>
                <el-radio v-model="shen2heshowdata.ts_has_voucher_is_allow" :label="0">不通过</el-radio>
              </div>
            </div>
            
            
            <div class="remarkds" v-if="shen2heshowdata.ts_has_voucher == '是'">
              <div class="pt20"></div>
              <div  :key="i+'img'" v-for="(img,i) in shen2heshowdata.arrimages">
                <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">图片{{i+1}}:
                  <el-image 
                  style="width: 200px; height: 200px"
                  :src="img"
                  fit="contain"></el-image></div>
                <div style="margin-top: 2px;line-height: 40px;">
                  <input type="radio"  @click='setarrimagesallow2(i,1)' v-model="shen2heshowdata.arrimagesallow[i].images_is_allow" :name="i"  value="1"> 通过 
                  <input type="radio"  @click='setarrimagesallow2(i,0)' v-model="shen2heshowdata.arrimagesallow[i].images_is_allow" :name="i" value="0"> 不通过
                </div>
              </div>
            </div>
            <div class="pt20"></div><div class="remarkds">
              <div class='bcedatatitle' style="line-height: 40px;margin-right:40px;">错误提示:</div>
              <div style="margin-top: 2px;line-height: 40px;">
                <el-input v-model="shen2heshowdata.remark" placeholder="请输入内容"></el-input>
              </div>
            </div>
            <div class="pt20"></div>
            <div style="text-align: center;">
              <el-button @click="shen2heshowdatabaocun()">保存</el-button>
            </div>
          </div>
      </el-dialog>
      <el-dialog
        title="编辑"
        :visible.sync="Ebankshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-table
                v-loading="loading"
                :data="Ebankdata.bank_item"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                >
                <el-table-column
                  prop="id"
                  label="序号">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="名称">
                </el-table-column>
                <el-table-column
                  prop="content"
                  label="填写信息">
                </el-table-column>
                <el-table-column
                  label="处理意见">
                <template  slot-scope="scope">
                  <el-radio v-model="scope.row.is_allow" :label="1">通过</el-radio>
                  <el-radio v-model="scope.row.is_allow" :label="0">未通过</el-radio>
                </template>
                </el-table-column>
          </el-table>
            <div class="pt20"></div>
            <div><el-button type="primary" @click="addbankitemshow(Ebankdata.bank.type)">{{add_item_show}}</el-button></div>
            <div class="pt10"></div>
            <div>
              <el-checkbox-group  v-model="Ebankadd_item">
                  <el-checkbox v-for="(it,index) in bankitemlist" :key="index" :label="it.id" required>{{ it.name }}</el-checkbox>
              </el-checkbox-group>
              <div class="pt10"></div>
              <el-input v-model="Ebankdata.bank.explain" v-if="bankitemlist.length != 0" placeholder="可接收外币种类" maxlength="50" show-word-limit clearable required></el-input>
            </div>
            <div class="pt20"></div>
            <div>备注信息</div>
            <div class="pt10"></div>
            <el-input v-model="Ebankdata.bank.cus_remark" disabled placeholder="" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            <div class="pt20"></div>
            <div>客服留言</div>
            <div class="pt10"></div>
            <el-input v-model="Ebankdata.remark" placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            <div class="pt20"></div>
            <div>全部收集完成</div>
            <div class="pt10"></div>
            <el-radio v-model="Ebankdata.bank.is_complete" :label="1">是</el-radio>
            <el-radio v-model="Ebankdata.bank.is_complete" :label="0">否</el-radio>
            <div class="pt20"></div>
            <div style="text-align: center;">
            <el-button type="primary" @click="bankDetailEdit(1)">再次发送</el-button>
            <el-button @click="bankDetailEdit(2)">保存</el-button>
            </div>
          </div>
      </el-dialog>
      <el-dialog
        title="历史记录"
        :visible.sync="BankHistoryshow"
        :fullscreen="false"
        :modal="false"
        width="85%"
        hight="80%"
        center>
        <div>
          <el-table
                v-loading="loading"
                :data="BankHistorydata"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                >
                <el-table-column
                  prop="create_time"
                  label="创建时间">
                </el-table-column>
                <el-table-column
                  prop="operator_name"
                  label="操作人员">
                </el-table-column>
                <el-table-column
                  label="操作">
                <template slot-scope="scope">
                  <el-button type="primary" @click="bankhinfo(scope.row.id)">查看</el-button>
                </template>
                </el-table-column>
          </el-table>
          </div>
      </el-dialog>
      <el-dialog
        title="历史记录"
        :visible.sync="bcHistoryshow"
        :fullscreen="false"
        :modal="false"
        width="85%"
        hight="80%"
        center>
        <div>
          <el-table
                v-loading="loading"
                :data="BcHistorydata"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                >
                <el-table-column
                  prop="create_time"
                  label="创建时间">
                  <template slot-scope="scope">
                    {{scope.row.create_time | formatDate}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="operator_name"
                  label="操作人员">
                </el-table-column>
                <el-table-column
                  label="操作">
                <template slot-scope="scope">
                  <el-button type="primary" @click="bchinfo(scope.row.id)">查看</el-button>
                </template>
                </el-table-column>
          </el-table>
          </div>
      </el-dialog>
      <el-dialog
        title="历史记录详情"
        :visible.sync="bcinfoshow"
        :fullscreen="false"
        :modal="false"
        width="75%"
        hight="80%"
        center>
        <div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle'>案件类型:</div>
              <div style="margin-top: 2px;">
                <el-radio v-model="bcinfodata.type" :label="1">行李</el-radio>
                <el-radio v-model="bcinfodata.type" :label="2">非行李</el-radio>
              </div>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class='bcedatatitle'>截止时间:</div>
              <el-date-picker
                v-model="bcinfodata.date"
                type="datetime"
                placeholder="选择截止时间">
              </el-date-picker>
            </div>
            <div class="pt20"></div>
            <div class="remarkds">
              <div class="bcedatatitle">说明:</div>
              <el-input v-model="bcinfodata.remark" placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </div>
            <div  class="pt20"></div>
            <div class="pt20"></div>
            <div>
                <div :style="dom.is_allow == 2 ? 'background-color: whitesmoke;cursor: move;margin-bottom: 2px;':'cursor: move;'" 
                v-for="(dom,index) in bcinfodata.question" :key="index">
                
            <div class="pt20"></div>
            <div class="pt20"></div>
                  <div class="remarkds">
                    <div class='bcedatatitle'>问题名称:</div>
                    <div style="margin-top: 2px;">
                      {{dom.name}}
                    </div>
                  </div>
                  <div  v-for="(reply,index2) in dom.reply" :key="index2">
                    <div v-if='reply.content !== "" && reply.name'>
                      <div class="pt20"></div>
                      <div class="remarkds">
                        <div class='bcedatatitle' style="line-height: 40px;">{{index2 == 0 ? '回复':'回复备注'}}:</div>
                        <div style="margin-top: 2px;line-height: 40px;">
                          {{reply.name != 'images_url' ? reply.content : ''}}
                          <div v-if="reply.name == 'images_url'">
                            <div  v-for="(urlobj,urldex) in reply.urls" :key="urldex">
                              <el-image 
                              style="width: 200px; height: 200px"
                              :src="urlobj.url"
                              fit="contain"></el-image>
                              <div style="margin-top: 2px;line-height: 40px;">
                                <input type="radio"   v-model="urlobj.is_allow" :name="urlobj.url"  value="1"> 通过 
                                <input type="radio"   v-model="urlobj.is_allow" :name="urlobj.url" value="0"> 不通过
                              </div>
                            </div>
                          </div> 
                          <el-button v-if='reply.content == "是" && (dom.item_type == 4 || dom.item_type == 5)' class="btnblack liulan" type="info" @click="liulandom(reply.no,dom.item_type,reply.id)">浏览</el-button>
                          <div v-if='reply.content != "否" && reply.name != "images_url"'>
                            <el-radio :disabled='reply.content == "是" && (dom.item_type == 4 || dom.item_type == 5)' v-model="reply.is_allow" label="1">通过</el-radio>
                            <el-radio :disabled='reply.content == "是" && (dom.item_type == 4 || dom.item_type == 5)' v-model="reply.is_allow" label="0">不通过</el-radio>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pt20"></div>
                  <div class="remarkds">
                    <div class='bcedatatitle'>错误提示:</div>
                    <el-input v-model="dom.remark" placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
                  </div>
                  <div class="pt20"></div>
                </div>
                <div v-for="(dom,index) in bcitem" :key="index + 'zz'" >
                <div>
                    类型：{{dom.item_type | types}} 
                    <el-checkbox class="domcheck" v-model="dom.is_must">必填项</el-checkbox>
                    <el-button class="btnblack" type="info" @click="delbcitem(index)">删除</el-button>
                    <el-button class="btnblack" type="info" @click="domodel(3,index)">使用模板</el-button>
                </div>
                <div class="pt20"></div>
                <div class="remarkds">
                  <div class='bcedatatitle' style="line-height: 40px;">问题名称:</div>
                  <div style="margin-top: 2px;">
                    <el-input v-model="dom.name" placeholder="请输入"></el-input>
                  </div>
                </div>  
                <div class="pt20" v-if="dom.item_type == 1"></div>
                <div class="remarkds" v-if="dom.item_type == 1">
                  <div class='bcedatatitle' style="line-height: 40px;">选项:</div>
                  <div style="margin-top: 2px;">
                    <el-input style="min-width: 400px;" v-model="dom.content" placeholder="多项目换行表示" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
                  </div>
                </div>
                <div class="pt20" v-if="dom.item_type == 3"></div>
                <div class="remarkds" v-if="dom.item_type == 3">
                  <div class='bcedatatitle' style="line-height: 40px;">图片类型:</div>
                  <div style="margin-top: 2px;">
                    <el-select v-model="dom.image_type" placeholder="请选择">
                      <el-option
                        v-for="item in selectimagetype"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                      </el-option>
                      <el-option
                        label="请选择"
                        :value="0">
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="pt20"></div>
              </div>
            </div>
            <div class="pt20"></div>
          </div>
      </el-dialog>
      <el-dialog
        title="历史记录详情"
        :visible.sync="bankhinfoshow"
        :fullscreen="false"
        :modal="false"
        width="75%"
        hight="80%"
        center>
        <div>
          <el-table
                v-loading="loading"
                :data="bankhinfodata.detail"
                style="width: 100%"
                :header-cell-style="{background:'#F5F7FA'}"
                >
                <el-table-column
                  prop="id"
                  label="序号">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="名称">
                </el-table-column>
                <el-table-column
                  prop="content"
                  label="填写信息">
                </el-table-column>
                <el-table-column
                  label="处理意见">
                <template  slot-scope="scope">
                  {{ scope.row.is_allow == 1 ? '通过' : '未通过' }}
                </template>
                </el-table-column>
          </el-table>
          <div class="pt20"></div>
          <div>备注信息</div>
            <div class="pt10"></div>
            <el-input v-model="bankhinfodata.cus_remark" disabled placeholder="" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            <div class="pt20"></div>
        </div>
      </el-dialog>
      <el-dialog
        title="修改信息详情"
        :visible.sync="sendInvitationInfoShow"
        :fullscreen="false"
        :modal="false"
        width="75%"
        hight="80%"
        center>
        <div>
          <div class="pt20"></div>
          <h2>修改项目:</h2>
          <div class="pt10"></div>
          <div style="font-size:18px">
            {{ sendInvitationInfoData.fdata == 1 ? '航班信息' :'' }}
            {{ sendInvitationInfoData.name == 1 ? '委托人姓名' :'' }}
            {{ sendInvitationInfoData.oidnum == 1 ? '证件号码' :'' }}
            {{ sendInvitationInfoData.sign == 1 ? '签名' :'' }}
          </div>
          <div class="pt20"></div>
          <h3>航班页说明:</h3>
          <div class="pt10"></div>
          <div style="font-size:16px">{{ sendInvitationInfoData.fdata_remark }}</div>
          <div class="pt20"></div>
          <h3>信息页说明:</h3>
          <div class="pt10"></div>
          <div style="font-size:16px">{{ sendInvitationInfoData.user_remark }}</div>
          <div class="pt20"></div>
          <h3>签名页说明:</h3>
          <div class="pt10"></div>
          <div style="font-size:16px">{{ sendInvitationInfoData.sign_remark }}</div>
          <div class="pt20"></div>
        </div>
      </el-dialog>
      <el-dialog
        title="查看"
        :visible.sync="ViewTempshow"
        :fullscreen="false"
        :modal="false"
        width="75%"
        hight="80%"
        center>
        <div>
          <div class="pt20"></div>
          <div>问题：{{ViewTempData.name}}</div>
          <div>问题类型：{{ViewTempData.item_type | types}}</div>
          <div>是否必填：{{ViewTempData.is_must ? '是' : '否'}}</div>
          <div v-if="ViewTempData.item_type == 3">图片类型：{{ViewTempData.image_type | imgtype}}</div>
          <el-button type="primary" @click="Usingtemplate()">使用</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="查看模板"
        :visible.sync="eddbankshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form ref="registerdata" :model="registerdata" label-width="120px">
            <el-form-item label="模板名称" prop="enclosure_name">
              <el-input v-model.trim="registerdata.enclosure_name" placeholder="请输入中文名称" maxlength="50" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="案件类型" prop="type">
              <el-select style="width:200px;"  v-model="registerdata.type" placeholder="请选择">
                    <el-option
                      label="行李"
                      :value="1">
                    </el-option><el-option
                      label="非行李"
                      :value="2">
                    </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="说明" prop="remark">
              <el-input v-model.trim="registerdata.remark" placeholder="请输入说明内容" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="存储位置" prop="f_type">
              <el-select style="width:200px;" @change="setstatus33" v-model="registerdata.f_type" placeholder="请选择">
                      <el-option
                        v-for="(ot,i) in options3"
                        :key="'a'+i"
                        :label="ot.name"
                        :value="ot.value">
                      </el-option>
              </el-select>
              <el-select style="width:200px;"  v-model="registerdata.c_type" placeholder="请选择">
                      <el-option
                        v-for="(ot,i) in options5"
                        :key="'a'+i"
                        :label="ot.name"
                        :value="ot.value">
                      </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="c_type"> </el-form-item>
            <el-form-item label="问题类型" prop="enclosure_content">
            <div class="pt20"></div>
            <div v-for="(dom,index) in registerdata.enclosure_content" :key="index" >
              <div>
                  类型：{{dom.title}} 
                  <el-checkbox class="domcheck malr" v-model="dom.is_must">必填项</el-checkbox>
              </div>
              <div class="pt20"></div>
              <div class="remarkds">
                <div class='bcedatatitle' style="line-height: 40px;">问题名称:</div>
                <div style="margin-top: 2px;">
                  <el-input v-model="dom.name" placeholder="请输入"></el-input>
                </div>
              </div>
              <div class="pt20" v-if="dom.item_type == 1"></div>
              <div class="remarkds" v-if="dom.item_type == 1">
                <div class='bcedatatitle' style="line-height: 40px;">选项:</div>
                <div style="margin-top: 2px;">
                  <el-input style="min-width: 400px;" v-model="dom.content" placeholder="多项目换行表示" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
                </div>
              </div>
              <div class="pt20" v-if="dom.item_type == 3"></div>
              <div class="remarkds" v-if="dom.item_type == 3">
                <div class='bcedatatitle' style="line-height: 40px;">图片类型:</div>
                <div style="margin-top: 2px;">
                  <el-select v-model="dom.image_type" placeholder="请选择">
                    <el-option
                      v-for="item in domtype3s"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="pt20"></div>
            </div>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button @click="Usingenclosure(registerdata.id)">使用</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src  
import axios from 'axios';
import config from '../../lib/config';
import globla from '../../globla';
import matDate from '../../lib/dateFormat';
import dragula from 'dragula';

// 全局变量app 
let app = null;
export default {
  name: 'Home',
  components: {
  },
  props:{
    order_id:null
  },
  data(){
    return {
      order_schedule_model_loading:false,
      total:0,
      per_page: 0,
      current_page:1,
      model_page:1,
      modeler_page:0,
      modeltotal:0,
      form: {
        type: '',
        item: [],
        explain: '',
        remark: '',
        order_id: '',
      },
      logform:{
        actionlogtype1:'',
        actionlogtype2:'',
        actionlogtype3:'',
        actionlogtype4:'',
      },
      bcData:[],
      BankHistorydata:[],
      modelshowlist:[],
      BcHistorydata:[{
        time:'2019-01-01 12:00:00',
        name:'张三',
        stuts:1,
        id:1,
      }],
      banklistData:[],
      Ebankadd_item:[],
      add_item_show:'新增发送项',
      complaintInformatinDeletes:[],//投诉信息拆分(删除内容)，示例[1,2,3] id数组
      InformationDataDeletes:[],//理赔息拆分(删除内容)，示例[1,2,3] id数组
      tsItem:[],//投诉函
      actionlist:[],//行为记录列表
      makeUseOfDate:[],//模板列表
      orderelations:[],//编辑主从订单数据
      adddomdata:[],//添加问题类型数据
      fjappenddata:[],//追加问题类型数据
      names:[],
      actionlogtypelist:[],
      actionlogtypelist2:[],
      actionlogtypelist3:[],
      actionlogtypelist4:[],
      otadata:[],
      damagemax:0,
      registerdata:{
        type: '',
        enclosure_name: '',
        f_type: '',
        c_type: '',
        remark: '',
        enclosure_content: [],
      },
      fj_enclosure_real_id:0,
      damagedata:{
        order_no:'',
        op_amount_flg:0,
        op_amount_date:'',
        custom_op_amount:0,
        dn_amount_flg:0,
        dn_amount_sdate:'',
        dn_amount_edate:'',
        custom_dn_amount:0,
        rti_amount_flg:0,
        rti_amount_rely:0,
        custom_rti_amount:0,
        roa_amount_flg:0,
        roa_amount_rely:0,
        custom_roa_amount	:0,
      },
      selectimagetype:[],
      domtype3s:[],
      tsmodel:{
        type:0,
        search_data:'',
        page:1,
      },
      enclosure:{
         page:1,//页码 0不分页
         search_data:'',//检索内容
         f_type:0,//父级分类
         c_type:0,//子级分类
     },
     enclosureloading:false,
     enclosureData:[],
      tsItemDate:{value:'',content:''},
      canableEditCoupon:false,
      hasDamage:false,
      topOrder_no:'',
      certificate_pic:'',
      topState:'',
      details:1,
      Amount_to_be_paid:0,
      key:'1',
      size: '',
      handle:'',
      addmodedata:{
        title:'',
        content:'',
        type:'',
      },
      editmodedata:{
        title:'',
        content:'',
        type:'',
      },
      centerDialogVisible: false,
      Associatediv:false,
      addmodeshow:false,
      editmodeshow:false,
      ModifyInformation:false,
      makeloading:false,
      previewShow:false,
      makeUseOfShow:false,
      ByOrderDataInfoShow:false,
      otadatafristpage:1,
      otadatalastpage:1,
      previewDate:'',
      next_processing_status:'',//下次处理状态
      next_processing_time:'',//下次处理时间
      exchangeByOrderData:[],//用户主动联系客服数据
      InvitationData:[],
      loading:true,
      tableData:[],
      ByOrderDataInfo:{},
      InformationData:{},//理赔详情 
      picdata:[],
      nextreason:[],//下次处理原因
      orderType:[],//订单类型
      oidType:[],//证件类型
      tchannel:[],//投诉渠道 
      cxType:[],//投诉类型(包括123级联动)
      cxTypeOne:[],//1级投诉类型
      cxTypeTwo:[],//2级投诉类型
      cxTypeThree:[],//3级投诉类型
      tmethod:[],//投诉方式
      claimsdata:[],//索赔情况
      causeanalysis:[],//原因分析
      pay_channel:[],//支付渠道
      bankitemlist:[],
      bankhinfodata:[],
      bcitem:[],
      bcinfodata:[{
        id:1,
        name:'张三',
        content:'修改了航班信息',
        is_allow:1,
      }],
      activeName:'',
      InvitationShow:false,
      frist:true,
      eddbankshow:false,
      sendInvitationInfoShow:false,
      sendInvitationInfoData:[],
      sendInvitationPost:{
        fdata:0,
        name:0,
        sign:0,
        oidnum:0,
        fdata_remark:'',
        user_remark:'',
        sign_remark:'',
      },
      domoindex:0,
      domofrom:0,
      addactionlistshow:false,
      modeloading:false,
      addbankshow:false,
      Ebankshow:false,
      shenheshow:false,
      shenheshowdata:{},
      shenhe2show:false,
      shen2heshowdata:{},
      modelshow:false,
      liulanshow:false,
      liulantitle:'',
      questionid:'',
      calculation_order_no:'',
      valueformat:'yyyy-MM-dd HH:mm:ss',
      valueformat1:'yyyy-MM-dd HH:mm:ss',//首次联系 - 打款时间
      valueformat2:'yyyy-MM-dd HH:mm:ss',
      valueformat3:'yyyy-MM-dd HH:mm:ss',
      valueformat4:'yyyy-MM-dd HH:mm:ss',
      valueformat5:'yyyy-MM-dd HH:mm:ss',
      valueformat6:'yyyy-MM-dd HH:mm:ss',
      ssqdata:[],
      options:[],
      options2:[],
      options3:[],
      options4:[],
      options5:[],
      options6:[],
      zxtsdata:[],
      liulandata:[{id:1}],
      modelshowdata:{
         page:1,//页码 0不分页
         search_data:'',//检索内容
         f_type:0,//父级分类
         c_type:0,//子级分类
        },
      selectedNum:0,
      ids: [],
      infoshowdata:[],
      editactionlistshow:false,
      bcshow:false,
      tailinfoshow:false,
      bcaddshow:false,
      usingtemplateshow:false,
      bcAshow:false,
      BankHistoryshow:false,
      bcHistoryshow:false,
      bankhinfoshow:false,
      bcinfoshow:false,
      modeActive:'',
      ViewTempshow:false,
      ViewTempData:{},
      bank_id:'',
      dragOptions: {
        //revertOnSpill: true
        // 在这里设置dragula的选项
        // 例如: revertOnSpill: true
      },
      ComplaintObjectCollection:[],
      AviationAdministrationofChina:[],
      claims_detail_list:[],
      bcedata:{
        question:[{
          reply:[]
        }],
      },
      Ebankdata:{
        bank:{},
        bank_item:{},
        remark:'',
      },
      list_type:2,//1查看 2编辑 默认编辑
      formInline: {
         order_id:'',  
      },
      orderScheduleModel:[],//订单进度 模板列表（处理中 待付款 客服消息）
      modelmessage:[],//消息推送 模板列表（处理中 待付款 已完成 客服消息）
      orderSchedule:{
        type:'1',
        content:'',
        en_content:'',
        secondStatus:'',
        en_secondStatus:'',
      },
      modeSchedule:{
        type:'1',
        content:'',
        title:'',
        en_content:'',
        en_title:'',
      },
      rules:{
          type: [
            { required: true, message: '请选择存储到哪里', trigger: 'change' }
          ],
          content: [
            { required: true, message: '请填写推送内容', trigger: 'blur' }
          ],
          en_content: [
            { required: true, message: '请填写推送内容(英文)', trigger: 'blur' }
          ],
          secondStatus: [
            { required: true, message: '请填写二级状态', trigger: 'blur' }
          ],
          en_secondStatus: [
            { required: true, message: '请填写二级状态(英文)', trigger: 'blur' }
          ],
      },
    }
  },
  beforeCreate() {
			// 将vue实例赋值给全局变量app   
			app = this;
		},
  watch:{
     order_id(a){
      this.formInline.order_id = a;
      this.onSubmit();
     },
  },
  filters:{
    types:function(type){
      let name = '';
      switch(type){
        case 1:
          name = '下拉框';
          break;
        case 2:
          name = '输入框';
          break;
        case 3:
          name = '上传图片';
          break;
        case 4:
          name = '损失清单';
          break;
        case 5:
          name = '自行投诉记录';
          break;
      }
      return name;
    },
    imgtype:function(type){
      let imagetype = app.selectimagetype;
      for(let i in imagetype){
        if(imagetype[i].value == type){
          return imagetype[i].name
        }
      }
      return type;
    },
    oidTypefil:function(value){
      let list = app.oidType;
      for(let i in list){
        if(list[i].value == value){
          return list[i].name
        } 
      }
    },
    formatDate(timestamp) {
      const date = new Date(timestamp*1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);
      const second = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
  },
  methods: {
    timef(type){
      switch(type){
        case 1: this.valueformat1 = 'yyyy-MM-dd HHmmss';break;
        case 2: this.valueformat2 = 'yyyy-MM-dd HHmmss';break;
        case 3: this.valueformat3 = 'yyyy-MM-dd HHmmss';break;
        case 4: this.valueformat4 = 'yyyy-MM-dd HHmmss';break;
        case 5: this.valueformat5 = 'yyyy-MM-dd HHmmss';break;
        case 6: this.valueformat6 = 'yyyy-MM-dd HHmmss';break;
        default: this.valueformat = 'yyyy-MM-dd HHmmss';
      }
    },
    timeb(type){
      switch(type){
        case 1: this.valueformat1 = 'yyyy-MM-dd HH:mm:ss';break;
        case 2: this.valueformat2 = 'yyyy-MM-dd HH:mm:ss';break;
        case 3: this.valueformat3 = 'yyyy-MM-dd HH:mm:ss';break;
        case 4: this.valueformat4 = 'yyyy-MM-dd HH:mm:ss';break;
        case 5: this.valueformat5 = 'yyyy-MM-dd HH:mm:ss';break;
        case 6: this.valueformat6 = 'yyyy-MM-dd HH:mm:ss';break;
        default: this.valueformat = 'yyyy-MM-dd HH:mm:ss';
      }
    },
      childMethod() {
      let frist = sessionStorage.getItem('frist');
      if(frist){
          sessionStorage.setItem("frist", false);
          this.key = '1';
          this.formInline.order_id = sessionStorage.getItem('order_id');
          this.list_type = sessionStorage.getItem('list_type');//编辑 查看权限
          this.onSubmit();
          this.getClaimInformation();//理赔详情
          this.getordertype();//获取订单类型如果能够正常请求，会触发orderinit
        }
      },
      setstatus2(){
        let _this = this;
        axios.get(config.selectquestion_type,{params:{pid:this.modelshowdata.f_type}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options2 = response.data.data;
              _this.modelshowdata.c_type = '';
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      setstatus3(){
        let _this = this;
        axios.get(config.selectquestion_type,{params:{pid:this.enclosure.f_type}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options4 = response.data.data;
              _this.enclosure.c_type = '';
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },  
      setstatus33(){
        let _this = this;
        axios.get(config.selectquestion_type,{params:{pid:_this.registerdata.f_type}})
        .then(function (response) { 
            if(response.data.code == 200){
              _this.options5 = response.data.data;
              _this.registerdata.c_type = '';
            }else{
              _this.$message.error(response.data.message);
            } 
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
    addbankitemshow(type){
      let _this = this;
      if(_this.add_item_show == '新增发送项'){
        let data = {
          search_data : '',
          type : type,
          page:0,
        }
        axios.get(config.bank_item_list,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              _this.bankitemlist = response.data.data;
              _this.Ebankadd_item = [];
              _this.add_item_show = '取消新增';
              
              for(let i in _this.Ebankdata.bank_item){
                _this.Ebankadd_item.push(_this.Ebankdata.bank_item[i].bank_item_id);
                }
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      }else{
        _this.add_item_show = '新增发送项';
        _this.bankitemlist = [];
        _this.Ebankadd_item = [];
      }
    },
    delbcitem(i){
      let _this = this;
      _this.bcitem.splice(i,1);
    },
    delfjappend(i){
      let _this = this;
      _this.fjappenddata.splice(i,1);
    },
    deldom(i){
      let _this = this;
      _this.bcedata.question.splice(i,1);
    },
    domodel(from,index){
      let _this = this;
      _this.modelshow = true;
      _this.modeloading = true;
      _this.domoindex = index;//记录使用模板的下标
      _this.domofrom = from;//记录使用模板的来自 1.fjappenddata(追加里面的使用模板)
      _this.modelshowdata = {
         page:1,//页码 0不分页
         search_data:'',//检索内容
         f_type:0,//父级分类
         c_type:0,//子级分类
        };
      axios.get(config.selectquestion_type,{params:{pid:0}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options = response.data.data
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.fj_question,{params:_this.modelshowdata})
        .then(function (response) {
            if(response.data.code == 200){
              _this.modelshowlist = response.data.data.data;
              _this.setpage(response.data.data);
            }else{
              _this.$message.error(response.data.message);
            }
              _this.modeloading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
    liulandom(no,item_type,questionid){
      let _this = this;
      _this.liulantitle = item_type == 4 ? '损失清单' : '自行投诉记录';
      _this.liulanshow = true;
      _this.questionid = questionid ? questionid : _this.questionid;
      if(item_type == 4){
        axios.get(config.ssqd,{params:{no:no}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.ssqdata = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      }else{
        axios.get(config.zxts,{params:{no:no}})
        .then(function (response) {
          console.log(response.data.data)
            if(response.data.code == 200){
              _this.zxtsdata = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      }
    },
    postaddmode(){
      let _this = this;
      let data = {
        id:_this.editmodedata.id,
        title:_this.editmodedata.title,
        type:_this.editmodedata.type,
        content:_this.editmodedata.content,
        is_default:_this.editmodedata.is_default,
      }
      axios.post(config.ts_edit,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                type: 'success',
                message: '成功!'
              });
              _this.editmodeshow = false;
              _this.makeUseOf();
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    putaddmode(){
      let _this = this;
      axios.post(config.ts_add,_this.addmodedata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                type: 'success',
                message: '成功!'
              });
              _this.addmodeshow = false;
              _this.makeUseOf();
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    addmodelist(){
      let _this = this;
      _this.addmodeshow = true;
      _this.tsItemDate.value = '';
      _this.addmodedata = {
        title:'',
        content:'',
        type:'',
      };
    },
    modolauc(content){
      let _this = this;
      _this.tsItemDate.content = content;
      _this.makeUseOfShow = false;
    },
    modoledit(editmodedata){
      let _this = this;
      _this.editmodedata = editmodedata;
      _this.editmodeshow = true;
    },
    modoldel(id){
      let _this = this;
        axios.delete(config.ts_delete,{data:{id:id}})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.makeUseOf();
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
    },
    addfjappend(type){//type 1下拉框 2输入框 3上传图片 4损失清单 5自行投诉记录
      let _this = this;
      _this.fjappenddata.push({
        item_type:type,
        image_type:0,
        is_must:true,
        name:'',
        content:'',
      });
    },
    bjadd(type){//type 1下拉框 2输入框 3上传图片 4损失清单 5自行投诉记录 
      let _this = this;
      _this.bcitem.push({
        item_type:type,
        image_type:0,
        is_must:true,
        name:'',
        content:'',
      });
    },
    adddom(type){//type 1下拉框 2输入框 3上传图片 4损失清单 5自行投诉记录
      let _this = this;
      _this.bcedata.question.push({
        item_type:type, 
        image_type:0,
        is_must:true,
        name:'',
        content:'',
      });
    },
    liulaned(id){
      let _this = this;
      _this.shenheshow = true;
        axios.get(config.ssqd_info,{params:{id:id}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.shenheshowdata = response.data.data;
              _this.shenheshowdata.arrimages = _this.shenheshowdata.images.split(',');
              _this.shenheshowdata.arrimagesallow = [];
              let arr = _this.shenheshowdata.images_is_allow.split(',');
              for(let i in arr){
                _this.shenheshowdata.arrimagesallow.push({
                  images_is_allow:arr[i]
                })
              }
              
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
    liulaned2(id){  
      let _this = this;
      _this.shenhe2show = true;
        axios.get(config.zxts_info,{params:{id:id}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.shen2heshowdata = response.data.data; 
              _this.shen2heshowdata.arrimages = _this.shen2heshowdata.ts_voucher_image.split(',');
              _this.shen2heshowdata.arrimagesallow = [];
              let arr = _this.shen2heshowdata.ts_voucher_image_is_allow.split(',');
              for(let i in arr){
                _this.shen2heshowdata.arrimagesallow.push({
                  images_is_allow:arr[i]
                })
              }
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
    shen2heshowdatabaocun(){
      let _this = this;
      let shen2heshowdata = _this.shen2heshowdata;
      let images_is_allow = shen2heshowdata.arrimagesallow;
      if(shen2heshowdata.arrimagesallow.length > 0){
        let a = [];
        for(let i in shen2heshowdata.arrimagesallow){
          a.push(shen2heshowdata.arrimagesallow[i].images_is_allow);
        }
        images_is_allow = a.toString();
      }
      let data = {
        id:shen2heshowdata.id,
        no:shen2heshowdata.no,
        ts_date_is_allow:shen2heshowdata.ts_date_is_allow,
        ts_way_is_allow:shen2heshowdata.ts_way_is_allow,
        ts_channel_is_allow:shen2heshowdata.ts_channel_is_allow,
        ts_result_is_allow:shen2heshowdata.ts_result_is_allow,
        ts_has_voucher_is_allow:shen2heshowdata.ts_has_voucher_is_allow,
        ts_voucher_image_is_allow:images_is_allow,
        remark:shen2heshowdata.remark ? shen2heshowdata.remark : ' ',
      };
        axios.post(config.zxts,data)
        .then(function (response) {
            if(response.data.code == 200){
              let q = _this.bcedata.question;
              for(let i in q){
                for(let j in q[i].reply){
                  if(q[i].reply[j].id == _this.questionid){
                    if(response.data.data.question_reply_is_allow == 0){//未通过
                      _this.bcedata.question[i].reply[j].is_allow = '0';
                    }else if(response.data.data.question_reply_is_allow == 1){
                      _this.bcedata.question[i].reply[j].is_allow = '1';
                    }
                  }
                }
              }
              _this.$message({
                type: 'success',
                message: '成功!'
              });
              _this.shenhe2show = false;
              _this.liulandom(shen2heshowdata.no,5);
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleCheckboxChange2(){
      let _this = this;
      if(_this.shen2heshowdata.checked){
        _this.shen2heshowdata.ts_date_is_allow = 1;
        _this.shen2heshowdata.ts_way_is_allow = 1;
        _this.shen2heshowdata.ts_channel_is_allow = 1;
        _this.shen2heshowdata.ts_result_is_allow = 1;
        _this.shen2heshowdata.ts_has_voucher_is_allow = 1;
        _this.shen2heshowdata.ts_voucher_image_is_allow = '1';
        for(let i in _this.shen2heshowdata.arrimagesallow){
          _this.shen2heshowdata.arrimagesallow[i].images_is_allow = '1';
        }
      }else{
        _this.shen2heshowdata.ts_date_is_allow = 0;
        _this.shen2heshowdata.ts_way_is_allow = 0;
        _this.shen2heshowdata.ts_channel_is_allow = 0;
        _this.shen2heshowdata.ts_result_is_allow = 0;
        _this.shen2heshowdata.ts_has_voucher_is_allow = 0;
        _this.shen2heshowdata.ts_voucher_image_is_allow = '0';
        for(let i in _this.shen2heshowdata.arrimagesallow){
          _this.shen2heshowdata.arrimagesallow[i].images_is_allow = '0';
        }
      }
    },
    handleCheckboxChange(){
      let _this = this;
      if(_this.shenheshowdata.checked){
        _this.shenheshowdata.time_is_allow = 1;
        _this.shenheshowdata.name_is_allow = 1;
        _this.shenheshowdata.price_is_allow = 1;
        _this.shenheshowdata.unit_is_allow = 1;
        _this.shenheshowdata.num_is_allow = 1;
        _this.shenheshowdata.has_voucher_is_allow = 1;
        _this.shenheshowdata.images_is_allow = '1';
        for(let i in _this.shenheshowdata.arrimagesallow){
          _this.shenheshowdata.arrimagesallow[i].images_is_allow = '1';
        }
      }else{
        _this.shenheshowdata.time_is_allow = 0;
        _this.shenheshowdata.name_is_allow = 0;
        _this.shenheshowdata.price_is_allow = 0;
        _this.shenheshowdata.unit_is_allow = 0;
        _this.shenheshowdata.num_is_allow = 0;
        _this.shenheshowdata.has_voucher_is_allow = 0;
        _this.shenheshowdata.images_is_allow = '0';
        for(let i in _this.shenheshowdata.arrimagesallow){
          _this.shenheshowdata.arrimagesallow[i].images_is_allow = '0';
        }
      }
    },
    shenheshowdatabaocun(){
      let _this = this;
      let shenheshowdata = _this.shenheshowdata;
      let images_is_allow = shenheshowdata.arrimagesallow;
      if(shenheshowdata.arrimagesallow.length > 0){
        let a = [];
        for(let i in shenheshowdata.arrimagesallow){
          a.push(shenheshowdata.arrimagesallow[i].images_is_allow);
        }
        images_is_allow = a.toString();
      }
      
      let data = {
        id:shenheshowdata.id, 
        no:shenheshowdata.no,
        time_is_allow:shenheshowdata.time_is_allow,
        name_is_allow:shenheshowdata.name_is_allow,
        price_is_allow:shenheshowdata.price_is_allow,
        unit_is_allow:shenheshowdata.unit_is_allow,
        num_is_allow:shenheshowdata.num_is_allow,
        has_voucher_is_allow:shenheshowdata.has_voucher_is_allow,
        images_is_allow:images_is_allow,
        remark:shenheshowdata.remark ? shenheshowdata.remark : ' ',
      };
      
        axios.post(config.ssqd,data)
        .then(function (response) {
            if(response.data.code == 200){
              let q = _this.bcedata.question;
              for(let i in q){
                for(let j in q[i].reply){
                  if(q[i].reply[j].id == _this.questionid){
                    if(response.data.data.question_reply_is_allow == 0){//未通过
                      _this.bcedata.question[i].reply[j].is_allow = '0';
                    }else if(response.data.data.question_reply_is_allow == 1){
                      _this.bcedata.question[i].reply[j].is_allow = '1';
                    }
                  }
                }
              }
              _this.$message({
                type: 'success',
                message: '成功!'
              });
              _this.shenheshow = false;
              _this.liulandom(shenheshowdata.no,4);
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error); 
        });
    },
    bcDetailinfo(){
      let _this = this;
      let replys = [];
      let reply = [];
      let question = [];
      let replyids = [];//存储reply中审核不通过的id
      const itemRefs = this.$refs.divRef;
      let indexs = [];
      itemRefs.sort(function(a, b) {
        var rectA = a.getBoundingClientRect();
        var rectB = b.getBoundingClientRect();
        return rectA.top - rectB.top; // 按照元素在视口中的top位置进行升序排序
      });
       itemRefs.forEach((element) => {
          indexs.push(element.id);
      });
      let bcedataquestion = indexs.map(index => _this.bcedata.question[index]);
       for(let i in bcedataquestion){
        replys = replys.concat(bcedataquestion[i].reply);
      }
      for(let i in replys){
        let is_allow = replys[i].content=='' ? 1 : replys[i].content == '否' ? 1 : replys[i].is_allow;
        reply.push({
          id:replys[i].id,
          is_allow:is_allow,
        })
        if(is_allow == 0){
          replyids.push(replys[i].id);
        }
      }
      for(let i in bcedataquestion){
        let is_allow = bcedataquestion[i].is_allow;
        for(let j in bcedataquestion[i].reply){
          if(replyids.indexOf(bcedataquestion[i].reply[j].id) != -1){
            is_allow = 0
          }
        }
        if(is_allow == 2){
          let bool = true;
          for(let j in bcedataquestion[i].reply){
            if((bcedataquestion[i].reply[j].is_allow != 1 || bcedataquestion[i].reply[j].is_allow != '1') && (bcedataquestion[i].reply[j].content != '' && bcedataquestion[i].reply[j].content != '否')){
              bool = false
            }
          }
          is_allow = bool ? 1 : 2;
        }
        if(is_allow == 0){
          question.push({
            name:bcedataquestion[i].name
          })
        }
      }
        for(let i in _this.bcitem){
          question.push({
            name:_this.bcitem[i].name
          })
        }
      _this.tailinfoshow = true;
      _this.infoshowdata= question;
    },
    ceshi(){
      let _this = this;
      const itemRefs = this.$refs.bdivRef;
      let indexs = [];
      itemRefs.sort(function(a, b) {
        var rectA = a.getBoundingClientRect();
        var rectB = b.getBoundingClientRect();
        return rectA.top - rectB.top; // 按照元素在视口中的top位置进行升序排序
      });
       itemRefs.forEach((element) => {
          indexs.push(element.id);
      });
      let bcedataquestion = indexs.map(index => _this.fjappenddata[index]);
      console.log(bcedataquestion);
    },
    bcDetailEdit(type){
      let _this = this;
      let replys = [];
      let reply = [];
      let question = [];

      let replyids = [];//存储reply中审核不通过的id

    
      const itemRefs = this.$refs.divRef;
      let indexs = [];
      itemRefs.sort(function(a, b) {
        var rectA = a.getBoundingClientRect();
        var rectB = b.getBoundingClientRect();
        return rectA.top - rectB.top; // 按照元素在视口中的top位置进行升序排序
      });
       itemRefs.forEach((element) => {
          indexs.push(element.id);
      });
      let bcedataquestion = indexs.map(index => _this.bcedata.question[index]);
      for(let i in bcedataquestion){
        replys = replys.concat(bcedataquestion[i].reply);
      }
      let showtols_is_allow = false;
      for(let i in replys){
        //选项为空或者为否 都默认通过
        let is_allow = replys[i].content=='' ? 1 : replys[i].content == '否' ? 1 : replys[i].is_allow;
        let img_is_allow = true;
        if(replys[i].name == 'images_url'){
          is_allow  = '';
          for(let j in replys[i].urls){
            if(is_allow == ''){
              is_allow += replys[i].urls[j].is_allow;
            }else{
              is_allow += ',';
              is_allow += replys[i].urls[j].is_allow;
            }
            if(replys[i].urls[j].is_allow === 0 || replys[i].urls[j].is_allow === '0'){
              img_is_allow = false;
            }
            if(replys[i].urls[j].is_allow === 2 || replys[i].urls[j].is_allow === '2'){
              showtols_is_allow = true;
            }
          }
        }
        if(showtols_is_allow){
          _this.$message.error('请选择图片是否通过');
          return;
        }
        reply.push({
          id:replys[i].id,
          is_allow:is_allow,
        })
        if(is_allow == 0 || !img_is_allow){//审核不通过的
          replyids.push(replys[i].id);
        }
      }
      for(let i in bcedataquestion){
        let is_allow = bcedataquestion[i].is_allow;
        for(let j in bcedataquestion[i].reply){//问题审核不通过
          if(replyids.indexOf(bcedataquestion[i].reply[j].id) != -1){
            is_allow = 0
          }
        }
        if(is_allow == 2){
          let bool = true;
          for(let j in bcedataquestion[i].reply){
            if(bcedataquestion[i].reply[j].name == "images_url"){
              for(let k in bcedataquestion[i].reply[j].urls){
                if(bcedataquestion[i].reply[j].urls[k].url == ''){//没有图片默认通过
                  bool = true
                }else {
                  if(bcedataquestion[i].reply[j].urls[k].is_allow !== 1 && bcedataquestion[i].reply[j].urls[k].is_allow !== '1'){
                    bool = false
                  }
                }
              }
            }else{
              if((bcedataquestion[i].reply[j].is_allow != 1 || bcedataquestion[i].reply[j].is_allow != '1') && (bcedataquestion[i].reply[j].content != '' && bcedataquestion[i].reply[j].content != '否')){
                bool = false
              }
            }
          }
          is_allow = bool ? 1 : 2;
        }
        question.push({
          id:bcedataquestion[i].id,
          is_allow:is_allow,
          remark:bcedataquestion[i].remark,
        })
      }

        let showtols = false;
        let item = [];
        for(let i in _this.bcitem){
          item.push({
            content:_this.bcitem[i].content == '' ? '1' : _this.bcitem[i].content,
            is_must:_this.bcitem[i].is_must ? 1 : 0,
            item_type:_this.bcitem[i].item_type,
            image_type:_this.bcitem[i].image_type,
            name:_this.bcitem[i].name
          })
          if(_this.bcitem[i].image_type == 0){
            showtols = true;
          }
        }
        if(showtols){
          _this.$message.error('图片类型必选');
          return;
        }
        let bcedatadate = matDate(_this.bcedata.date,1);
        let data = {
          button_type:type,
          id:_this.bcedata.id,
          order_id:_this.bcedata.order_id,
          type:_this.bcedata.type,
          date:bcedatadate,
          remark:_this.bcedata.remark,
          is_complete:type == 1 ? 0 : _this.bcedata.is_complete,
          question:question,
          reply:reply,
          item:item.length ? item : '',
        }
        axios.post(config.fj,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                type: 'success',
                message: '成功!'
              });
              _this.handleSelect('8');  
              _this.bcshow = false;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    Viewenclosure(id){
      let _this = this;
        let data = {
          id:id
        }
        axios.get(config.enclosure_detail,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              _this.registerdata = response.data.data;
              for(let i in _this.registerdata.enclosure_content){
                _this.registerdata.enclosure_content[i].is_must = _this.registerdata.enclosure_content[i].is_must ? true : false;
              }
              _this.eddbankshow = true;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    Usingenclosure(id){
      let _this = this;
        let data = {
          id:id
        }
        axios.get(config.enclosure_detail,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
               _this.eddbankshow = false;
               _this.usingtemplateshow = false;
               _this.bcaddshow = true;
               _this.setbce(response.data.data);
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    setbce(data){
       let _this = this;
       _this.bcedata = {
        type:data.type,
        remark:data.remark,
        question:data.enclosure_content
      }
    },
    ViewTemplate(id){
      let _this = this;
      _this.ViewTempshow = true;
      axios.get(config.fj_question_detail,{params:{id:id}})
              .then(function (response) {
                    if(response.data.code == 200){
                      _this.ViewTempData = response.data.data[0];
                    }else{
                      _this.$message.error(response.data.message);
                    }
                })
              .catch(function (error) {
                _this.$message.error(error);
              });
    },
    Batchuse(){
      let _this = this;
      let ids = _this.ids.toString();
      axios.get(config.fj_question_detail,{params:{id:ids}})
              .then(function (response) {
                    if(response.data.code == 200){
                      let datas = response.data.data;
                      let arrs = [];
                      for(let i in datas){
                        arrs.unshift({
                          item_type:datas[i].item_type,
                          image_type:datas[i].image_type,
                          is_must:datas[i].is_must ? true :false,
                          name:datas[i].name,
                          content:datas[i].content,
                        });
                      }
                      let i = _this.domoindex;
                      if(_this.domofrom == 1){
                        _this.fjappenddata.splice(i,1,...arrs);
                      }
                      if(_this.domofrom == 2){
                        _this.bcedata.question.splice(i,1,...arrs);
                      }
                      if(_this.domofrom == 3){
                        _this.bcitem.splice(i,1,...arrs);
                      }
                      _this.ViewTempshow = false;
                      _this.modelshow = false;
                    }else{
                      _this.$message.error(response.data.message);
                    }
                })
              .catch(function (error) {
                _this.$message.error(error);
              });
    },
    setarrimagesallow(i,num){
      this.shenheshowdata.arrimagesallow[i].images_is_allow = num;
    },
    setarrimagesallow2(i,num){
      this.shen2heshowdata.arrimagesallow[i].images_is_allow = num;
    },
    Usingtemplates(id){
      let _this = this;
      axios.get(config.fj_question_detail,{params:{id:id}})
              .then(function (response) {
                    if(response.data.code == 200){
                      _this.ViewTempData = response.data.data[0];
                      _this.Usingtemplate();
                    }else{
                      _this.$message.error(response.data.message);
                    }
                })
              .catch(function (error) {
                _this.$message.error(error);
              });
    },
    Usingtemplate(){
      let _this = this;
      _this.ViewTempshow = false;
      _this.modelshow = false;
      let i = _this.domoindex;
      if(_this.domofrom == 1){//1.fjappenddata(追加里面的使用模板)
        _this.fjappenddata[i] = {
          item_type:_this.ViewTempData.item_type,
          image_type:_this.ViewTempData.image_type,
          is_must:_this.ViewTempData.is_must ? true :false,
          name:_this.ViewTempData.name,
          content:_this.ViewTempData.content,
        }
      }
      if(_this.domofrom == 2){//2.bcedata.question(新增里面的使用模板)
        _this.bcedata.question[i] = {
          item_type:_this.ViewTempData.item_type,
          image_type:_this.ViewTempData.image_type,
          is_must:_this.ViewTempData.is_must ? true :false,
          name:_this.ViewTempData.name,
          content:_this.ViewTempData.content,
        }
      }
      if(_this.domofrom == 3){//3.bcitem(编辑里面的使用模板)
        _this.bcitem[i] = {
          item_type:_this.ViewTempData.item_type,
          image_type:_this.ViewTempData.image_type,
          is_must:_this.ViewTempData.is_must ? true :false,
          name:_this.ViewTempData.name,
          content:_this.ViewTempData.content,
        }
      }
    },
    addDetailEdit(){
        let _this = this;
        let date = matDate(_this.bcedata.date,1);
        let item = [];
        let showtols = false;
        for(let i in _this.bcedata.question){
          item.push({
            item_type:_this.bcedata.question[i].item_type ? _this.bcedata.question[i].item_type : 0,
            image_type:_this.bcedata.question[i].image_type ? _this.bcedata.question[i].image_type : 0,
            is_must:_this.bcedata.question[i].is_must ? _this.bcedata.question[i].is_must : 0,
            name:_this.bcedata.question[i].name ? _this.bcedata.question[i].name : '1',
            content:_this.bcedata.question[i].content ? _this.bcedata.question[i].content : '1',
          });
          if(_this.bcedata.question[i].image_type == 0){
            showtols = true;
          }
        }
        if(showtols){
          _this.$message.error('图片类型必选');
          return;
        }
        let data = {
          order_id:_this.formInline.order_id,
          type:_this.bcedata.type,
          date:date,
          remark:_this.bcedata.remark,
          item:item,
        }
        axios.put(config.fj,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                  message: response.data.message,
                  type: 'success'
                });
              _this.bcaddshow = false;
              axios.get(config.fj,{params:{order_id:_this.formInline.order_id}})
              .then(function (response) {
                    if(response.data.code == 200){
                        _this.bcData = [];
                      if(response.data.data){
                        response.data.data.create_time = matDate(response.data.data.create_time*1000);
                        _this.bcData.push(response.data.data);
                      }
                    }else{
                      _this.$message.error(response.data.message);
                    }
                    _this.loading = false;
                })
              .catch(function (error) {
                _this.$message.error(error);
              });
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
    bankDetailEdit(type){
      let _this = this;
      let item = [];
      for(let i in _this.Ebankdata.bank_item){
        item.push({
          id:_this.Ebankdata.bank_item[i].id,
          is_allow:_this.Ebankdata.bank_item[i].is_allow,
        });
      }
      let add_item = '';
      if(_this.Ebankadd_item.length != 0){
        add_item = JSON.stringify(_this.Ebankadd_item);
      }
        let data = {
          is_complete:_this.Ebankdata.bank.is_complete === '' ? -1 : _this.Ebankdata.bank.is_complete,
          type:type,
          item:item,
          bank_order_id:_this.bank_id,
          add_item:add_item,
          remark:_this.Ebankdata.remark?_this.Ebankdata.remark:' ',
          explain:'',
        }
        axios.post(config.bank_detail_edit,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                type: 'success',
                message: '成功!'
              }); 
              _this.handleSelect('8');
              _this.Ebankshow = false;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    BankDataE(id){
        let _this = this;
        _this.bank_id = id;
        let data = {
          bank_id : id
        };
        axios.get(config.bank_detail,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              _this.Ebankdata = response.data.data;
              _this.bankitemlist = [];
              _this.Ebankshow = true;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
    putfjappend(){
      let _this = this;
      let item = [];
      const itemRefs = this.$refs.bdivRef;
      let indexs = [];
      let showtols = false;
      itemRefs.sort(function(a, b) {
        var rectA = a.getBoundingClientRect();
        var rectB = b.getBoundingClientRect();
        return rectA.top - rectB.top; // 按照元素在视口中的top位置进行升序排序
      });
       itemRefs.forEach((element) => {
          indexs.push(element.id);
      });
      let fjappenddatas = indexs.map(index => _this.fjappenddata[index]);
        for(let i in fjappenddatas){
          item.push({
            content:fjappenddatas[i].content == '' ? '1' : fjappenddatas[i].content,
            is_must:fjappenddatas[i].is_must ? 1 : 0,
            item_type:fjappenddatas[i].item_type,
            image_type:fjappenddatas[i].image_type,
            name:fjappenddatas[i].name
          })
          if(fjappenddatas[i].image_type == 0){
            showtols = true;
          }
        }
        if(showtols){
          _this.$message.error('图片类型必选');
          return;
        }
        let data = {
          fj_enclosure_real_id:_this.fj_enclosure_real_id,
          order_id:_this.formInline.order_id,
          item:item
        }
        axios.put(config.fj_append,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.bcAshow = false;
              _this.$message({
                  message: response.data.message,
                  type: 'success'
                });
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
    bcDetaAdd(){
      let _this = this;
      _this.bcAshow = false;
    },
    bcDataA(id){
      let _this = this;
      let clear = setInterval(()=>{
          let bcontainer = _this.$refs.bcontainer;
          if(bcontainer){
            clearInterval(clear);
            const drake = dragula([bcontainer]);
            drake.on('drop', (el, target, source, sibling) => {
              console.log(el,target,source,sibling);
            });
          }
        },100);
      _this.fj_enclosure_real_id = id;
      _this.bcAshow = true;
      _this.fjappenddata = [];
    },
    bcDataE(id){
      let _this = this;
      _this.bcshow = true;
      _this.bcitem = [];
       let clear = setInterval(()=>{
          let container = _this.$refs.container;
          if(container){
            clearInterval(clear);
            const drake = dragula([container]);
            drake.on('drop', (el, target, source, sibling) => {
              console.log(el.id,target.id,source.id,sibling);
            });
          }
        },100);
      axios.get(config.fj_info,{params:{id:id}})
        .then(function (response) {
              if(response.data.code == 200){
                  _this.bcedata = response.data.data;
                  for(let i in _this.bcedata.question){
                    for(let j in _this.bcedata.question[i].reply){
                      if(_this.bcedata.question[i].reply[j].name == "images_url"){
                       let urls = _this.bcedata.question[i].reply[j].content.split(',');
                       let isws = _this.bcedata.question[i].reply[j].is_allow.split(',');
                       _this.bcedata.question[i].reply[j].urls = [];
                       for(let k in urls){
                        _this.bcedata.question[i].reply[j].urls.push({
                          url:urls[k],
                          is_allow:isws[k]
                        })
                       }
                      }
                    }
                  }
              }else{
                _this.$message.error(response.data.message);
              }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
    Createusingtemplates(){
      let _this = this;
      _this.bcedata = {
        question:[]
      }
      _this.usingtemplateshow = true;
      axios.get(config.selectquestion_type,{params:{pid:0}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options3 = response.data.data
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        _this.getustemp();
    },
    getustemp(cha){
      let _this = this;
      if(cha=='cha'){
        _this.enclosure.page = 1;
      }
        _this.enclosureloading = true;
        axios.get(config.enclosure,{params:_this.enclosure})
        .then(function (response) {
            if(response.data.code == 200){
              _this.enclosureData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.enclosureloading = false;
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
     addbcdata(){
      this.bcedata = {
        question:[]
      }
      this.bcaddshow = true;
     },
    bcHistory(id){
      let _this = this;
        axios.get(config.fj_history,{params:{enclosure_id:id}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.BcHistorydata = response.data.data;
              _this.bcHistoryshow = true;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
    editaclog(data){
      let _this = this;
      _this.editactionlistshow = true;
      if(data.action){
        _this.actionlogtypeson(data.action,1,true)
      }
      if(data.specific_situation){
        _this.actionlogtypeson(data.specific_situation,2,true)
      }
      if(data.specific_doubts){
        _this.actionlogtypeson(data.specific_doubts,3,true)
      }
        _this.logform = {
          id:data.id,
          actionlogtype1:data.action ? data.action*1 : '',
          actionlogtype2:data.specific_situation*1 ? data.specific_situation*1 : '',
          actionlogtype3:data.specific_doubts*1 ? data.specific_doubts*1 : '',
          actionlogtype4:data.specific_materials ? data.specific_materials.split(',') : '',
          remark:data.remark,
        };
        if(_this.logform.actionlogtype4){
          for(let i in _this.logform.actionlogtype4){
            _this.logform.actionlogtype4[i] = _this.logform.actionlogtype4[i] ? _this.logform.actionlogtype4[i] * 1 : _this.logform.actionlogtype4[i];
          }
        }
        console.log(_this.logform)
    },
    delaclog(id){
      let _this = this;
        axios.delete(config.action_log,{data:{id:id}})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.getaction_list();
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
    },
    BankHistory(){
        let _this = this;
        let data = {
          order_id : _this.formInline.order_id
        };
        axios.get(config.bank_history,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              _this.BankHistorydata = response.data.data;
              for(let i in _this.BankHistorydata){
                _this.BankHistorydata[i].create_time = matDate(_this.BankHistorydata[i].create_time*1000);
              }
              _this.BankHistoryshow = true;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
    bchinfo(id){
      let _this = this;
      axios.get(config.fj_history_info,{params:{id:id}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.bcinfodata = response.data.data.content;
              for(let i in _this.bcinfodata.question){
                    for(let j in _this.bcinfodata.question[i].reply){
                      if(_this.bcinfodata.question[i].reply[j].name == "images_url"){
                      let urls = _this.bcinfodata.question[i].reply[j].content.split(',');
                       let isws = _this.bcinfodata.question[i].reply[j].is_allow.split(',');
                       _this.bcinfodata.question[i].reply[j].urls = [];
                       for(let k in urls){
                        _this.bcinfodata.question[i].reply[j].urls.push({
                          url:urls[k],
                          is_allow:isws[k]
                        })
                       }
                      }
                    }
                  }
              _this.bcinfoshow = true;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
    bankhinfo(history_id){
        let _this = this;
        let data = {
          history_id : history_id
        };
        axios.get(config.bank_h_info,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              _this.bankhinfodata = response.data.data;
              _this.bankhinfodata.detail = JSON.parse(_this.bankhinfodata.detail);
              _this.bankhinfoshow = true;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
    getitems(){
        let _this = this;
        let data = {
          search_data : '',
          type : _this.form.type,
          page:0,
        }
        axios.get(config.bank_item_list,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              _this.bankitemlist = response.data.data;
              _this.form.item = [];
              for(let i in _this.bankitemlist){
                _this.form.item.push(_this.bankitemlist[i].id);
              }
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
     xinchuangjian(){
        this.addbankshow = true;
     },
     action_list_add(){
      this.addactionlistshow = true;
      this.logform = {
        actionlogtype1:'',
        actionlogtype2:'',
        actionlogtype3:'',
        actionlogtype4:'',
      };
     },
      InfoExchange(){
        let _this = this;
        if(_this.ByOrderDataInfo.is_call === ''){
          _this.$message.error('请选择是否打通！');
          return;
        }
        let name = [];
        for(let i in _this.names){
          name.push({
            name:_this.names[i]
          })
        }
        let data = {
          id:_this.ByOrderDataInfo.id,
          image:name,
          message:_this.ByOrderDataInfo.message?_this.ByOrderDataInfo.message:' ',
          is_call:_this.ByOrderDataInfo.is_call,
        }
        axios.post(config.exchange,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                type: 'success',
                message: '保存成功!'
              });
              _this.exchange_by_order();
              _this.ByOrderDataInfoShow = false;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      ByOrderDataE(id){
        let _this = this;
        _this.ByOrderDataInfoShow = true;
        _this.names = [];
         axios.get(config.exchange_detail,{params:{id:id}})//用户联系详情  
        .then(function (response) {
            if(response.data.code == 200){
              _this.ByOrderDataInfo = response.data.data;
              if(_this.ByOrderDataInfo.images != ''){
                _this.ByOrderDataInfo.imgs = _this.ByOrderDataInfo.images.split(',');
              }
              if(_this.ByOrderDataInfo.name != ''){
                _this.names = _this.ByOrderDataInfo.name.split(',');
              }
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      previewDateCopy(){
        let _this = this;
        const input = document.createElement('input');
        input.style='dispaly:none;';
        document.body.appendChild(input);
        input.setAttribute('value', _this.previewDate);
        input.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
            _this.previewShow = false;
            _this.$message({
              type: 'success',
              message: '复制成功!'
            });
        }
        document.body.removeChild(input);
      },
      resetForm() {
        this.$refs.form.resetFields();
      },
      resetlogform() {
        this.$refs.logform.resetFields();
      },
      insert(){
        let _this = this;
        _this.tsItemDate.content = _this.tsItemDate.content + _this.tsItemDate.value;
      },
      inmodel(){
        let _this = this;
        _this.addmodedata.content = _this.addmodedata.content + _this.tsItemDate.value;
      },
      inemodel(){
        let _this = this;
        _this.editmodedata.content = _this.editmodedata.content + _this.tsItemDate.value;
      },
      preview(){
        let _this = this;
        if(_this.tsItemDate.content == ''){
          _this.$message.error('请填写预览内容！');
          return;
        }
        let data = {
          order_id:_this.formInline.order_id,
          content:_this.tsItemDate.content,
        }
        axios.post(config.preview,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.previewShow = true;
              _this.previewDate = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      makeUseOf(from){
        let _this = this;
        _this.makeUseOfShow = true;
        if(from == 'CHA'){
          _this.tsmodel.page = 1;
        }
        _this.makeloading = true;
         axios.get(config.ts_model_list,{params:_this.tsmodel})//根据code获取航空公司信息
        .then(function (response) {
            if(response.data.code == 200){
              _this.makeUseOfDate = response.data.data.data;
              _this.setmodelpage(response.data.data);
            }else{
              _this.$message.error(response.data.message);
            }
            _this.makeloading = false;
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      increase(){
        let _this = this;
        if(_this.tsItemDate.content == ''){
          _this.$message.error('请填写内容！');
          return;
        }
        let data = {
          order_id:_this.formInline.order_id,
          content:_this.tsItemDate.content,
        }
        axios.post(config.ts_logs,data)
        .then(function (response) {
            if(response.data.code == 200){
               _this.$message({
                message: response.data.message,
                type: 'success'
              });
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      setdiscount(){//设置折扣比例
        let _this = this;
        for(let i in _this.coupons){
          if(_this.coupons[i].id == _this.InformationData.order.cus_coupon_id){
            _this.InformationData.order.discount = _this.coupons[i].discount;
            _this.InformationData.order.coupon_name = _this.coupons[i].coupon_name;
          }
        }
        _this.calculation();
      },
      calculation(){ 
        // 原服务费金额=（获赔金额-不计赔款金额）*服务费比例
        // 应支付服务费 = 原服务费金额*（100-折扣比例）/100-优惠金额+违约金
        // 需打款金额 = 获赔金额-应支付服务费
        // 支付渠道为 公账入款时显示需打款金额
        let _this = this;
    //    _this.InformationData.order.amount_paid = _this.InformationData.order.amount_paid * 1;
        // _this.InformationData.order.payable = ((_this.InformationData.order.amount_paid - _this.InformationData.order.ex_price)*(_this.InformationData.order.service_charges/100)).toFixed(2);
        // _this.InformationData.order.payment = (_this.InformationData.order.payable*((100-_this.InformationData.order.discount)/100) - _this.InformationData.deduction_roll.price).toFixed(2);
        // _this.Amount_to_be_paid = (_this.InformationData.order.amount_paid - _this.InformationData.order.payment).toFixed(2);
       let data = {
        amount_paid:_this.InformationData.order.amount_paid,
        ex_price:_this.InformationData.order.ex_price,
        service_charges:_this.InformationData.order.service_charges,
        user_coupon:_this.InformationData.order.cus_coupon_id ? _this.InformationData.order.cus_coupon_id : 0,
        order_no:_this.calculation_order_no,
       }
        axios.get(config.calculated,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              if(response.data.data){
                _this.InformationData.order.payable = response.data.data.payable;
                _this.InformationData.order.payment = response.data.data.payment;
                _this.Amount_to_be_paid = response.data.data.need_pay_price;
              }
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      InformationDataCalculation(index){ 
        // 原服务费金额=（获赔金额-不计赔款金额）*服务费比例
        // 应支付服务费 = 原服务费金额*（100-折扣比例）/100-优惠金额+违约金
        // 需打款金额 = 获赔金额-应支付服务费
        // 支付渠道为 公账入款时显示需打款金额
        let _this = this;
        // _this.InformationData.claim_information[index].payable = (_this.InformationData.claim_information[index].amount_paid - _this.InformationData.claim_information[index].ex_price)*(_this.InformationData.claim_information[index].service_charges/100);
        // _this.InformationData.claim_information[index].payment = _this.InformationData.claim_information[index].payable*((100-_this.InformationData.claim_information[index].discount)/100) - _this.InformationData.deduction_roll.price;
        // _this.InformationData.claim_information[index].Amount_to_be_paid = _this.InformationData.claim_information[index].amount_paid - _this.InformationData.claim_information[index].payment;
        let data = {
        amount_paid:_this.InformationData.claim_information[index].amount_paid,
        ex_price:_this.InformationData.claim_information[index].ex_price,
        service_charges:_this.InformationData.claim_information[index].service_charges,
        user_coupon:_this.InformationData.order.cus_coupon_id ? _this.InformationData.order.cus_coupon_id : 0,
        order_no:_this.topOrder_no,
       }
        axios.get(config.calculated,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              if(response.data.data){
                _this.InformationData.claim_information[index].payable = response.data.data.payable;
                _this.InformationData.claim_information[index].payment = response.data.data.payment;
                _this.InformationData.claim_information[index].Amount_to_be_paid = response.data.data.need_pay_price;
              }
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      synchronization(){
        let _this = this;
        setTimeout(function(){
            _this.InformationData.claim_information[0].application_amount = _this.InformationData.order.application_amount;
            _this.InformationData.claim_information[0].amount_paid = _this.InformationData.order.amount_paid;
            _this.InformationData.claim_information[0].ex_price = _this.InformationData.order.ex_price;
            _this.InformationData.claim_information[0].service_charges = _this.InformationData.order.service_charges;
            _this.InformationData.claim_information[0].discount = _this.InformationData.order.discount;
            _this.InformationData.claim_information[0].price = _this.InformationData.order.price;
            _this.InformationData.claim_information[0].payable = _this.InformationData.order.payable;
            _this.InformationData.claim_information[0].payment_channel = _this.InformationData.order.payment_channel;
            _this.InformationData.claim_information[0].Amount_to_be_paid = _this.Amount_to_be_paid;
            _this.InformationData.claim_information[0].payment = _this.InformationData.order.payment;
            _this.InformationData.claim_information[0].coupon_name = _this.InformationData.order.coupon_name;
          },500)
      },
      getAircompanyInfo(index){
        let _this = this;
        let code = _this.tableData.complaint_information[index].flight_number.substring(0,2);
        axios.get(config.aircompany_info,{params:{code:code}})//根据code获取航空公司信息
        .then(function (response) {
            if(response.data.code == 200){
              if(response.data.data){
                _this.tableData.complaint_information[index].flight_code = code.toUpperCase();
                _this.tableData.complaint_information[index].airline_company = response.data.data.air_company;
                _this.tableData.complaint_information[index].ascription = response.data.data.atype_text;
                _this.tableData.complaint_information.push({});
                _this.tableData.complaint_information.pop();
              }
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      AddComplaintInformatin(){
        let _this = this;
        let complaint_information_no = 'CI' + new Date().getTime(); 
        _this.tableData.complaint_information.push({
          complaint_information_no:complaint_information_no,
          is_main:1,
          accident_number: "",
          area_code: "",
          birth: "",
          channel: "",
          created_at: "",
          customer_service_operation_no: _this.tableData.customer_data.customer_service_operation_no,
          email: "",
          etkt_num: "",
          flight_number:'',
          flight_code:'',
          airline_company:'',
          ascription:'',
          departure_airport:'',
          airport_destination:'',
          complaint_type:-1,
          delay_time:0,
          custom_1:'',
          custom_2:'',
          custom_3:'',
          id: 0,
          is_complex:"" ,
          name: "",
          oidnum: "",
          oidtype: "",
          order_no: "",
          phone: "",
          region: "",
          remark: "",
          sex: "",
          updated_at: "",
          complaint_type_level_one:-1,
          complaint_type_level_two:-1,
          complaint_type_level_three:-1,
          complaint_type_level_two_custom:'',
          complaint_type_level_three_custom:'',
        });
        
      },
      AddInformation(){
        let _this = this;
        _this.InformationData.claim_information.push({
          amount_paid: "",
          application_amount: "",
          claim_deduction_roll: "0",
          coupon_name: _this.InformationData.order.coupon_name,
          customer_service_operation_no: _this.tableData.customer_data.customer_service_operation_no,
          discount: "0.00",
          estimated_payment_time: "",
          complaint_information_no:"",
          ex_price: 0,
          id: 0,
          number_of_claims: "",
          order_no: "",
          payable: "",
          payment: "",
          payment_channel: -1,
          service_charges: "",
        });
      },
      feelock(){
        let _this = this;
        axios.post(config.fee_lock,{order_no:_this.topOrder_no})
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      DelInformation(index){
        let _this = this;
        _this.$confirm('此操作将删除该投诉信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if(_this.InformationData.claim_information[index].id !== ''){
            _this.InformationDataDeletes.push(_this.InformationData.claim_information[index].id);
          }
          _this.InformationData.claim_information.splice(index,1)
          _this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          _this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
      DelComplaintInformatin(index){
        let _this = this;
        _this.$confirm('此操作将删除该投诉信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if(_this.tableData.complaint_information[index].id !== ''){
            _this.complaintInformatinDeletes.push(_this.tableData.complaint_information[index].id);
          }
          _this.tableData.complaint_information.splice(index,1)
          _this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          _this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
      openmsgbox(type){
      let _this = this;
      let details_order = _this.InformationData.order;//理赔表单数据（填）
      let od = '';//订单状态
      if(type == 2){//提交
        if(details_order.payable == 0){
          od = '处理中'
        }else{
          od = '待付款'
        }
      }else if(type == 1){//结单
        if(details_order.payable == 0){
          od = '投诉完成'
        }else{
          od = '索赔成功'
        }
      }else if(type == 4){//315
        if(details_order.payable == 0){
          if(details_order.payment_channel == 1 || details_order.payment_channel == 2){
            od = '待付款'
          }else{
            od = '索赔成功'
          }
        }
      }
      this.$confirm(`<div>获赔金额（元）：${details_order.amount_paid}</div>
      <div>服务费比例（%）：${details_order.service_charges}</div>
      <div>折扣（%）：${details_order.discount}</div>
      <div>应支付服务费（元）：${details_order.payable}</div>
      <div>实支付服务费（元）：${details_order.payment}</div>
      <div>预计打款时间：${matDate(details_order.estimated_payment_time)}</div>
      <div>下一步订单状态：${od}</div>`, '请确认以下信息', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          center: true
        }).then(() => {
          _this.postorder(type);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消提交'
          });
        });
      },
      postorder(submit){//1结单2提交3保存
        let _this = this;
        let tableData = _this.tableData;//order数据
        let details_order = _this.InformationData.order;//理赔表单数据（填）
        // if(!_this.next_processing_status || !_this.next_processing_time){
        //   _this.$message.error('下次处理状态和下次处理时间是必选项');
        //   return
        // }
        let order = {
          order_id:_this.formInline.order_id,//订单id   
          order_no:tableData.customer_data.order_no,//订单号
          order_group:tableData.order.order_group,//订单类型(值)
          next_processing_status:_this.next_processing_status,//下次处理状态
          next_processing_time:matDate(_this.next_processing_time),//下次处理时间
          application_amount:details_order.application_amount ? details_order.application_amount : 0,//申请金额 
          amount_paid:details_order.amount_paid ? details_order.amount_paid : 0,//获赔金额 
          ex_price:details_order.ex_price ? details_order.ex_price : 0,//不计赔款金额
          service_charges:details_order.service_charges ? details_order.service_charges : 0,//服务费比例
          payable:details_order.payable ? details_order.payable : 0,//服务费金额
          payment_channel:details_order.payment_channel,//支付渠道
          payment:details_order.payment ? details_order.payment : 0,//应付金额
          account_holder:details_order.account_holder,//开户人
          bank:details_order.bank,//银行名称
          bank_account:details_order.bank_account,//银行账号
          opening_bank:details_order.opening_bank,//开户行
          bank_address:details_order.bank_address,//银行地址
          cnaps:details_order.cnaps,//cnaps联行号 
          swift_code:details_order.swift_code,//swift_code
          estimated_payment_time:matDate(details_order.estimated_payment_time),//预计打款时间
          service_address:details_order.service_address,//送达地址 
          cus_coupon_id:details_order.cus_coupon_id ? details_order.cus_coupon_id : 0,//后台修改用户优惠卷
        };
        let customer_data = {
          id:tableData.customer_data.id,//客服处理信息id
          name:tableData.customer_data.name,//证件名
          sex:tableData.customer_data.sex ? tableData.customer_data.sex : 0,//性别
          birth:matDate(tableData.customer_data.birth),//出生年月
          channel:tableData.customer_data.channel,//渠道
          remark:tableData.customer_data.remark,//渠道备注 channel为4时必填
          area_code:tableData.customer_data.area_code,//手机区号
          phone:tableData.customer_data.phone,//手机号
          oidtype:tableData.customer_data.oidtype,//证件类型 0 护照 1户口本 2台胞证 3港澳通行证 4大陆居民往来台湾通行证 5其他 6身份证
          email:tableData.customer_data.email,//邮箱
          oidnum:tableData.customer_data.oidnum,//证件号码
          region:tableData.customer_data.region,//地区
          is_complex:tableData.customer_data.is_complex,//有无通行人 1有 2没有
          etkt_num:tableData.customer_data.etkt_num,//etkt号码
          accident_number:tableData.customer_data.accident_number,//事故单编号
          order_no:tableData.customer_data.order_no,//订单号
          customer_service_operation_no:tableData.customer_data.customer_service_operation_no,//客服处理单号
        };
        for(let i in tableData.complaint_information){//tableData.complaint_information == customerData
          tableData.complaint_information[i].first_contact_time = matDate(tableData.complaint_information[i].first_contact_time,1);
          tableData.complaint_information[i].all_data_collected_time = matDate(tableData.complaint_information[i].all_data_collected_time,1);
          tableData.complaint_information[i].time_of_complaint_initiation = matDate(tableData.complaint_information[i].time_of_complaint_initiation,1);
          tableData.complaint_information[i].first_response_to_complaint_time = matDate(tableData.complaint_information[i].first_response_to_complaint_time,1);
          tableData.complaint_information[i].confirmation_compensation = matDate(tableData.complaint_information[i].confirmation_compensation,1);
          tableData.complaint_information[i].payment_time_airlines = matDate(tableData.complaint_information[i].payment_time_airlines,1);
          tableData.complaint_information[i].departure_date = matDate(tableData.complaint_information[i].departure_date);
         

          // if(!tableData.complaint_information[i].claims_detail && tableData.complaint_information[i].claims_detail !== 0){
          //   _this.$message.error('请选择赔偿方案');
          //   return;
          // }
          if(tableData.complaint_information[i].claims_detail !== 0){
            tableData.complaint_information[i].claims_detail = tableData.complaint_information[i].claims_detail ? tableData.complaint_information[i].claims_detail :  -1;
          }

          if(tableData.complaint_information[i].claims !== 0){
            tableData.complaint_information[i].claims = tableData.complaint_information[i].claims ? tableData.complaint_information[i].claims :  -1;
          }

          if(tableData.complaint_information[i].complaint_target !== 0){
            tableData.complaint_information[i].complaint_target = tableData.complaint_information[i].complaint_target ? tableData.complaint_information[i].complaint_target :  -1;
          }
          
          if(tableData.complaint_information[i].civil_aviation !== 0){
            tableData.complaint_information[i].civil_aviation = tableData.complaint_information[i].civil_aviation ? tableData.complaint_information[i].civil_aviation :  -1;
          }
          if(tableData.complaint_information[i].complaint_mode !== 0){
            tableData.complaint_information[i].complaint_mode = tableData.complaint_information[i].complaint_mode ? tableData.complaint_information[i].complaint_mode :  -1;
          }
          if(tableData.complaint_information[i].complaint_type_level_one !== 0){
            tableData.complaint_information[i].complaint_type_level_one = tableData.complaint_information[i].complaint_type_level_one ? tableData.complaint_information[i].complaint_type_level_one :  -1;
          }
          if(tableData.complaint_information[i].complaint_type_level_two !== 0){
            tableData.complaint_information[i].complaint_type_level_two = tableData.complaint_information[i].complaint_type_level_two ? tableData.complaint_information[i].complaint_type_level_two :  -1;
          }
          if(tableData.complaint_information[i].complaint_type_level_three !== 0){
            tableData.complaint_information[i].complaint_type_level_three = tableData.complaint_information[i].complaint_type_level_three ? tableData.complaint_information[i].complaint_type_level_three :  -1;
          }
          if(tableData.complaint_information[i].cause_analysis !== 0){
             tableData.complaint_information[i].cause_analysis = tableData.complaint_information[i].cause_analysis ? tableData.complaint_information[i].cause_analysis :  -1;
          }
          if(tableData.complaint_information[i].flight_information !== 0){
             tableData.complaint_information[i].flight_information = tableData.complaint_information[i].flight_information ? tableData.complaint_information[i].flight_information :  -1;
          }
          tableData.complaint_information[i].created_at = tableData.complaint_information[i].created_at ? tableData.complaint_information[i].created_at :  '';
          tableData.complaint_information[i].updated_at = tableData.complaint_information[i].updated_at ? tableData.complaint_information[i].updated_at :  '';
          tableData.complaint_information[i].final_compensation = tableData.complaint_information[i].final_compensation ? tableData.complaint_information[i].final_compensation.toString() :  '';
         }
        let complaint_information = {
          origin:tableData.complaint_information,//投诉信息拆分（发生变更内容，编辑和新增） 详情中所有数据传回
          delete:_this.complaintInformatinDeletes,//投诉信息拆分(删除内容)，示例[1,2,3] id数组
        }
        for(let i in _this.InformationData.claim_information){
          _this.InformationData.claim_information[i].estimated_payment_time = matDate(_this.InformationData.claim_information[i].estimated_payment_time);
          _this.InformationData.claim_information[i].application_amount = _this.InformationData.claim_information[i].application_amount ? _this.InformationData.claim_information[i].application_amount : 0 ;
          _this.InformationData.claim_information[i].amount_paid = _this.InformationData.claim_information[i].amount_paid ? _this.InformationData.claim_information[i].amount_paid : 0 ;
          _this.InformationData.claim_information[i].service_charges = _this.InformationData.claim_information[i].service_charges ? _this.InformationData.claim_information[i].service_charges : 0 ;
          _this.InformationData.claim_information[i].payment = _this.InformationData.claim_information[i].payment ? _this.InformationData.claim_information[i].payment : 0 ;
          _this.InformationData.claim_information[i].claim_deduction_roll = _this.InformationData.claim_information[i].claim_deduction_roll ? _this.InformationData.claim_information[i].claim_deduction_roll : 0 ;
          _this.InformationData.claim_information[i].number_of_claims = _this.InformationData.claim_information[i].number_of_claims ? _this.InformationData.claim_information[i].number_of_claims : 0;
          _this.InformationData.claim_information[i].ex_price = _this.InformationData.claim_information[i].ex_price ? _this.InformationData.claim_information[i].ex_price : 0 ;
          _this.InformationData.claim_information[i].payable = _this.InformationData.claim_information[i].payable ? _this.InformationData.claim_information[i].payable : 0 ;
        
        }
        let claim = { 
          origin:_this.InformationData.claim_information,//拆分的理赔信息（发生变更内容，编辑和新增） 详情中所有数据传回 
          delete:_this.InformationDataDeletes,//拆分的理赔信息(删除内容)，示例[1,2,3] id数组  
        }
        let data = {
          submit:submit,
          order:order,
          customer_data:customer_data,//客服处理信息  
          processing_contents:_this.handle,//处理内容
          complaint_information:complaint_information,//投诉详情 
          claim:claim,
        };
        axios.post(config.order,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.$emit('child-event');
            }else{ 
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      picdownload(){
        location.href= `${globla.SERVE_URL+'/'+config.pic_download}?order_id=${this.formInline.order_id}&api_token=${localStorage.getItem('Authorization')}`;
        },
      getpic(){
        let _this = this;
        _this.centerDialogVisible = true;
        axios.get(config.pic,{params:{order_id:_this.formInline.order_id}})//原因分析
        .then(function (response) {
            if(response.data.code == 200){
              _this.picdata = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },GetModifyInformation(){
        let _this = this;
        _this.ModifyInformation = true;
        axios.get(config.sendInvitation,{params:{order_id:_this.formInline.order_id}})//发送修改信息列表
        .then(function (response) {
            if(response.data.code == 200){
              _this.InvitationData = response.data.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      getOrderRelation(){
        let _this = this;
        _this.Associatediv = true;
         axios.get(config.order_relation,{params:{order_no:_this.topOrder_no}})//主从订单详情
          .then(function (response) {
              if(response.data.code == 200){
                _this.orderelations = response.data.data.sub_order_relation;
              }else{
                _this.$message.error(response.data.message);
              }
            })
          .catch(function (error) {
            _this.$message.error(error);
          });
      },
      AddsendInvitationPost(){
        let _this = this;
        for(let i in _this.sendInvitationPost.ota_item){
          if(isNaN(_this.sendInvitationPost.ota_item[i])){
            _this.sendInvitationPost.ota_item[i] = _this.tableData.order.ota_id;
          }
        }
        
        let data = {
        order_id:_this.formInline.order_id,
        ota:_this.sendInvitationPost.ota ? 1 : 0,
        ota_item:_this.sendInvitationPost.ota_item.toString(),
        fdata:_this.sendInvitationPost.fdata ? 1 : 0,
        name:_this.sendInvitationPost.name ? 1 : 0,
        sign:_this.sendInvitationPost.sign ? 1 : 0,
        oidnum:_this.sendInvitationPost.oidnum ? 1 : 0,
        fdata_remark:_this.sendInvitationPost.fdata_remark,
        user_remark:_this.sendInvitationPost.user_remark,
        sign_remark:_this.sendInvitationPost.sign_remark,
      };
        axios.post(config.sendInvitation,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '成功',
                type: 'success'
              });
              _this.GetModifyInformation();
              _this.InvitationShow = false;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      sendInvitationInfo(id){
        let _this = this;
        let data = {
          id : id
        };
        axios.get(config.sendInvitation_info,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              _this.sendInvitationInfoData = response.data.data;
              _this.sendInvitationInfoShow = true;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      AddInvitation(){
        let _this = this;
        _this.InvitationShow = true;
        _this.sendInvitationPost = {
          ota_item:_this.tableData.order.otaTitle ? _this.tableData.order.otaTitle.split(',') : '',
          ota:false,
          fdata:false,
          name:false,
          sign:false,
          oidnum:false,
          fdata_remark:'',
          user_remark:'',
          sign_remark:'',
        };
        _this.otadata = [];
        _this.otadatafristpage = 1;
        _this.getota();
      },
      getota(){
        let _this = this;
        if(_this.otadatafristpage > _this.otadatalastpage){
          return;
        }
        axios.get(config.ota,{params:{title:'',page:_this.otadatafristpage}})//ota列表
        .then(function (response) {
            if(response.data.code == 200){
              _this.otadata = _this.otadata.concat(response.data.data.data);
              _this.otadatafristpage++;
              _this.otadatalastpage = response.data.data.last_page;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      AssociateAdd(){
        let _this = this;
        _this.orderelations.push({
          sub_order_no:'',
          phone:'',
        });
      },
      AssociateDF(i){
        let _this = this;
        _this.orderelations.splice(i,1);
      },
      define(){
        let _this = this;
        // if(_this.orderelations.length < 1){
        //   _this.$message.error('子订单不能为空');
        //   return;
        // }
        let data = {
          order_no:_this.topOrder_no,
          sub_order_relation:_this.orderelations.length < 1 ? '' :_this.orderelations,
        }
        for(let i in data.sub_order_relation){
          if(data.sub_order_relation[i].sub_order_no == ''){
            _this.$message.error('订单号不能为空');
            return;
          }
          if(data.sub_order_relation[i].phone == ''){
            _this.$message.error('手机号不能为空');
            return;
          }
        }
        axios.post(config.order_relation,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
            _this.Associatediv = false;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      getordertype(){
        let _this = this;
        axios.get(config.order_type)//订单类型
        .then(function (response) {
            if(response.data.code == 200){
              _this.orderType = response.data.data;
              _this.orderinit();
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      orderinit(){
        let _this = this;
        axios.get(config.next_reason)//下次处理原因
        .then(function (response) {
            if(response.data.code == 200){
              _this.nextreason = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.oid_type)//证件类型
        .then(function (response) {
            if(response.data.code == 200){
              _this.oidType = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.tchannel)//投诉渠道
        .then(function (response) {
            if(response.data.code == 200){
              _this.tchannel = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.t_method)//投诉方式
        .then(function (response) {
            if(response.data.code == 200){
              _this.tmethod = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.claims)//索赔情况
        .then(function (response) {
            if(response.data.code == 200){
              _this.claimsdata = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        _this.commonSelect();//获取公用下拉框数据
        _this.getpay_channel();//支付渠道
        _this.getcttype(0);//投诉类型(包括123级联动) 
      },
      getcauseanalysis(claims_id,index){
        let _this = this;
        axios.get(config.cause_analysis,{params:{claims_id:claims_id}})//原因分析
        .then(function (response) {
            if(response.data.code == 200){
              _this.$set(_this.causeanalysis,index,response.data.data);
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      getselectimage_type(){
        let _this = this;
        axios.get(config.selectimage_type,{})
        .then(function (response) {
            if(response.data.code == 200){
              _this.selectimagetype = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      deleteota_item(){
        this.sendInvitationPost.ota_item = [];
      },
      loadMore() {
        if(this.otadatafristpage > this.otadatalastpage){
          return;
        }
        this.getota()
      },
      getClaimInformation(){
        let _this = this;
        axios.get(config.claim_information,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.InformationData = response.data.data;
              _this.calculation_order_no = response.data.data.order.order_no;
              _this.calculation();//计算一次金额
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      getpay_channel(){
        let _this = this;
        axios.get(config.pay_channel)//支付渠道
        .then(function (response) {
            if(response.data.code == 200){
              _this.pay_channel = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      commonSelect(){//获取公用下拉框数据  (claims_detail:索赔方案,complaint_target:投诉对象,complaint_mode_c:民航局投诉情况)
        let _this = this;
        axios.get(config.commonSelect,{params:{name:'claims_detail'}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.claims_detail_list = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.commonSelect,{params:{name:'complaint_target'}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.ComplaintObjectCollection = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.commonSelect,{params:{name:'complaint_mode_c'}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.AviationAdministrationofChina = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      getcttype(type){
        let _this = this;
        axios.get(config.cx_type,{params:{type:type}})//投诉类型(包括123级联动)
        .then(function (response) {
            if(response.data.code == 200){
              _this.cxType = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      ScxType(type,index,form){
        let _this = this;
        if(form){
          _this.cxTypeOne[index] = [];
          _this.cxTypeTwo[index] = [];
          _this.cxTypeThree[index] = [];
          _this.tableData.complaint_information[index].complaint_type_level_one = -1;
          _this.tableData.complaint_information[index].complaint_type_level_two = -1;
          _this.tableData.complaint_information[index].complaint_type_level_three = -1;
          _this.tableData.complaint_information[index].complaint_type_level_two_custom = '';
          _this.tableData.complaint_information[index].complaint_type_level_three_custom = '';
        }
         if(type == -1) { return }
        axios.get(config.cx_type,{params:{type:type}})//投诉类型(包括123级联动) 
        .then(function (response) {
            if(response.data.code == 200){
              _this.cxTypeOne[index] = response.data.data;
              _this.$set(_this.cxTypeOne,index,response.data.data);
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      ScxTypeO(type,index,form){//form为真 来自点击 
        let _this = this;
        if(form){
          _this.cxTypeTwo[index] = [];
          _this.cxTypeThree[index] = [];
          _this.tableData.complaint_information[index].complaint_type_level_two = -1;
          _this.tableData.complaint_information[index].complaint_type_level_three = -1;
          _this.tableData.complaint_information[index].complaint_type_level_two_custom = '';
          _this.tableData.complaint_information[index].complaint_type_level_three_custom = '';
        }
        if(type == -1) { return }
        axios.get(config.cx_type,{params:{type:type}})//投诉类型(包括123级联动) 
        .then(function (response) {
            if(response.data.code == 200){
              _this.$set(_this.cxTypeTwo,index,response.data.data);
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      ScxTypeT(type,index,form){
        let _this = this;
        if(form){
          _this.cxTypeThree[index] = [];
         _this.tableData.complaint_information[index].complaint_type_level_three = -1;
         _this.tableData.complaint_information[index].complaint_type_level_three_custom = '';
        }
        if(type == -1) { return }
        axios.get(config.cx_type,{params:{type:type}})//投诉类型(包括123级联动)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$set(_this.cxTypeThree,index,response.data.data);
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      getuserCoupon(user_id,cus_coupon_id,type){
        let _this = this;
        axios.get(config.user_coupon,{params:{user_id:user_id,cus_coupon_id:cus_coupon_id}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.coupons = response.data.data;
              if(_this.InformationData.order.cus_coupon_id != undefined){
                for(let i in _this.coupons){
                  if(_this.coupons[i].id == _this.InformationData.order.cus_coupon_id){
                    _this.InformationData.order.discount = _this.coupons[i].discount;
                  }
                }
                if(type != 1){//选择下拉框的时候才触发计算
                  _this.calculation();
                }
              }
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      onSubmit(){
        let _this = this;
        axios.get(config.order_detail,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.canableEditCoupon = response.data.data.canableEditCoupon;
              _this.hasDamage = response.data.data.hasDamage;
              if(_this.canableEditCoupon){
                _this.getuserCoupon(response.data.data.user.id,response.data.data.order.cus_coupon_id,1);
                if(_this.InformationData.order){
                  _this.InformationData.order.cus_coupon_id = response.data.data.order.cus_coupon_id;
                }else{
                  _this.InformationData.order = {};
                  _this.InformationData.order.cus_coupon_id = response.data.data.order.cus_coupon_id;
                }
              }
              _this.tableData = response.data.data;
              _this.topOrder_no = _this.tableData.order.order_no;
              _this.topState = _this.tableData.order.state;
              _this.certificate_pic = _this.tableData.order.certificate_pic;
              _this.next_processing_status = _this.tableData.order.next_processing_status;
              _this.next_processing_time = _this.tableData.order.next_processing_time;
              if(_this.topState == 0){_this.topState = '待审核'}
              if(_this.topState == 1){_this.topState = '处理中'}
              if(_this.topState == 2){_this.topState = '待付款'}
              if(_this.topState == 3){_this.topState = '投诉完成'}
              if(_this.topState == 4){_this.topState = '索赔成功'}
              for(let i in _this.tableData.complaint_information){
                if(_this.tableData.complaint_information[i].is_main === '' || _this.tableData.complaint_information[i].is_main === null){
                  _this.tableData.complaint_information[i].is_main = 1;
                }
                if(_this.tableData.complaint_information[i].complaint_type === 0){
                  _this.tableData.complaint_information[i].complaint_type = -1;
                }
                if(_this.tableData.complaint_information[i].complaint_type_level_one === 0){
                  _this.tableData.complaint_information[i].complaint_type_level_one = -1;
                }
                if(_this.tableData.complaint_information[i].complaint_type_level_two === 0){
                  _this.tableData.complaint_information[i].complaint_type_level_two = -1;
                }
                if(_this.tableData.complaint_information[i].complaint_type != -1){
                  _this.ScxType(_this.tableData.complaint_information[i].complaint_type,i,false);
                }
                if(_this.tableData.complaint_information[i].complaint_type_level_one != -1){
                  _this.ScxTypeO(_this.tableData.complaint_information[i].complaint_type_level_one,i,false);
                }
                if(_this.tableData.complaint_information[i].complaint_type_level_two != -1){
                  _this.ScxTypeT(_this.tableData.complaint_information[i].complaint_type_level_two,i,false);
                }
                if(_this.tableData.complaint_information[i].claims){
                  _this.getcauseanalysis(_this.tableData.complaint_information[i].claims,i);
                }else{
                  _this.tableData.complaint_information[i].cause_analysis = -1;
                }
              }
              for(let i in _this.tableData.complaint_information){
                if(_this.tableData.complaint_information[i].first_contact_time == '0000-00-00 00:00:00'){
                  _this.tableData.complaint_information[i].first_contact_time = '';
                }
                if(_this.tableData.complaint_information[i].all_data_collected_time == '0000-00-00 00:00:00'){
                  _this.tableData.complaint_information[i].all_data_collected_time = '';
                }
                if(_this.tableData.complaint_information[i].time_of_complaint_initiation == '0000-00-00 00:00:00'){
                  _this.tableData.complaint_information[i].time_of_complaint_initiation = '';
                }
                if(_this.tableData.complaint_information[i].first_response_to_complaint_time == '0000-00-00 00:00:00'){
                  _this.tableData.complaint_information[i].first_response_to_complaint_time = '';
                }
                if(_this.tableData.complaint_information[i].confirmation_compensation == '0000-00-00 00:00:00'){
                  _this.tableData.complaint_information[i].confirmation_compensation = '';
                }
                if(_this.tableData.complaint_information[i].payment_time_airlines == '0000-00-00 00:00:00'){
                  _this.tableData.complaint_information[i].payment_time_airlines = '';
                }
                if(_this.tableData.complaint_information[i].final_compensation){
                  _this.tableData.complaint_information[i].final_compensation = _this.tableData.complaint_information[i].final_compensation.split(',');
                 }
              }
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      addhandle(){
        let _this = this;
        _this.loading = true;
        let data = {
          content:_this.handle,
          order_id:_this.formInline.order_id
        }
        axios.put(config.handle_logs,data)
        .then(function (response) {
            _this.handle = '';
            if(response.data.code == 200){
              _this.$message({
                  message: response.data.message,
                  type: 'success'
                });
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false;
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      handlelogs(){
        let _this = this;
        _this.loading = true;
        axios.get(config.handle_logs,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data;
              for(let i in _this.tableData){
                _this.tableData[i].create_time = matDate(_this.tableData[i].create_time*1000,1);
              }
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false;
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      operatorlogs(){
        let _this = this;
            _this.loading = true;
        axios.get(config.operator_logs,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false;
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      order_schedule(){
        let _this = this;
            _this.loading = true;
        axios.get(config.order_schedule,{params:_this.formInline})
        .then(function (response) {
              _this.tableData = response.data.data;
              _this.loading = false;
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      order_schedule_model(type){
        let _this = this;
            if(!type){
              type = _this.activeName*1 + 1;
            }
        axios.get(config.order_schedule_model,{params:{type:type}})
        .then(function (response) {
            if(response.data.code == 200){
              console.log('gg')
              _this.orderScheduleModel = response.data.data;
              // setTimeout(function(){
              //   _this.asrbxcf(type)
              // },1000)
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      editsubmitactionlog() {
        let _this = this;
        this.logform.order_no = _this.topOrder_no;
        console.log(_this.logform.actionlogtype4.toString())
        let data = {
          id:_this.logform.id,
          action:_this.logform.actionlogtype1 ? _this.logform.actionlogtype1 : 0,
          specific_situation:_this.logform.actionlogtype2 ? _this.logform.actionlogtype2 : 0,
          specific_doubts:_this.logform.actionlogtype3 ? _this.logform.actionlogtype3 : 0,
          specific_materials:_this.logform.actionlogtype4 ? _this.logform.actionlogtype4.toString() : '',
          remark:_this.logform.remark ? _this.logform.remark : '',
        };
        this.$refs.logform.validate(valid => {
          if (valid) {
            axios.post(config.action_log,data)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.handleSelect('10');
                  _this.editactionlistshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });
      },
      submitactionlog() {
        let _this = this;
        this.logform.order_no = _this.topOrder_no;
        console.log(_this.logform.actionlogtype4.toString())
        let data = {
          order_no:_this.topOrder_no,
          action:_this.logform.actionlogtype1 ? _this.logform.actionlogtype1 : 0,
          specific_situation:_this.logform.actionlogtype2 ? _this.logform.actionlogtype2 : 0,
          specific_doubts:_this.logform.actionlogtype3 ? _this.logform.actionlogtype3 : 0,
          specific_materials:_this.logform.actionlogtype4 ? _this.logform.actionlogtype4.toString() : '',
          remark:_this.logform.remark ? _this.logform.remark : '',
        };
       // JSON.parse(JSON.stringify(_this.logform));
        this.$refs.logform.validate(valid => {
          if (valid) {
            axios.put(config.action_log,data)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.handleSelect('10');
                  _this.addactionlistshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });
      },
      submitFormBC() {// 补充资料里面的表单
        let _this = this;
        this.form.order_id = _this.formInline.order_id;
        let data = JSON.parse(JSON.stringify(_this.form));
        this.$refs.form.validate(valid => {
          if (valid) {
            data.item = data.item.toString();
            axios.put(config.bank,data)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.handleSelect('8');
                  _this.addbankshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });
      },
      postForm(order){
        let _this = this;
        if(order.en_content == '' || order.en_title == '' || order.content == '' || order.secondStatus == '' || order.type == ''){
          _this.$message.error('都是必填项，请填写完整！');
          return
        }
        _this.order_schedule_model_loading = true;
        axios.post(config.order_schedule_model,order)
        .then(function (response) {
          _this.order_schedule_model_loading = false;
            if(response.data.code == 200){
              _this.$message({
                message: '存储成功',
                type: 'success'
              });
              _this.order_schedule_model(order.type);
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      submitForm(order){
        let _this = this;
        if(order.en_content == '' || order.en_title == '' || order.content == '' || order.secondStatus == '' || order.type == ''){
          _this.$message.error('都是必填项，请填写完整！');
          return
        }
        axios.put(config.order_schedule_model,order)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '存储成功',
                type: 'success'
              });
              _this.order_schedule_model(order.type);
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      sendSchedule(order){
        let _this = this;
        if(!order.content || !order.en_content || !order.secondStatus || !order.en_secondStatus){
            _this.$message.error('带*是必填项');
          return
        }
        let data = {
          order_id:_this.formInline.order_id,
          content:order.content,
          en_content:order.en_content,
          secondStatus:order.secondStatus,
          en_secondStatus:order.en_secondStatus,
        }
        axios.put(config.send_schedule,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
            _this.order_schedule()
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      deleteModeModel(model_id,type){
        let _this = this;
        axios.delete(config.model_message,{data:{model_id:model_id}})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.model_message(type*1+1);
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
      },
      deleteordermodel(model_id,type){
        let _this = this;
        axios.delete(config.order_schedule_model,{data:{model_id:model_id}})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.order_schedule_model(type*1+1);
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
      },
      addorder(order){
        this.orderSchedule.en_content = order.en_content;
        this.orderSchedule.en_secondStatus = order.en_secondStatus;
        this.orderSchedule.content = order.content;
        this.orderSchedule.secondStatus = order.secondStatus;
        this.orderSchedule.type = (this.activeName*1 + 1).toString();
        this.orderSchedule.id = order.model_id;
      },
      addmodel(mode){
        this.modeSchedule.en_content = mode.en_content;
        this.modeSchedule.en_title = mode.en_title;
        this.modeSchedule.content = mode.content;
        this.modeSchedule.title = mode.title;
        this.modeSchedule.type = (this.modeActive*1 + 1).toString();
        this.modeSchedule.id = mode.model_id;
      },
      model_message(type){
        let _this = this;
            if(!type){
              type = _this.modeActive*1 + 1;
            }
        axios.get(config.model_message,{params:{type:type}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.modelmessage = response.data.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      order_message(){
        let _this = this;
            _this.loading = true;
        axios.get(config.order_message,{params:_this.formInline})
        .then(function (response) {
              _this.tableData = response.data.data.data;
              _this.loading = false;
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      getts(){
        let _this = this;
        _this.tsItemDate = {value:'',content:''};
        _this.loading = true;
        axios.get(config.ts_item,{params:{order_id:_this.formInline.order_id}})
        .then(function (response) {
              if(response.data.code == 200){
               _this.tsItem = response.data.data;
              }else{
                _this.$message.error(response.data.message);
              }
              _this.loading = false;
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      getaction_list(){
        let _this = this;
        _this.loading = true;
        axios.get(config.action_list,{params:{order_no:_this.topOrder_no}})
        .then(function (response) {
              if(response.data.code == 200){
               _this.actionlist = response.data.data.data;
               for(let i in _this.actionlist){
                _this.actionlist[i].create_time = matDate(_this.actionlist[i].create_time*1000);
              }
              }else{
                _this.$message.error(response.data.message);
              }
              _this.loading = false;
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.selectaction_log_type,{params:{pid:0}})
        .then(function (response) {
              if(response.data.code == 200){
               _this.actionlogtypelist = response.data.data;
              }else{
                _this.$message.error(response.data.message);
              }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      setopamountdate(){
        let _this = this;
        let sdate = matDate(_this.damagedata.op_amount_date);
        axios.get(config.op_amount,{params:{order_no:_this.topOrder_no,s_date:sdate}})
        .then(function (response) {
              if(response.data.code == 200){
               _this.damagedata.custom_op_amount = response.data.data.total;
              }else{
                _this.$message.error(response.data.message);
              }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      setdnamountsdate(){
        let _this = this;
        let sdate = matDate(_this.damagedata.dn_amount_sdate);
        let edate = matDate(_this.damagedata.dn_amount_edate);
        axios.get(config.dn_amount,{params:{order_no:_this.topOrder_no,s_date:sdate,e_date:edate}})
        .then(function (response) {
              if(response.data.code == 200){
               _this.damagedata.custom_dn_amount = response.data.data.total;
              }else{
                _this.$message.error(response.data.message);
              }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      setrtiamountflg(){
        let _this = this;
        axios.get(config.rti_amount,{params:{order_no:_this.topOrder_no,type:_this.damagedata.rti_amount_rely}})
        .then(function (response) {
              if(response.data.code == 200){
               _this.damagedata.custom_rti_amount = response.data.data.total;
              }else{
                _this.$message.error(response.data.message);
              }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      setroaamountflg(){
        let _this = this;
        axios.get(config.roa_amount,{params:{order_no:_this.topOrder_no,type:_this.damagedata.roa_amount_rely}})
        .then(function (response) {
              if(response.data.code == 200){
               _this.damagedata.custom_roa_amount = response.data.data.total;
              }else{
                _this.$message.error(response.data.message);
              }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      editdamage(){
        let _this = this;
        // if(!mode.content || !mode.title || !mode.en_content){
        //   _this.$message.error('请填写必填项！');
        //   return
        // }
        let data = {
          order_no:_this.damagedata.order_no,
          op_amount_flg:_this.damagedata.op_amount_flg,
          op_amount_date:matDate(_this.damagedata.op_amount_date),
          custom_op_amount:_this.damagedata.custom_op_amount.replace(",", "") * 1,
          dn_amount_flg:_this.damagedata.dn_amount_flg,
          dn_amount_sdate:matDate(_this.damagedata.dn_amount_sdate),
          dn_amount_edate:matDate(_this.damagedata.dn_amount_edate),
          custom_dn_amount:_this.damagedata.custom_dn_amount.replace(",", "") * 1,
          rti_amount_flg:_this.damagedata.rti_amount_flg,
          rti_amount_rely:_this.damagedata.rti_amount_rely,
          custom_rti_amount:_this.damagedata.custom_rti_amount.replace(",", "") * 1,
          roa_amount_flg:_this.damagedata.roa_amount_flg,
          roa_amount_rely:_this.damagedata.roa_amount_rely,
          custom_roa_amount:_this.damagedata.custom_roa_amount.replace(",", "") * 1,
        }
        axios.post(config.damage,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '修改成功',
                type: 'success'
              });
            _this.getdamage()
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      getdamage(){
        let _this = this;
        _this.loading = true;
        axios.get(config.damage,{params:{order_no:_this.topOrder_no}})
        .then(function (response) {
              if(response.data.code == 200){
               _this.damagedata = response.data.data;
              }else{
                _this.$message.error(response.data.message);
              }
              _this.loading = false;
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      actionlogtypeson(pid,type,boo){
        let _this = this;
        axios.get(config.selectaction_log_type,{params:{pid:pid}})
        .then(function (response) {
              if(response.data.code == 200){
                if(type == 1){
                  _this.actionlogtypelist2 = response.data.data;
                  if(!boo){
                    _this.actionlogtypelist3 = [];
                    _this.actionlogtypelist4 = [];
                    _this.logform.actionlogtype2 = '';
                    _this.logform.actionlogtype3 = '';
                    _this.logform.actionlogtype4 = '';
                  }
                }  
                if(type == 2){
                  _this.actionlogtypelist3 = response.data.data;
                  if(!boo){
                    _this.actionlogtypelist4 = [];
                    _this.logform.actionlogtype3 = '';
                    _this.logform.actionlogtype4 = '';
                  }
                }
                if(type == 3){
                  _this.actionlogtypelist4 = response.data.data;
                  if(!boo){
                    _this.logform.actionlogtype4 = '';
                  }
                }
              }else{
                _this.$message.error(response.data.message);
              }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      exchange_by_order(){
        let _this = this;
        _this.loading = true;
        axios.get(config.fj,{params:{order_id:_this.formInline.order_id}})
        .then(function (response) {
              if(response.data.code == 200){
                  _this.bcData = [];
                if(response.data.data){
                  response.data.data.create_time = matDate(response.data.data.create_time*1000);
                  _this.bcData.push(response.data.data);
                }
              }else{
                _this.$message.error(response.data.message);
              }
              _this.loading = false;
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.exchange_by_order,{params:{order_id:_this.formInline.order_id}})
        .then(function (response) {
              if(response.data.code == 200){
               _this.exchangeByOrderData = response.data.data;
              }else{
                _this.$message.error(response.data.message);
              }
              _this.loading = false;
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.bank_list,{params:{order_id:_this.formInline.order_id}})
        .then(function (response) {
          console.log(response.data)
              if(response.data.code == 200){
                _this.banklistData = [];
                if(response.data.data){
               _this.banklistData.push(response.data.data);
                }
              }else{
                _this.$message.error(response.data.message);
              }
              _this.loading = false;
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      postmodeForm(mode){
        let _this = this;
        if(mode.en_content == '' || mode.en_title == '' ||mode.content == '' || mode.title == '' || mode.type == ''){
          _this.$message.error('都是必填项，请填写完整！');
          return
        }
        axios.post(config.model_message,mode)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '存储成功',
                type: 'success'
              });
              _this.model_message(mode.type);
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      submitmodeForm(mode){
        let _this = this;
        if(mode.en_content == '' || mode.en_title == '' ||mode.content == '' || mode.title == '' || mode.type == ''){
          _this.$message.error('都是必填项，请填写完整！');
          return
        }
        axios.put(config.model_message,mode)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '存储成功',
                type: 'success'
              });
              _this.model_message(mode.type);
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      sendSmodechedule(mode){
        let _this = this;
        if(!mode.content || !mode.title || !mode.en_content){
          _this.$message.error('请填写必填项！');
          return
        }
        let data = {
          order_id:_this.formInline.order_id,
          content:mode.content,
          en_content:mode.en_content,
          en_title:mode.en_title,
          title:mode.title,
        }
        axios.post(config.order_message,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
            _this.order_message()
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      chaxunzi(cha){
        let _this = this;
        _this.modeloading = true;
        if(cha == 'cha'){
          _this.modelshowdata.page = 1;
        }
        _this.modelshowdata.f_type = _this.modelshowdata.f_type ? _this.modelshowdata.f_type : 0;
        _this.modelshowdata.c_type = _this.modelshowdata.c_type ? _this.modelshowdata.c_type : 0;
        axios.get(config.fj_question,{params:_this.modelshowdata})
        .then(function (response) {
            if(response.data.code == 200){
              _this.modelshowlist = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.modeloading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      setpage(page){
        this.total = page.total;
        this.per_page = page.per_page;
        this.current_page = page.current_page;
      },
      setmodelpage(page){
        this.modeltotal = page.total;
        this.modeler_page = page.per_page;
        this.model_page = page.current_page;
      },
      handleCurrentChange(val) {
        this.modelshowdata.page = val;
        this.chaxunzi();
      },
      handleCurrentChange2(val) {
        this.enclosure.page = val;
        this.getustemp();
      },
      modelChange(val) {
        this.tsmodel.page = val;
        this.makeUseOf();
      },
      handleSelectionChange(selection) {// 多选框选中数据
        //获取所有选中项的gh(工号)属性的值
        this.ids = selection.map(item => item.id)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },
      mkaodf(){
        let _this = this;
          let clear = setInterval(()=>{
            let divs = _this.$refs.mkaod;
            if(divs){
              clearInterval(clear);
              const drake = dragula([divs]);
              drake.on('drop', (el, target, source, sibling) => {
                console.log(el,target,source,sibling);
                _this.dbdfbwf();
              });
            }
          },100);
          let clear1 = setInterval(()=>{
            let divs = _this.$refs.mkaod1;
            if(divs){
              clearInterval(clear1);
              const drake = dragula([divs]);
              drake.on('drop', (el, target, source, sibling) => {
                console.log(el,target,source,sibling);
                _this.dbdfbwf();
              });
            }
          },100);
          let clear2 = setInterval(()=>{
            let divs = _this.$refs.mkaod2;
            if(divs){
              clearInterval(clear2);
              const drake = dragula([divs]);
              drake.on('drop', (el, target, source, sibling) => {
                console.log(el,target,source,sibling);
                _this.dbdfbwf();
              });
            }
          },100);
          let clear3 = setInterval(()=>{
            let divs = _this.$refs.mkaod3;
            if(divs){
              clearInterval(clear3);
              const drake = dragula([divs]);
              drake.on('drop', (el, target, source, sibling) => {
                console.log(el,target,source,sibling);
                _this.dbdfbwf();
              });
            }
          },100);
      },
      dbdfbwf(){
        let _this = this;
        let indexs = [];
        const dbdfbw = this.$refs.dbdfbw;
        dbdfbw.sort(function(a, b) {
          var rectA = a.getBoundingClientRect();
          var rectB = b.getBoundingClientRect();
          return rectA.top - rectB.top; // 按照元素在视口中的top位置进行升序排序
        });
        dbdfbw.forEach((element,index) => {
            indexs.push({
              id:element.id,
              sort:index
            });
        });
        const dbdfbw1 = this.$refs.dbdfbw1;
        dbdfbw1.sort(function(a, b) {
          var rectA = a.getBoundingClientRect();
          var rectB = b.getBoundingClientRect();
          return rectA.top - rectB.top; // 按照元素在视口中的top位置进行升序排序
        });
        dbdfbw1.forEach((element,index) => {
            indexs.push({
              id:element.id,
              sort:index
            });
        });
        const dbdfbw2 = this.$refs.dbdfbw2;
        dbdfbw2.sort(function(a, b) {
          var rectA = a.getBoundingClientRect();
          var rectB = b.getBoundingClientRect();
          return rectA.top - rectB.top; // 按照元素在视口中的top位置进行升序排序
        });
        dbdfbw2.forEach((element,index) => {
            indexs.push({
              id:element.id,
              sort:index
            });
        });
        const dbdfbw3 = this.$refs.dbdfbw3;
        dbdfbw3.sort(function(a, b) {
          var rectA = a.getBoundingClientRect();
          var rectB = b.getBoundingClientRect();
          return rectA.top - rectB.top; // 按照元素在视口中的top位置进行升序排序
        });
        dbdfbw3.forEach((element,index) => {
            indexs.push({
              id:element.id,
              sort:index
            });
        });
        console.log(indexs);
        axios.post(config.order_schedule_sort,{sortData:indexs})
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      asrbxcf(){
        let _this = this;
          let clear = setInterval(()=>{
            let asrbxc = _this.$refs.asrbxc;
            if(asrbxc){
              clearInterval(clear);
              const drake = dragula([asrbxc]);
              drake.on('drop', (el, target, source, sibling) => {
                console.log(el,target,source,sibling);
                _this.asrbxcfgo();
              });
            }
          },100);
          let clear2 = setInterval(()=>{
            let asrbxc2 = _this.$refs.asrbxc2;
            if(asrbxc2){
              clearInterval(clear2);
              const drake = dragula([asrbxc2]);
              drake.on('drop', (el, target, source, sibling) => {
                console.log(el,target,source,sibling);
                _this.asrbxcfgo();
              });
            }
          },100);
          let clear3 = setInterval(()=>{
            let asrbxc3 = _this.$refs.asrbxc3;
            if(asrbxc3){
              clearInterval(clear3);
              const drake = dragula([asrbxc3]);
              drake.on('drop', (el, target, source, sibling) => {
                console.log(el,target,source,sibling);
                _this.asrbxcfgo();
              });
            }
          },100);
      },
      asrbxcfgo(){
        let _this = this;
        let indexs = [];
        const asrbxcfgoRefs = this.$refs.asrbxcfgoRef;
        asrbxcfgoRefs.sort(function(a, b) {
          var rectA = a.getBoundingClientRect();
          var rectB = b.getBoundingClientRect();
          return rectA.top - rectB.top; // 按照元素在视口中的top位置进行升序排序
        });
        asrbxcfgoRefs.forEach((element,index) => {
            indexs.push({
              id:element.id,
              sort:index
            });
        });
        const asrbxcfgoRefs2 = this.$refs.asrbxcfgoRef2;
        asrbxcfgoRefs2.sort(function(a, b) {
          var rectA = a.getBoundingClientRect();
          var rectB = b.getBoundingClientRect();
          return rectA.top - rectB.top; // 按照元素在视口中的top位置进行升序排序
        });
        asrbxcfgoRefs2.forEach((element,index) => {
            indexs.push({
              id:element.id,
              sort:index
            });
        });
        const asrbxcfgoRefs3 = this.$refs.asrbxcfgoRef3;
        asrbxcfgoRefs3.sort(function(a, b) {
          var rectA = a.getBoundingClientRect();
          var rectB = b.getBoundingClientRect();
          return rectA.top - rectB.top; // 按照元素在视口中的top位置进行升序排序
        });
        asrbxcfgoRefs3.forEach((element,index) => {
            indexs.push({
              id:element.id,
              sort:index
            });
        });
        console.log(indexs);
        axios.post(config.order_schedule_sort,{sortData:indexs})
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      handleSelect(key) {
        let _this = this;
        _this.key = key;
        _this.tableData = [];
        _this.activeName = '0';
        _this.modeActive = '0';
        switch (key) {
          case '1':
            _this.onSubmit()
            _this.getClaimInformation();
            break;
          case '2':
            _this.handlelogs()
            break;
          case '3':
            _this.operatorlogs()
            break;
          case '5':
            _this.order_schedule()
            _this.order_schedule_model(1)
            _this.asrbxcf()
            break;
          case '6':
            _this.order_message()
            _this.model_message(1)
            _this.mkaodf()
            break;
          case '8':
            _this.exchange_by_order()
            _this.getselectimage_type();
            break;
          case '9':
            _this.getts()
            break;
          case '10':
            _this.getaction_list()
            break;
          case '11':
            _this.getdamage()
            break;
          default:
            break;
        }
      }
    },
  created(){
    let frist = sessionStorage.getItem('frist');
    if(frist){
      sessionStorage.setItem("frist", false);
      this.key = '1';
      this.formInline.order_id = sessionStorage.getItem('order_id');
      this.list_type = sessionStorage.getItem('list_type');//编辑 查看权限
      this.onSubmit();
      this.getClaimInformation();//理赔详情
      this.getordertype();//获取订单类型如果能够正常请求，会触发orderinit
    }
  },
  directives: {
    'el-select-loadmore': {
        bind(el, binding) {
            // 下拉框下拉的框
            const SELECTWRAP_DOM = el.querySelector(
                '.el-select-dropdown .el-select-dropdown__wrap'
            );
            // 增加滚动监听，
            SELECTWRAP_DOM.addEventListener('scroll', function() {
                const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
                // 当滚动条滚动到最底下的时候执行接口加载下一页
                if (condition) {
                    binding.value();
                }
            });
        }
    }
  },
}
</script>
<style>
.gu-transit{
  background-color: #F4FAFF;
  user-select: none; /* 防止文本被选中 */
  font-size: 18px;
}
</style>
<style scoped>

  .div6{
    display: flex;
    margin-top: 10px;
  }
  .div5{
    display: flex;
    margin-top: 10px;
  }
  .left{
    width: 49%;
    margin-right: 2%;
  }
  .right{
    width: 49%
  }
  .div2{
    border-bottom: 1px solid #ccc;
    padding-bottom: 6px;
  cursor: pointer;
  }
  .content{
    font-size: 15px;
    width: 90%;
  }
  .title{
    cursor: pointer;
    position: relative;
    padding: 5px 0px;
    font-size: 16px;
    font-weight: bold;
  }
  .lese{
    padding: 5px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .cltitle{
    font-weight: bold;
    font-size: 18px;
    height: 40px;
    line-height: 30px;
    margin-top: 10px;
  }
  .select1{
    margin-left: 5px;
    width: 200px;
  }
  .content_title{
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 20px;
  }
  .handle_title{
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
  }
  .contents{
    height: 100px;
    margin: 20px;
    background: #FAFAFA none repeat scroll 0 0;
    border-style: solid;
    border-width: 1px;
    border-color: #ABADB3 #E2E3EA #E2E3EA #ABADB3;
    border-radius: 4px;
    text-align: left;
  }
  .ph{
    display: flex;
  }
  .ph_title{
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
    margin-right: 10px;
    margin-left: 20px;
  }
  .ph_btn{
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 1.42857143;
    border-radius: 3px;
    color: #fff;
    background-color: #605ca8;
    border-color: #605ca8;
  }
  .botoom{
    display: flex;
  }
  .nextprocessingtime{
    margin-left: 20px;
    margin-right: 20px;
  }
  .magrin20{
    margin-left: 50px;
  }
  .magrin100{
    margin-right: 100px;
  }
  .orderstatus{
    display: flex;
    line-height: 40px;
  }
  .details_title{
    margin: 20px 0px;
    text-align: left;
    font-weight: bold;
  }
  .dis_flx{
    display: flex;
    overflow: auto;
    padding: 5px 5px 20px 5px;
  }
  .block{
    display: grid;
    margin-right: 20px;
  }
  .demonstration{
    text-align: center;
    margin-bottom: 10px;
  }
  .thumbnail{
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px; 
    padding: 5px;
  }
  .exchangeByOrder{
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      line-height: 50px;
  }
  .Associated{
    display: flex;
    line-height: 40px;
  }
  .AOrder{
    font-size: 16px;
    font-weight: bold;
  }
  .ABtn{
    margin-left: auto;
  }
  .Asoon{
    text-align: left;
    font-size: 14px;
    line-height: 28px;
    margin-left: 30px;
  }
  .inpdiv{

  }
  .inps{
    border: 2px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 3px #ddd;
    font-size: 16px;
    padding: 10px;
    margin: 10px;
  }
  .inps::placeholder{
    color: #ccc;
  }
  .inps:hover {
  border-color: #aaa;
  box-shadow: 0 0 3px #aaa;
  }
  .inps:focus {
    border-color: #f90;
    box-shadow: 0 0 3px #f90;
  }
  .inpis{
    font-size: 20px;
  }
  .remarkds{
    display: flex;
  }
  .bcedatatitle{
    min-width: 80px;
  }
  .btnblack{
    background-color: #000;
    color: #fff;
  }
  .liulan{
    height: 40px;
    margin: 0px 15px;
  }
  .domcheck{
    margin: 0px 15px;
  }

  .xs315{
    background-color: #605ca8;
    border-color: #605ca8;
  }
  .draggable-item {
    cursor: move;
  }
 .el-range-editor.el-input__inner{
  width: 300px;
}
.conentbox{
  max-height: 75vh;
  overflow:auto;
}
.contflex{
  position: absolute;
  height:60px;
  box-shadow: 5px 5px 5px #888888;
  width: 90%;
  background-color: white;
  z-index: 999;
  top: 75px;
}
.editflex{
  position: absolute;
  height:285px;
  box-shadow: 5px 5px 5px #888888;
  width: 90%;
  background-color: white;
  z-index: 999;
  top: 75px;
  padding: 0px 20px 0px 0px;
}
.container{
  width: 90%;
}
.reds{
  color:red !important;
}
</style>